import { Trans } from 'react-i18next';
import { Flex, BodySmall, BodyLarge, H6 } from '@beauty/beauty-market-ui';
import { transComponents } from '../constants';

type SectionProps = {
  title: string;
  body: string | string[];
  articles?: string[];
  subSection?: JSX.Element[];
  isTitleNotH6?: boolean;
};

// TODO Refactor to remove index from key
const renderItem = (item: string, i?: number) => (
  <BodySmall key={i} mb="24px">
    <Trans components={transComponents}>{item}</Trans>
  </BodySmall>
);

export const Section = ({ title, body, articles, subSection, isTitleNotH6 = false }: SectionProps) => (
  <Flex flexDirection="column">
    {title !== '' &&
      (!isTitleNotH6 ? (
        <H6 mb="24px">{title}</H6>
      ) : (
        <BodyLarge large mb="8px">
          {title}
        </BodyLarge>
      ))}
    {articles?.map((article, i) => renderItem(article, i))}
    {body && (typeof body === 'string' ? renderItem(body) : body.map((part, i) => renderItem(part, i)))}
    {subSection}
  </Flex>
);
