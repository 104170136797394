import React from 'react';
import { useTranslation } from 'react-i18next';
import { H2, BodySmall } from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import FAQCard from './components/FAQCard';
import { faqCards } from './constants';
import { FAQCardsWrapper } from './style';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <Container flexDirection="column" mt={['24px', '24px', '24px', '40px']}>
      <H2 mb="16px"> {t('faq.title')}</H2>
      <BodySmall large width="287px" mb="70px">
        {t('faq.subtitle')}
      </BodySmall>
      <FAQCardsWrapper>
        {faqCards.map(({ image, title, description, link }) => (
          <FAQCard
            key={image}
            image={image}
            title={t(`faq.${title}`)}
            description={t(`faq.${description}`)}
            link={link}
          />
        ))}
      </FAQCardsWrapper>
    </Container>
  );
};
export default FAQ;
