import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { NotificationEndpoints } from '../api/endpoints/endpoints';
import { TextWithTranslations } from '../types';

type NotificationItem = {
  id: string;
  type: string;
  title: TextWithTranslations;
  descr: TextWithTranslations;
};

type NotificationData = Record<string, NotificationItem>;

type Notifications = {
  types: string[];
  data: NotificationData;
};

export const useGetNotificationTypes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsMap, setNotificationsMap] = useState<Notifications>({ types: [], data: {} });

  useEffect(() => {
    let isNotificationTypesLoading = true;

    const fetchNotificationTypes = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: NotificationEndpoints.NotificationTypes,
      });

      if (isNotificationTypesLoading && response) {
        const data: NotificationData = {};
        const types: string[] = [];

        response.data.forEach(item => {
          data[item.type] = {
            id: item.id,
            type: item.type,
            title: item.title,
            descr: item.descr,
          };
          types.push(item.type);
        });

        setNotificationsMap({ data, types });
        setIsLoading(false);
      }
    };

    fetchNotificationTypes();

    return () => {
      isNotificationTypesLoading = false;
    };
  }, []);

  return {
    isLoading,
    data: notificationsMap.data,
    types: notificationsMap.types,
  };
};
