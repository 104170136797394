import { generatePath } from 'react-router';
import { RouterUrl } from '../../routes/routes';

export const clientMenu = (userId?: string) => [
  {
    id: 1,
    item: 'Profile',
    disabled: false,
    link: RouterUrl.Balance,
  },
  {
    id: 2,
    item: 'Appointments',
    disabled: !userId,
    link: generatePath(RouterUrl.ClientAppointments, { id: userId ?? null }),
  },
];
