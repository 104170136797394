import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { Footer, ShareOrganisationModal } from '../../../components';
import { FavouritePopup } from '../../../components/FavouritePopup';
import { ServiceIconsBlock } from '../../../components/Services/components/ServiceIconsBlock';
import { useToggle } from '../../../hooks/useToggle';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updateShowMenu } from '../../../store/redux-slices/headerSlice';
import { selectHistory, setFrom, setTopCategory } from '../../../store/redux-slices/historySlice';
import { AllOffersWrapper } from '../style';
import CustomMap from './GoogleMap/CustomMap';
import { OffersList } from './OffersList';

export const AllOffers = () => {
  const { topCategory } = useAppSelector(selectHistory).history;
  const dispatch = useAppDispatch();
  const setSelected = (name: string) => dispatch(setTopCategory(name));
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [offersView, setOffersView] = useState('map');
  dispatch(setFrom('AllOffers'));

  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const [isFavouriteModalOpen, setFavouriteModalOpen] = useToggle();
  const [shareOrganisationId, setShareOrganisationId] = useState('');

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const handleOpenSidebar = () => {
    setSidebarIsOpen(prev => !prev);
  };

  const handleChangeView = () => {
    setOffersView(offersView === 'list' ? 'map' : 'list');
  };

  useEffect(() => {
    dispatch(updateShowMenu({ isShowMenu: offersView === 'list' || (offersView === 'map' && !isMobile) }));
  }, [offersView, isMobile]);

  useEffect(() => {
    if (!offersView) setOffersView('map');
  }, []);

  return (
    <>
      <AllOffersWrapper isWide={offersView === 'map'}>
        {/* {isMobile && <Search />} */}
        <ServiceIconsBlock
          selected={topCategory}
          onClick={setSelected}
          fromTop={offersView === 'map'}
          isBackground={isMobile && offersView === 'map'}
        />
        {offersView === 'list' ? <OffersList onFilterOpen={handleOpenSidebar} onChangeView={handleChangeView} /> : null}
        {offersView === 'map' ? (
          <CustomMap
            onFilterOpen={handleOpenSidebar}
            onChangeView={handleChangeView}
            setFavouriteModalOpen={setFavouriteModalOpen}
            setShareModalOpen={setShareModalOpen}
            setShareOrganisationId={setShareOrganisationId}
          />
        ) : null}
        {/* <FilterSidebar isOpen={sidebarIsOpen} onOpen={handleOpenSidebar} /> */}
      </AllOffersWrapper>
      <FavouritePopup isOpen={isFavouriteModalOpen} handlePopupClose={setFavouriteModalOpen} />
      {isShareModalOpen && (
        <ShareOrganisationModal
          handelModalClose={setShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          organisationId={shareOrganisationId}
        />
      )}
      {offersView === 'list' && <Footer />}
    </>
  );
};
