import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { Search } from '../../components/Search';
import { zIndex } from '../../constants';

export const StyledSearch = styled(Search)`
  width: 92% !important;
`;

export const SearchWrapper = styled(Flex)`
  width: 100%;
  position: fixed;
  left: 0px;
  box-sizing: border-box;
  justify-content: center;
  z-index: ${zIndex.searchMobileWrapper};
  padding-left: 25px;
  padding-right: 25px;
`;

export const NoDataWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 200px);
  margin: auto;

  align-items: center;
  text-align: center;
`;
