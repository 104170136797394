import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PatchHeaders } from './interceptors/request/PatchHeaders';
import { AccessDenied } from './interceptors/response/AccessDenied';
import { GeneralError } from './interceptors/response/GeneralError';
import { Unavailable } from './interceptors/response/Unavailable';

const requestInterceptors: any[] = [new PatchHeaders()];

const responseInterceptors: any[] = [new Unavailable(), new AccessDenied(), new GeneralError()];

type ResolveCallbackType = (config: AxiosRequestConfig) => AxiosRequestConfig;
type ResponseCallbackType = (response: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>;

// TODO Review and refactor to remove ts-ignore
export const registerInterceptors = (axiosInstance: AxiosInstance = axios) => {
  requestInterceptors.forEach(instance => {
    const { resolve } = instance;
    const boundResolve = resolve.bind(instance) as unknown as ResolveCallbackType;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    axiosInstance.interceptors.request.use(boundResolve);
  });

  responseInterceptors.forEach(instance => {
    const { resolve, reject } = instance;
    const boundResolve = resolve.bind(instance) as unknown as ResponseCallbackType;
    const boundReject = reject.bind(instance) as unknown as ResponseCallbackType;

    axiosInstance.interceptors.response.use(boundResolve, boundReject);
  });
};
