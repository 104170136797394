import { useTranslation } from 'react-i18next';
import { useMediaQuery, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import CRM1x from '../../../assets/CRM@1x.png';
import CRM2x from '../../../assets/CRM@2x.png';
import CRM3x from '../../../assets/CRM@3x.png';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { RouterUrl } from '../../../routes/routes';
import {
  CRMButton,
  CRMImage,
  CRMWrapper,
  CRMDesktopTitle,
  CRMSubTitle,
  Description,
  CRMRightWrapper,
  CRMMobileTitle,
} from '../style';

export const CRMPreviewSection = () => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const { business } = useGetUrl(RouterUrl.SignUp);
  const handleOpenBusiness = () => window.open(business, '_blank');

  return (
    <CRMWrapper>
      <CRMImage>
        <img src={CRM1x} srcSet={`${CRM1x} 1x,${CRM2x} 2x,${CRM3x} 3x`} alt={t('home.crm.icon')} />
      </CRMImage>
      <CRMRightWrapper gap="24px">
        <CRMSubTitle large>{t('home.crm.comingSoon')}</CRMSubTitle>
        {isMobile ? (
          <CRMMobileTitle>{t('home.crm.title')}</CRMMobileTitle>
        ) : (
          <CRMDesktopTitle>{t('home.crm.title')}</CRMDesktopTitle>
        )}
        <Description>{t('home.crm.info')}</Description>
        <CRMButton onClick={handleOpenBusiness}>{t('home.crm.button')}</CRMButton>
      </CRMRightWrapper>
    </CRMWrapper>
  );
};
