import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { ReviewEndpoints } from '../api/endpoints/endpoints';
import { BadgeType } from '../types/review';

export const useGetRatingCards = (reviewType: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<{ [key: string]: BadgeType[] }>({});

  useEffect(() => {
    let isCardsLoading = true;

    const fetchRatingCards = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: ReviewEndpoints.Badges,
        endpointParams: reviewType,
      });

      if (isCardsLoading) {
        setCards(response?.status === 200 ? response?.data : []);
        setIsLoading(false);
      }
    };

    fetchRatingCards();

    return () => {
      isCardsLoading = false;
    };
  }, [reviewType]);

  return {
    isLoading,
    cards,
  };
};
