import { AlertTypes } from '@beauty/beauty-market-ui';

export const HTTP_STATUS_NETWORK_AUTHENTICATION_REQUIRED = 401;
export const HTTP_STATUS_PAYMENT_REQUIRED = 402;

export const ACCESS_TOKEN_KEY = 'token';
export const SELECTED_LOCALE_KEY = 'locale';
export const COOKIES_ACCEPTED_KEY = 'cookiesAccepted';

export const LOCALES = {
  en: 'en-US',
  ru: 'ru-RU',
  he: 'he-IL',
};

export const REQUEST_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  NONE: 'NONE',
};

export const REQUEST_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  NONE: 'NONE',
};

export const AlertType = {
  [REQUEST_STATUS.SUCCESS]: AlertTypes.SUCCESS,
  [REQUEST_STATUS.FAILURE]: AlertTypes.ERROR,
};
