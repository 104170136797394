import { Currency } from '../constants';

export enum SubscriptionPlanType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  UPGRADE = 'UPGRADE',
}

export enum SubscriptionPricePlanType {
  MAIN = 'MAIN',
  EMPLOYEE = 'EMPLOYEE',
  WHATSAPP = 'WHATSAPP',
}

export type SubscriptionPriceType = {
  annual: number;
  currency: Currency;
  id: string;
  monthly: number;
  planType: SubscriptionPricePlanType;
  subscriptionPlanId: string;
};

export type SubscriptionDataType = {
  id: string;
  limit: number;
  planType: SubscriptionPlanType;
  prices: Record<string, Record<SubscriptionPricePlanType, SubscriptionPriceType>>;
  public: boolean;
};

export enum SubscriptionFilter {
  Monthly = 'monthly',
  Annually = 'annual',
}
