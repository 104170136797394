import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, H5 } from '@beauty/beauty-market-ui';
import { GalleryImage } from '../style';

interface PhotoGalleryProps {
  photos: string[];
  setCheckedIndex: (index: number) => void;
}

const getImages: (slides: string[], setCheckedIndex: (index: number) => void) => JSX.Element[] = (
  slides,
  setCheckedIndex,
) =>
  slides.map((slide, id) => <GalleryImage src={slide} key={slide} alt="slide" onClick={() => setCheckedIndex(id)} />);

const PhotoGallery = ({ photos, setCheckedIndex }: PhotoGalleryProps) => {
  const { t } = useTranslation();

  const slides = useMemo(() => getImages(photos, setCheckedIndex), [photos]);
  const title = <H5>{t('organisation.about.photoGallery')}</H5>;
  return <Slider title={title} titleBottomMargin="24px" slides={slides} marginTop="24px" isPaginationEnabled />;
};

export default PhotoGallery;
