import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { INTELLECTUAL_PROPERTY_ARTICLES_COUNT, transComponents } from '../constants';

// TODO Refactor to enable eslint checks
export const IntellectualProperty = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfUse.intellectualProperty.title')}</PrivacyItemTitleWrapper>
        {Array(INTELLECTUAL_PROPERTY_ARTICLES_COUNT)
          .fill(0)
          .map((__, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <BodySmall key={i + 1} mb={i === INTELLECTUAL_PROPERTY_ARTICLES_COUNT - 1 ? '24px' : '16px'}>
              <Trans components={transComponents}>{t(`termsOfUse.intellectualProperty.article${i + 1}`)}</Trans>
            </BodySmall>
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
