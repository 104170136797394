import { useEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { updateIsOnTop } from '../../store/redux-slices/headerSlice';
import { useScrollY } from '../HomePage/hooks/useScrollPosition';
import { HowWeHelp } from './HowWeHelp';
import { Managing } from './Managing';
import { OurBenefits } from './OurBenefits';
import { StyledContainer } from './style';
import { TariffPlans } from './TariffPlans';
import { TopSection } from './TopSection';

export const ForBusiness = () => {
  const posY = useScrollY();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (posY > 10) dispatch(updateIsOnTop({ isOnTop: true }));
    else dispatch(updateIsOnTop({ isOnTop: false }));
  }, [posY, dispatch]);

  return (
    <StyledContainer>
      <TopSection />
      <HowWeHelp />
      <OurBenefits />
      <Managing />
      <TariffPlans />
    </StyledContainer>
  );
};
