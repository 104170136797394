import { useTranslation } from 'react-i18next';
import { Button, Popup, BodyLarge } from '@beauty/beauty-market-ui';
import { GappedFlex } from '../../../../../style';
import { PopupWrapper, StyledButton, TelegramIcon } from './style';

type TelegramPopupProps = {
  isTelegram: boolean;
  setPopup: (isPopup: boolean) => void;
  connect: () => void;
  disconnect: () => void;
};

export const TelegramPopup = ({ isTelegram, setPopup, connect, disconnect }: TelegramPopupProps) => {
  const { t } = useTranslation();
  const prefix = `profile.telegramPopup.${isTelegram ? 'disconnect' : 'connect'}`;
  return (
    <PopupWrapper>
      <Popup
        open
        title={t(`${prefix}.title`)}
        handleClose={() => setPopup(false)}
        FooterBody={
          <>
            {isTelegram && (
              <Button size="large" width="100%" design="secondary" onClick={() => setPopup(false)}>
                {t('profile.telegramPopup.cancelButton')}
              </Button>
            )}
            <StyledButton
              size="large"
              width="100%"
              onClick={isTelegram ? disconnect : connect}
              prefix={!isTelegram ? <TelegramIcon /> : null}
              isRed={isTelegram}
            >
              {t(`profile.telegramPopup.${isTelegram ? 'disconnectButton' : 'openButton'}`)}
            </StyledButton>
          </>
        }
      >
        <GappedFlex gap="24px" flexDirection="column">
          <BodyLarge>{t(`${prefix}.info1`)}</BodyLarge>
          <BodyLarge mb="24px">{t(`${prefix}.info2`)}</BodyLarge>
        </GappedFlex>
      </Popup>
    </PopupWrapper>
  );
};
