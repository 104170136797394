import { useEffect, useRef, useState } from 'react';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { UserEndpoints } from '../api/endpoints/endpoints';
import { getProfileFromApi } from '../helpers/profile';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectUser, updateProfile } from '../store/redux-slices/userSlice';

export const useGetProfile = () => {
  const [isLoading, setLoading] = useState(true);
  const [isPolling, setPolling] = useState(false);
  const [attempts, setAttempts] = useState(5);

  const timerRef = useRef<Nullable<NodeJS.Timeout | number>>(null);

  const dispatch = useAppDispatch();
  const { user, profile } = useAppSelector(selectUser);

  const clearTimer = () => timerRef.current && clearTimeout(timerRef.current);

  useEffect(() => {
    let isProfileLoading = true;

    const fetchProfile = async () => {
      setLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: UserEndpoints.Root,
        endpointParams: {
          id: user.userId,
          queryParams: 'include=contact,address,accountNotification,telegram',
        },
      });

      if (isProfileLoading && response) {
        dispatch(updateProfile(getProfileFromApi(response.data)));
        setLoading(false);
      }
    };

    if (user.userId) {
      fetchProfile();

      if (isPolling && !profile.telegram && attempts) {
        clearTimer();
        timerRef.current = setTimeout(() => {
          fetchProfile();
          setAttempts(prev => prev - 1);
        }, 3000);
      } else {
        setPolling(false);
        setAttempts(5);
        clearTimer();
      }
    }

    return () => {
      isProfileLoading = false;
      clearTimer();
    };
  }, [user.userId, isPolling, attempts]);

  return {
    isLoading,
    isPolling,
    setPolling,
  };
};
