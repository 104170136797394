import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../../../components';
import { getCurrencyOptions } from '../../../../../helpers';

export const EditCurrencySidebar = ({ currency, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const currencyOptions = getCurrencyOptions(t);
  const defaultCurrency = currencyOptions.find(cur => cur.id === currency) || currencyOptions[0];
  const [selected, setSelected] = useState<{ id: string }>(defaultCurrency);

  return (
    <SidebarSheet
      label={t('profile.updateCurrency')}
      descriptor={t('profile.chooseCurrency')}
      onClose={onClose}
      FooterBody={
        <>
          <Button
            size="large"
            width="100%"
            onClick={() => onSubmit(selected.id)}
            disabled={selected.id === defaultCurrency.id}
          >
            {t('button.apply')}
          </Button>
          <Button size="large" width="100%" design="secondary" onClick={onClose}>
            {t('button.cancel')}
          </Button>
        </>
      }
    >
      <Select
        selected={selected}
        onSelect={setSelected}
        options={currencyOptions}
        placeholder={t('profile.currency.currency')}
        isSeparator
        disabled
      />
    </SidebarSheet>
  );
};
