import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { apiRequest } from '../api/api';
// import { FAQCategoriesEndpoints } from '../api/endpoints/endpoints';
import { getFaqQuestions } from '../helpers/faq';
import { FAQCategoryObj, FAQQuestionsVariants } from '../page/FAQ/types';

export const useGetFAQCategories = (type: FAQQuestionsVariants) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<FAQCategoryObj[]>([]);

  useEffect(() => {
    let isQuestionsLoading = true;

    const fetchQuestions = async () => {
      setIsLoading(true);
      // const [, response] = await apiRequest.get({
      //   endpoint: FAQCategoriesEndpoints.Root,
      //   endpointParams: type,
      // });

      if (isQuestionsLoading) {
        setCategories(getFaqQuestions(type, t));
        setIsLoading(false);
      }
    };

    if (type) fetchQuestions();

    return () => {
      isQuestionsLoading = false;
    };
  }, [type, t]);

  return {
    isLoading,
    categories,
  };
};
