import { useTranslation } from 'react-i18next';
import { H3, BodyLarge } from '@beauty/beauty-market-ui';
import Star from '../../../../assets/orderSection/star.svg';
import { Card, ImgWrapper, InfoWrapper, StarIconWrapper } from './style';

export type BenefitCardProps = {
  title: string;
  description: string;
  imgUrl: string;
  starsCount?: number;
  isRight?: boolean;
};

// TODO Refactor to enable eslint checks
export const BenefitCard = ({ title, description, imgUrl, starsCount = 0, isRight = false }: BenefitCardProps) => {
  const { t } = useTranslation();
  return (
    <Card>
      <ImgWrapper imgUrl={imgUrl} isRight={isRight} />
      <InfoWrapper>
        <H3 textAlign="center">{title}</H3>
        <BodyLarge textAlign="center" width={['230px', '230px', '230px', '220px']}>
          {description}
        </BodyLarge>
        {!!starsCount && (
          <StarIconWrapper>
            {Array(starsCount)
              .fill(0)
              .map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <img key={index} src={Star} height="29px" width="29px" alt={t('order.cards.rated.icon')} />
              ))}
          </StarIconWrapper>
        )}
      </InfoWrapper>
    </Card>
  );
};
