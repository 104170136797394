import { useTranslation, Trans } from 'react-i18next';
import { H5, BodySmall, ShowMore } from '@beauty/beauty-market-ui';
import { GappedFlex } from '../../../style';

type SpecialistInformationProps = {
  languages: string[];
};

export const SpecialistInformation = ({ languages }: SpecialistInformationProps) => {
  const { t } = useTranslation();

  return (
    <GappedFlex gap="24px" flexDirection="column">
      <H5>{t('organisation.specialists.specialistInformation')}</H5>
      <GappedFlex gap="24px" flexDirection="column">
        <GappedFlex gap="4px" flexDirection="column">
          <BodySmall large>{t('organisation.specialists.languages.title')} </BodySmall>
          <BodySmall>
            {languages.map(language => t(`organisation.specialists.languages.${language}`)).join(', ')}{' '}
          </BodySmall>
        </GappedFlex>
        <GappedFlex gap="4px" flexDirection="column">
          <BodySmall large>{t('organisation.specialists.specialist-1.degree.title')} </BodySmall>
          <BodySmall>{t(`organisation.specialists.specialist-1.degree.info`)}</BodySmall>
        </GappedFlex>

        <GappedFlex gap="4px" flexDirection="column">
          <BodySmall large>{t('organisation.specialists.specialist-1.about.title')} </BodySmall>
          <ShowMore
            showMore={t('organisation.about.showMore')}
            showLess={t('organisation.about.showLess')}
            visibleLines={2}
            text={
              <BodySmall>
                <Trans
                  components={{
                    br: (
                      <>
                        <br />
                        <br />
                      </>
                    ),
                  }}
                >
                  {t(`organisation.specialists.specialist-1.about.info`)}
                </Trans>
              </BodySmall>
            }
          />
        </GappedFlex>
      </GappedFlex>
    </GappedFlex>
  );
};
