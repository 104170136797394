import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';

type UpdatePasswordParams = {
  token: string;
  password: string;
};

export const updatePassword = async (params: UpdatePasswordParams) => {
  const [, response] = await apiRequest.patch({
    endpoint: AuthEndpoints.ResetPassword,
    data: { ...params },
  });

  return response?.data;
};
