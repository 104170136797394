import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Nullable } from 'tsdef';
import {
  H3,
  PinFilledIcon,
  colors,
  BodySmall,
  Link,
  PhoneIcon,
  MailFillIcon,
  Button,
  Caption,
  InstagramMonoIcon,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage, instagramDomain } from '../../../constants';
import { handleSeeDirection } from '../../../helpers/organisation';
import { getTranslation } from '../../../helpers/utils';
import useSetCrumbsQuery from '../../../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setShowSearch } from '../../../store/redux-slices/organisationSlice';
import { searchState } from '../../../store/redux-slices/searchSlice';
import { GappedFlex } from '../../../style';
import { ContactWrapper, ContactBody, Map, ContactInfoBlock, ContactInfo, InstagramWrapper } from '../style';
import { Organisation } from '../types';

interface ContactProps {
  organisation: Organisation;
  showBtn: boolean;
  coordinates: Nullable<{ lat: number; lng: number }>;
  onMapClick: (value: string) => void;
}

const Contact = ({ organisation, showBtn, coordinates, onMapClick }: ContactProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { name, address, phone, email, social, addressDescription } = organisation;
  const { geolocation } = useAppSelector(searchState);
  const { orgId } = useParams();
  const crumbsQuery = useSetCrumbsQuery(name);

  return (
    <ContactWrapper>
      <H3>{t('organisation.contact.contact')}</H3>
      <ContactBody>
        <ContactInfoBlock>
          <GappedFlex gap="8px" flexDirection="column">
            <BodySmall>{address}</BodySmall>
            {addressDescription && (
              <Caption color={colors.grey.dark}>{getTranslation(addressDescription, language)}</Caption>
            )}
            <Link size="s" onClick={() => handleSeeDirection(coordinates, geolocation)}>
              {t('organisation.contact.seeDirections')}
            </Link>
          </GappedFlex>

          <ContactInfo marginTop={['32px', '32px', '32px', '40px']}>
            <PhoneIcon width="16" height="16" fill={colors.black.standard} />
            <Link design="black" size="s" href={`tel:${phone}`}>
              {phone}
            </Link>
          </ContactInfo>
          <ContactInfo marginTop="8px">
            <MailFillIcon width="16" height="16" />
            <Link design="black" size="s" href={`mailto:${email}`}>
              {email}
            </Link>
          </ContactInfo>
          {social?.instagram && (
            <ContactInfo marginTop="8px">
              <InstagramWrapper>
                <InstagramMonoIcon />
              </InstagramWrapper>
              <Link design="black" size="s" href={`${instagramDomain}/${social.instagram}`} target="_blank">
                {t('organisation.contact.instagram')}
              </Link>
            </ContactInfo>
          )}
        </ContactInfoBlock>
        <Map
          onClick={() => {
            onMapClick('map');
            dispatch(setShowSearch(false));
          }}
        >
          <PinFilledIcon width="25" height="25" fill={colors.blue.standard} />
        </Map>
      </ContactBody>
      {showBtn && (
        <Button
          size="large"
          width="100%"
          marginTop="40px"
          onClick={() => navigate(`${RouterUrl.Booking}/${orgId}${crumbsQuery}`, { state: { organisation } })}
        >
          {t('organisation.bookNow')}
        </Button>
      )}
    </ContactWrapper>
  );
};

export default Contact;
