import { ItemWrapper, OptionsItem } from './style';

const MenuItem = ({ title, selected, disabled, onClick }) => (
    <ItemWrapper
      role="option"
      aria-selected={selected}
      selected={selected}
      tabIndex={0}
      disabled={disabled}
      onClick={onClick}
    >
      <OptionsItem>{title}</OptionsItem>
    </ItemWrapper>
  );

export default MenuItem;
