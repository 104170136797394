import React, { MouseEvent, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import { Nullable } from 'tsdef';
import {
  CloseIcon,
  DownIcon,
  UpIcon,
  BodySmall,
  Flex,
  Scroll,
  useClickOutside,
  colors,
} from '@beauty/beauty-market-ui';
import { CheckIconStyled, DropdownHeaderWrapper, IconWrapper, OptionsWrapper, OptionWrapper } from '../../style';

type OptionType = {
  id: number;
  item: string;
  option: JSX.Element;
};

const colorScheme = {
  default: {
    backgroundColor: colors.grey.light,
    color: colors.grey.dark,
  },
  active: {
    backgroundColor: colors.grey.light,
    color: colors.black.standard,
  },
  selected: {
    backgroundColor: colors.blue.standard,
    color: colors.white.standard,
  },
};

type DateSortingProps = {
  selectedOptionId: Nullable<number>;
  onSelect: (selectedOptionId: Nullable<number>) => void;
};

export const DateSorting = ({ selectedOptionId, onSelect }: DateSortingProps) => {
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [close, setClose] = useState(false);

  const Arrow = useMemo(() => (isOptionsOpen ? UpIcon : DownIcon), [isOptionsOpen]);
  const optionsRef = useRef<HTMLElement>(null);

  const handleSelectOption = (id: number) => {
    selectedOptionId !== id && onSelect(id);
    setOptionsOpen(false);
  };

  const optionList: OptionType[] = ['newest', 'oldest'].map((item, index) => ({
    id: index,
    item,
    option: (
      <OptionWrapper onClick={() => handleSelectOption(index)} isSelected={index === selectedOptionId}>
        <BodySmall>{t(`organisation.rating.${item}`)}</BodySmall>
        {index === selectedOptionId && <CheckIconStyled />}
      </OptionWrapper>
    ),
  }));

  const selectedOption = optionList.find(option => option.id === selectedOptionId);
  const colorType = (() => {
    if (selectedOptionId !== null) return 'selected';
    if (isOptionsOpen) return 'active';
    return 'default';
  })();

  const handleOptionsOpen = () => {
    !close && setOptionsOpen(prev => !prev);
    setClose(false);
  };

  const handleCrossClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelect(null);
  };

  useClickOutside(optionsRef, () => {
    setClose(true);
    setOptionsOpen(false);
  });

  return (
    <Flex flexDirection="column" position="relative">
      <DropdownHeaderWrapper
        {...colorScheme[colorType]}
        onClick={() => {
          !isOptionsOpen && handleOptionsOpen();
        }}
        onMouseEnter={() => setClose(false)}
      >
        <BodySmall large lowline {...colorScheme[colorType]}>
          {selectedOption
            ? t(`organisation.rating.${optionList[selectedOption.id].item}`)
            : t('organisation.rating.date')}
        </BodySmall>
        {selectedOption ? (
          <IconWrapper p="1px" onClick={handleCrossClick} stroke={colorScheme[colorType].color}>
            <CloseIcon width="16" height="16" />
          </IconWrapper>
        ) : (
          <IconWrapper stroke={colorScheme[colorType].color}>
            <Arrow width="16" height="16" />
          </IconWrapper>
        )}
      </DropdownHeaderWrapper>
      {isOptionsOpen && (
        <OptionsWrapper ref={optionsRef}>
          <Flex flexDirection="column" gap="4px">
            <Scroll>{optionList.map(option => option.option)}</Scroll>
          </Flex>
        </OptionsWrapper>
      )}
    </Flex>
  );
};
