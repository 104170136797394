import { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Div } from '@beauty/beauty-market-ui';
import { transComponents } from '../../constants';
import { emailVerification } from '../../helpers/emailVerification';
import { useGetSearchParams } from '../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../routes/routes';

const EmailVerification = () => {
  const [isLinkWrong, setIsLinkWrong] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParamsAsObject] = useGetSearchParams();
  useEffect(() => {
    let isVerificating = true;
    const verification = async () => {
      setIsLoading(true);
      const result = await emailVerification({
        token: searchParamsAsObject?.token,
      });
      if (isVerificating && result.success) {
        if (result.data.email) {
          setEmail(result.data.email);
        }
        if (result.data.code && result.data.number) {
          setCode(result.data.code);
          setNumber(result.data.number);
        }
      } else if (result.statusCode === 401) {
        setIsLinkWrong(true);
      }
      setIsLoading(false);
    };

    if (searchParamsAsObject) verification();

    return () => {
      isVerificating = false;
    };
  }, [searchParamsAsObject]);

  const wrongLink = useCallback(() => {
    navigate(RouterUrl.SignUp, { state: { isWrongLink: true } });
  }, [navigate]);

  const emailVerified = useCallback(() => {
    navigate(RouterUrl.Registration, { state: { email, token: searchParamsAsObject?.token, code, number } });
  }, [navigate, email]);

  return (
    <Div>
      {email && emailVerified()}
      {isLinkWrong && (
        <p>
          <Trans components={transComponents}>{t('signUp.pleaseSignUp')}</Trans>
        </p>
      )}
      {/* {isLinkWrong && wrongLink()} */}
      {isLoading && <p>{t('signUp.emailIsVerifying')}</p>}
    </Div>
  );
};

export default EmailVerification;
