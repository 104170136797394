import { useTranslation } from 'react-i18next';
import { StyledLink } from './style';

type ColumnLinkProps = {
  text: string;
  link?: string;
  inBlank?: boolean;
};

type ColumnLinksProps = {
  columnData: ColumnLinkProps[];
};

const ColumnLinks = ({ columnData }: ColumnLinksProps) => {
  const { t } = useTranslation();

  return (
    <>
      {columnData.map((columnLink: ColumnLinkProps) => (
        <StyledLink
          key={columnLink.text}
          size="s"
          design="black"
          href={columnLink.link}
          target={columnLink.inBlank ? '_blank' : '_self'}
        >
          {t(columnLink.text)}
        </StyledLink>
      ))}
    </>
  );
};

export default ColumnLinks;
