import { useEffect, useState } from 'react';
import { SHOW_RADIUS, telAvivGeolocation } from '../constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { searchState, setBounds, setCurrentLocationBounds, setGeolocation } from '../store/redux-slices/searchSlice';

export const useGeolocation = () => {
  const [askedLocation, setAskedLocation] = useState(false);
  const dispatch = useAppDispatch();
  const { isCurrentLocation } = useAppSelector(searchState);

  const handleError = () => {
    dispatch(setGeolocation(telAvivGeolocation));
    setAskedLocation(true);
  };

  const askGeolocation = () =>
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        dispatch(
          setCurrentLocationBounds({
            bounds: {
              NE: { lat: latitude + 0.01 * SHOW_RADIUS, lng: longitude + 0.01 * SHOW_RADIUS },
              SW: { lat: latitude - 0.01 * SHOW_RADIUS, lng: longitude - 0.01 * SHOW_RADIUS },
            },
          }),
        );
        if (isCurrentLocation) {
          dispatch(
            setBounds({
              bounds: {
                NE: { lat: latitude + 0.01 * SHOW_RADIUS, lng: longitude + 0.01 * SHOW_RADIUS },
                SW: { lat: latitude - 0.01 * SHOW_RADIUS, lng: longitude - 0.01 * SHOW_RADIUS },
              },
              geolocation: { lat: latitude, lng: longitude },
            }),
          );
        }
        setAskedLocation(true);
      },
      () => handleError(),
    );

  useEffect(() => {
    navigator.geolocation ? askGeolocation() : handleError();
  }, []);

  return {
    askedLocation,
  };
};
