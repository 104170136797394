import { AxiosError, AxiosResponse } from 'axios';

type TestProps = {
  payload: any;
  success: boolean;
} & AxiosResponse;

// TODO Review and refactor to remove ts-ignore
export class Unavailable {
  public resolve = (response: TestProps) => response;

  public reject = async (error: AxiosError) => {
    if (error.message === 'Network Error') {
      error.response = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: 'NetworkError',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config: {},
        status: 418,
        statusText: '',
        data: 'Network error. Cannot connect to the server.',
      };
    }

    return Promise.reject(error);
  };
}
