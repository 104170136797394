import styled from 'styled-components';
import { Flex, Div, CloseSmallIcon } from '@beauty/beauty-market-ui';

export const DescriptionWrapper = styled(Div)`
  max-width: 400px;
`;

export const TitleWrapper = styled(Flex)`
  margin: 40px 0 16px;

  @media screen and (max-width: 992px) {
    margin: 24px 0 16px;
  }
`;

export const TextWrapper = styled(Div)`
  margin-bottom: 80px;

  @media screen and (max-width: 992px) {
    margin-bottom: 56px;
  }
`;

export const SupportCardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 992px) {
    margin-bottom: 80px;
    gap: 16px;
  }
`;

export const CloseIconWrapper = styled(CloseSmallIcon)`
  cursor: pointer;
`;
