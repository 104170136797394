import { Outlet } from 'react-router-dom';
import { Flex } from '@beauty/beauty-market-ui';
import { ImageBlock, InfoWrapper, LoginWrapper } from './style';

const Login = () => (
  <LoginWrapper>
    <InfoWrapper>
      <Flex width={['100%', '100%', '100%', '400px']} flexDirection="column">
        <Outlet />
      </Flex>
    </InfoWrapper>
    <ImageBlock />
  </LoginWrapper>
);

export default Login;
