import { useTranslation } from 'react-i18next';
import { H4, BodySmall, colors } from '@beauty/beauty-market-ui';
import LogoutImage from '../../../assets/exit-image.png';
import { MissingTimeslotsWrapper } from '../style';

type MissingTimeslotsProps = {
  paddingTop: string;
  width?: string;
};

const MissingTimeslots = ({ paddingTop, width }: MissingTimeslotsProps) => {
  const { t } = useTranslation();

  return (
    <MissingTimeslotsWrapper pt={paddingTop} pb="44px">
      <img src={LogoutImage} alt={t('logout.altImg')} width={width} />
      <H4>{t('organisation.booking.sorryEmpty')}</H4>
      <BodySmall color={colors.grey.dark}>{t('organisation.booking.noSpecialistsForDay')}</BodySmall>
    </MissingTimeslotsWrapper>
  );
};

export default MissingTimeslots;
