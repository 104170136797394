export const documents = [
  {
    id: 1,
    isFilled: true,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 22 dec 2023',
  },
  {
    id: 2,
    isFilled: false,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 06 mar 2023',
  },
  {
    id: 3,
    isFilled: true,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 22 dec 2023',
  },
  {
    id: 4,
    isFilled: true,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 22 dec 2023',
  },
  {
    id: 5,
    isFilled: false,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 17 apr 2023',
  },
  {
    id: 6,
    isFilled: true,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 17 apr 2023',
  },
  {
    id: 7,
    isFilled: false,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 17 apr 2023',
  },
  {
    id: 8,
    isFilled: false,
    service: 'Blacksmith Burbershop',
    title: 'Allergic reaction on color components',
    info: 'Fill until 17 apr 2023',
  },
  {
    id: 9,
    isFilled: true,
    service: 'Hairdressing',
    title: 'Allergic reaction on color components',
    info: 'Fill until 17 apr 2026',
  },
];

export const buttons = {
  filled: {
    first: 'edit',
    second: 'download',
  },
  nonFilled: {
    first: 'startFilling',
  },
};

export const organizations = [
  {
    id: '0',
    selected: true,
    text: '',
    translationKey: 'docs.allOrganizations',
  },
  {
    id: '1',
    imgUrl: 'https://i.pravatar.cc/300',
    selected: false,
    text: 'Jerry Brukhuimer',
  },
  {
    id: '2',
    imgUrl: 'https://i.pravatar.cc/301',
    selected: false,
    text: 'Josef Stalin',
  },
  {
    id: '3',
    imgUrl: 'https://i.pravatar.cc/302',
    selected: false,
    text: 'Gans Trahenbyurger',
  },
  {
    id: '4',
    imgUrl: 'https://i.pravatar.cc/303',
    selected: false,
    text: 'Kapitan Pihulka',
  },
];
