import { useEffect, useState } from 'react';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { ReviewEndpoints } from '../api/endpoints/endpoints';
import { useAppDispatch } from '../store/hooks';
import { setForceFetch } from '../store/redux-slices/userSlice';
import { ReviewType } from '../types/organisation';

const orderType = {
  0: 'date_desc',
  1: 'date_asc',
};

const pageSize = 5;

export const useGetOrganisationReviews = (
  id: string,
  page: number,
  order: Nullable<number>,
  score: Nullable<number>,
  specialist: Nullable<string>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<ReviewType[] | null>(null);
  const [pages, setPages] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isReviewsLoading = true;

    const fetchOrganisationReviews = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: ReviewEndpoints.OrganisationReview,
        endpointParams: {
          id,
          queryParams: `?page=${page}&size=${pageSize}${score ? `&score=${score}` : ''}${
            order !== null ? `&order=${orderType[order]}` : ''
          }${specialist ? `&orgSpecId=${specialist}` : ''}`,
        },
      });

      if (isReviewsLoading) {
        setReviews(response?.data.data);
        setPages(response?.data.pages);
        setTimeout(() => setIsLoading(false), 200);
      }
    };

    fetchOrganisationReviews();
    dispatch(setForceFetch(false));

    return () => {
      isReviewsLoading = false;
    };
  }, [id, page, order, score, specialist]);

  return {
    isLoading,
    reviews: reviews ?? null,
    pages,
  };
};
