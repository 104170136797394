import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodySmall, Flex, H7 } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { USER_CONTENT_ARTICLES_COUNT, USER_CONTENT_SUBSECTION_1_PARTS_COUNT, transComponents } from '../constants';
import { Section } from './Section';

// TODO Refactor to enable eslint checks
export const UserContent = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfUse.userContent.title')}</PrivacyItemTitleWrapper>
        {Array(USER_CONTENT_ARTICLES_COUNT)
          .fill(0)
          .map((__, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <BodySmall key={i} mb="24px">
              {t(`termsOfUse.userContent.article${i + 1}`)}
            </BodySmall>
          ))}
        <Section
          title={t(`termsOfUse.userContent.section1.title`)}
          body=""
          subSection={[
            <Flex key={1} flexDirection="column" mb="24px">
              <H7 mb="16px">
                <Trans components={transComponents}>
                  {t(`termsOfUse.userContent.section1.subsections.subsection1.title`)}
                </Trans>
              </H7>
              {Array(USER_CONTENT_SUBSECTION_1_PARTS_COUNT)
                .fill(0)
                .map((_____, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <BodySmall key={j} mb={j === USER_CONTENT_SUBSECTION_1_PARTS_COUNT - 1 ? '0px' : '16px'}>
                    <Trans components={transComponents}>
                      {t(`termsOfUse.userContent.section1.subsections.subsection1.body.part${j + 1}`)}
                    </Trans>
                  </BodySmall>
                ))}
            </Flex>,
          ]}
        />
      </PrivacyItemWrapper>
    );
  }),
);
