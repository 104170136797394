import { Navigate } from 'react-router-dom';
import { RouterUrl } from '../../routes/routes';

interface ProtectedRouteProps {
  isAllowed: boolean;
  component: JSX.Element;
  redirectPath?: string;
}

export const ProtectedRoute = ({
  component,
  isAllowed = false,
  redirectPath = RouterUrl.Login,
}: ProtectedRouteProps) => (isAllowed ? component : <Navigate to={redirectPath} replace />);
