import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Container } from '../../components';
import { useGetFAQCategories } from '../../hooks/useGetFAQCategories';
import { DisplayedQuestions } from './components/DisplayedQuestions';
import { FAQCategories } from './components/FAQCategories';
import FAQHeader from './components/FAQHeader';
import FAQHelper from './components/FAQHelper';
import { FAQContentWrapper } from './style';
import { FAQQuestionsVariants } from './types';

const FAQQuestions = () => {
  const { search } = useLocation();
  const [activeCategoryId, setActiveCategoryId] = useState<string>('0');

  const categoriesType: FAQQuestionsVariants = useMemo(() => {
    const parsedUrl = queryString.parse(search);
    return parsedUrl.type === FAQQuestionsVariants.Client ? FAQQuestionsVariants.Client : FAQQuestionsVariants.Business;
  }, [search]);

  const { categories } = useGetFAQCategories(categoriesType);

  return (
    <Container flexDirection="column">
      <>
        <FAQHeader />
        <FAQCategories
          categories={categories}
          activeCategoryId={activeCategoryId}
          setActiveCategoryId={setActiveCategoryId}
        />
        <FAQContentWrapper>
          <FAQHelper />
          <DisplayedQuestions categoriesType={categoriesType} activeCategoryId={activeCategoryId} />
        </FAQContentWrapper>
      </>
    </Container>
  );
};

export default FAQQuestions;
