import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import {
  YOUR_COMMITMENTS_SECTIONS_COUNT,
  YOUR_COMMITMENTS_SECTION_1_SUBSECTIONS_COUNT,
  YOUR_COMMITMENTS_SECTIONS_BODY_PARTS_COUNT,
  transComponents,
} from '../constants';
import { Section } from './Section';

// TODO Refactor to enable eslint checks
export const YourCommitments = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfUse.yourCommitments.title')}</PrivacyItemTitleWrapper>
        {Array(YOUR_COMMITMENTS_SECTIONS_COUNT)
          .fill(0)
          .map((__, index) => (
            <Section
              // eslint-disable-next-line react/no-array-index-key
              key={index + 1}
              isTitleNotH6
              title={t(`termsOfUse.yourCommitments.section${index + 1}.title`)}
              body={Array(YOUR_COMMITMENTS_SECTIONS_BODY_PARTS_COUNT[index])
                .fill(0)
                .map((___, i) => t(`termsOfUse.yourCommitments.section${index + 1}.body.part${i + 1}`))}
              subSection={
                index === 0
                  ? Array(YOUR_COMMITMENTS_SECTION_1_SUBSECTIONS_COUNT)
                      .fill(0)
                      .map((___, i) => (
                        <Flex
                          // eslint-disable-next-line react/no-array-index-key
                          key={i + 1}
                          flexDirection="column"
                          mb={i === YOUR_COMMITMENTS_SECTION_1_SUBSECTIONS_COUNT - 1 ? '24px' : '24px'}
                        >
                          <BodySmall large mb="8px">
                            <Trans components={transComponents}>
                              {t(`termsOfUse.yourCommitments.section${index + 1}.subsections.subsection${i + 1}.title`)}
                            </Trans>
                          </BodySmall>
                          <BodySmall>
                            <Trans components={transComponents}>
                              {t(`termsOfUse.yourCommitments.section${index + 1}.subsections.subsection${i + 1}.body`)}
                            </Trans>
                          </BodySmall>
                        </Flex>
                      ))
                  : undefined
              }
            />
          ))}
        <BodyLarge large mb="24px">
          {t(`termsOfUse.yourCommitments.section3.body.note`)}
        </BodyLarge>
      </PrivacyItemWrapper>
    );
  }),
);
