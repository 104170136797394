import React from 'react';
import { debounce } from 'lodash';
import { Button, SearchInput, Separator, Caption, theme } from '@beauty/beauty-market-ui';
import { getPlaces } from '../../../../helpers/search';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  searchState,
  setBounds,
  setCitiesList,
  setIsCurrentLocation,
} from '../../../../store/redux-slices/searchSlice';
import { getPlaceByPlaceId } from './helpers';
import { DropdownWrapper, ItemWrapper, OptionsItem, SearchDropdownWrapper, StyledIcon, StyledScroll } from './style';
import { RegionSearchProps } from './types';

export const RegionSearch = ({
  isOpen,
  buttonCaption,
  placeholder = 'Choose your location',
  bottomHint = 'Adjust location settings for the best search results',
  onSetLocation,
  onChangeOpen,
  onSetSearch,
  onGetCurrentLocation,
  onGetService,
}: RegionSearchProps) => {
  const dispatch = useAppDispatch();
  const { isCurrentLocation, citiesList, service } = useAppSelector(searchState);

  const handleInput = async (value: string) => {
    dispatch(setCitiesList(await getPlaces(value)));
    onSetSearch && onSetSearch(value);
  };

  return isOpen ? (
    <SearchDropdownWrapper isExtended={false}>
      <SearchInput
        id="Search"
        design="white"
        placeholder={placeholder}
        mb="24px"
        onInput={debounce(handleInput, 1000)}
      />

      <StyledScroll maxHeight="160px">
        <DropdownWrapper role="listbox" tabIndex={-1}>
          {citiesList?.map(item => (
            <ItemWrapper
              key={item.text}
              role="option"
              tabIndex={0}
              onClick={async () => {
                dispatch(setIsCurrentLocation(false));
                const response = await getPlaceByPlaceId(item.placeId);
                onChangeOpen && onChangeOpen(false);
                if (response) {
                  dispatch(setBounds(response));
                  service && onGetService(service, response.bounds);
                }
                onSetLocation && onSetLocation(item.text);
              }}
            >
              <OptionsItem>{item.text}</OptionsItem>
            </ItemWrapper>
          ))}
        </DropdownWrapper>
      </StyledScroll>

      <Separator mb="16px" />

      <Caption lowline color={theme.colors.grey.dark} mb="16px">
        {bottomHint}
      </Caption>

      <Button
        prefix={<StyledIcon disabled={isCurrentLocation} />}
        size="small"
        onClick={() => {
          dispatch(setIsCurrentLocation(true));
          onGetCurrentLocation && onGetCurrentLocation(service);
          onChangeOpen && onChangeOpen(false);
          onSetLocation && onSetLocation(`currentLocation`);
        }}
        disabled={isCurrentLocation}
      >
        {buttonCaption}
      </Button>
    </SearchDropdownWrapper>
  ) : null;
};
