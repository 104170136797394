import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { H2, SupportCard, BodySmall, Alert, AlertTypes, Flex } from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import { supportCards } from './constants';
import { SupportCardsWrapper, DescriptionWrapper, TitleWrapper, TextWrapper } from './style';
import SupportCardSidebar from './SupportCardSidebar';

const Support = () => {
  const { t } = useTranslation();

  const [isCardSupportOpen, setIsCardSupportOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(-1);
  const [sectionId, setSectionId] = useState(-1);
  const [alertType, setAlertType] = useState<Nullable<AlertTypes>>(null);

  const onSupportCardCancel = () => {
    setSelectedItemId(-1);
    setIsCardSupportOpen(false);
  };

  const supportCardList = supportCards.map(card => (
    <SupportCard
      key={card.id}
      id={card.id}
      title={t(`support.sections.${card.item}.title`)}
      description={t(`support.sections.${card.item}.description`)}
      onClick={() => {
        setSectionId(+card.id);
        setIsCardSupportOpen(true);
      }}
    />
  ));

  const alertProps = useMemo(
    () => ({
      isAlertVisible: !!alertType,
      type: alertType,
      title: alertType === AlertTypes.SUCCESS ? t('contactUs.successTitle') : t('contactUs.errorTitle'),
      subtitle: alertType === AlertTypes.SUCCESS ? t('contactUs.successDescription') : t('contactUs.errorDescription'),
    }),
    [alertType],
  );

  const currentOptions = useMemo(() => {
    const sectionCard = supportCards.find(card => +card.id === sectionId);
    if (!sectionCard) return [];
    return Array.from({ length: sectionCard.optionsCount }, (_, i) => ({
      item: t(`support.sections.${sectionCard.item}.options.option${i + 1}`),
      disabled: false,
    }));
  }, [supportCards, sectionId, t]);

  useEffect(() => {
    alertType &&
      setTimeout(() => {
        setAlertType(null);
      }, 5100);
    alertType === AlertTypes.SUCCESS && setIsCardSupportOpen(false);
  }, [alertType]);

  return (
    <Container flexDirection="column">
      <DescriptionWrapper>
        <TitleWrapper>
          <H2>{t('support.titlePage')}</H2>
        </TitleWrapper>
        <TextWrapper>
          <BodySmall>{t('support.description')}</BodySmall>
        </TextWrapper>
      </DescriptionWrapper>
      <SupportCardsWrapper>{supportCardList}</SupportCardsWrapper>
      {isCardSupportOpen && (
        <SupportCardSidebar
          isOpen={isCardSupportOpen}
          onCancel={onSupportCardCancel}
          sectionId={sectionId}
          options={currentOptions}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          alertType={alertType}
          setAlertType={setAlertType}
        />
      )}
      <Flex onClick={() => setAlertType(null)}>
        <Alert {...alertProps} />
      </Flex>
    </Container>
  );
};

export default Support;
