import React from 'react';
import { Flex, BodySmall, Switcher, colors } from '@beauty/beauty-market-ui';
import { Wrapper } from './style';

type HeaderAccordionProps = {
  text: string;
  description?: string;
};

export const HeaderAccordion = ({ text, description }: HeaderAccordionProps) => (
  <Wrapper>
    <Flex flexDirection="column">
      <BodySmall regular>{text}</BodySmall>
      {description && <BodySmall color={colors.grey.dark}>{description}</BodySmall>}
    </Flex>
    <Switcher size="small" defaultChecked />
  </Wrapper>
);
