import { MutableRefObject, useEffect, useState } from 'react';
import { Link, getMinWidthMediaQuery, useMediaQuery, Slider, Tab } from '@beauty/beauty-market-ui';
import { MenuWrapper } from './style';

type AnchorMenuProps = {
  menuItems: string[];
  hrefs: MutableRefObject<Element[]>;
  activeSection?: number;
  onClick?: (isClicked: boolean) => void;
};

export const AnchorMenu = ({ menuItems, activeSection, hrefs, onClick }: AnchorMenuProps) => {
  const [active, setActive] = useState<number>(-1);
  const executeScroll = (i: number) => hrefs.current[i].scrollIntoView({ behavior: 'smooth', block: 'start' });

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);
  const handleClick = (i: number) => {
    setActive(i);
    executeScroll(i);
    onClick && onClick(true);

    setTimeout(() => {
      onClick && onClick(false);
    }, 1000);
  };

  const renderLink = (item, i) => (
    <Link key={i} design={i === active ? 'blue' : 'black'} size="s" onClick={() => handleClick(i)}>
      {item}
    </Link>
  );

  useEffect(() => {
    setActive(activeSection ?? -1);
  }, [activeSection]);

  return (
    <MenuWrapper>
      {isMobile ? (
        <>
          {/* <Slider
          width="100%"
          inlineSlider
          slides={menuItems.map((item, i) => {
            return (
              <Link
                width="max-content"
                key={i}
                design={i === active ? 'blue' : 'black'}
                size="s"
                onClick={() => handleClick(i)}
              >
                {item.length > 12 ? item.substring(0, 12) + '...' : item}
              </Link>
            );
          })}
        /> */}
          <div />
        </>
      ) : (
        menuItems.map((item, i) => renderLink(item, i))
      )}
    </MenuWrapper>
  );
};
