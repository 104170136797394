import { TFunction } from 'react-i18next';
import _, { lowerCase } from 'lodash';
import { Nullable } from 'tsdef';
import { weekDays } from '../../constants';
import { getTranslation } from '../../helpers/utils';
import {
  OrganisationBusinessHours,
  ResponseOrganisation,
  OrganisationService,
  ResponseOrganisationService,
  Categories,
  OrganisationWorkTime,
} from './types';

const getHoursAndMinutesForUserLocale = (durationInMinutes: number, language: string) => {
  const hours = parseInt(`${durationInMinutes / 60}`, 10);
  const minutes = durationInMinutes % 60;

  const enFormatter = new Intl.RelativeTimeFormat('EN', { numeric: 'always' });
  const formatter = new Intl.RelativeTimeFormat(language, { numeric: 'always' });

  const hoursParts =
    language !== 'HE'
      ? formatter.formatToParts(hours, 'hours')
      : [
          { value: '' },
          { value: enFormatter.formatToParts(hours, 'hours')[1].value },
          { value: formatter.formatToParts(0, 'hours')[2].value },
        ];
  const minsParts = formatter.formatToParts(minutes, 'minutes');

  const minutesDuration = minutes ? `${minsParts[1].value}${minsParts[2].value}` : '';
  const hoursDuration = `${hoursParts[1].value}${hoursParts[2].value}`;

  return hours && hoursDuration ? `${hoursDuration} ${minutesDuration}` : minutesDuration;
};

export const getFormattedCurrency = (amount: number, currency: Nullable<string>, language?: string) => {
  const formatter = Intl.NumberFormat(language !== 'HE' ? 'EN' : language, {
    currency: currency ?? 'ILS',
    style: 'currency',
    maximumFractionDigits: 0,
  });
  return formatter.format(amount);
};

// Object used to create Date obj for dayOfWeek enum which we get from BE
export const WEEKDAYS = {
  SUNDAY: new Date(Date.UTC(2023, 0, 8)),
  MONDAY: new Date(Date.UTC(2023, 0, 2)),
  TUESDAY: new Date(Date.UTC(2023, 0, 3)),
  WEDNESDAY: new Date(Date.UTC(2023, 0, 4)),
  THURSDAY: new Date(Date.UTC(2023, 0, 5)),
  FRIDAY: new Date(Date.UTC(2023, 0, 6)),
  SATURDAY: new Date(Date.UTC(2023, 0, 7)),
};

const getFormattedWeekday = (day: string, language: string) => {
  const weekdayFormatter = Intl.DateTimeFormat(language, { weekday: 'short' });
  const date = WEEKDAYS[day.toUpperCase()];
  return weekdayFormatter.format(date);
};

export const getOrganisationBusinessHours = (orgBusinessHours: OrganisationBusinessHours[], language: string) => {
  const workTime: OrganisationWorkTime[] = [];
  weekDays.map(day => {
    const dayIndex = orgBusinessHours.findIndex(hours => hours.dayOfWeek === day);
    const formattedDay = getFormattedWeekday(day, language);
    dayIndex > -1
      ? workTime.push({
          weekday: formattedDay,
          openTime: orgBusinessHours[dayIndex].start,
          closeTime: orgBusinessHours[dayIndex].end,
          isWeekend: false,
        })
      : workTime.push({
          weekday: formattedDay,
          isWeekend: true,
        });
    return 1;
  });
  return workTime;
};

export const getOrganisationCategoriesIds = (orgService: ResponseOrganisationService[]) =>
  _.uniq(_.map(orgService, service => service.category?.id));

export const getOrganisationServices = (
  orgService: ResponseOrganisationService[],
  categories: Categories[],
  currency: Nullable<string>,
  language: string,
  t: TFunction<'translation', undefined>,
) =>
  _.map(orgService, service => {
    const serviceCategory = _.head(_.values(_.pickBy(categories, { id: service.category.id })));
    return {
      id: service.id,
      label: getTranslation(service.title, language),
      description: service.duration ? getHoursAndMinutesForUserLocale(Number(service.duration), language) : '',
      // TODO: remove price choise after BE fixed
      price:
        service.price !== null && serviceCategory?.parentId !== 'd3147656-b459-4c4f-9175-9355f6e1b454'
          ? getFormattedCurrency(Number(service.price), currency, language)
          : t('organisation.booking.nullPrice'),
      categoryId: serviceCategory!.id,
      title: serviceCategory!.name,
      duration: service.duration,
    };
  });

const getOrganisationCategoryById = (
  categoryId: string,
  categories: Categories[],
  orgServices: OrganisationService[],
) => {
  const category = _.head(_.values(_.pickBy(categories, { id: categoryId })));
  const services = _.values(_.pickBy(orgServices, { categoryId }));
  return {
    id: categoryId,
    name: category!.name,
    description: category!.description,
    offersCount: services.length,
    services,
  };
};

export const mapOrganisation = (
  organisation: ResponseOrganisation,
  language: string,
  t: TFunction<'translation', undefined>,
) => {
  const address = organisation.address ? organisation.address.address : null;
  const addressDetails = address
    ? [address[0].city, address[0].street, address[0].building, address[0].office, address[0].postal].filter(Boolean)
    : [];

  const mappedCategories = organisation.category?.map(cat => ({
    id: cat.id,
    parentId: cat.parent?.id,
    name: getTranslation(cat.title, language),
    description: getTranslation(cat.descr, language),
  }));
  const mappedProperties = organisation?.property?.map(prop => ({
    id: prop.id,
    title: getTranslation(prop.title, language),
    iconUrl: prop.iconUrl,
  }));
  const categoriesIds = getOrganisationCategoriesIds(organisation.orgService);
  const services = getOrganisationServices(
    organisation.orgService,
    mappedCategories,
    organisation.headOrganization?.currency,
    language,
    t,
  );
  const categories = _.map(categoriesIds, id => getOrganisationCategoryById(id, mappedCategories, services));
  const workSchedule = getOrganisationBusinessHours(organisation.orgBusinessHours, language);
  // const orgDescription = getTranslation(organisation.headOrganization.summary, language);

  const socialNetworks = organisation.contact?.socialNetwork.reduce(
    (acc, arg) => ({ ...acc, [lowerCase(arg.label)]: arg.link }),
    {},
  );

  return {
    id: organisation.id,
    headOrgId: organisation.headOrgId,
    name: organisation.name,
    orgName: organisation.orgName,
    mainPhoto: organisation?.mainPhoto?.url,
    photo: organisation.photo?.filter(item => item.id !== organisation?.mainPhoto?.id).map(photo => photo.url),
    photosNum: organisation.photo?.length ? organisation.photo.length - 1 : 0,
    address: addressDetails.length ? addressDetails.join(', ') : '',
    addressDescription: address ? address[0].addressDescr : null,
    email: organisation.contact?.email.email,
    phone: `${organisation.contact?.phone[0].code} ${organisation.contact?.phone[0].number}`,
    social: socialNetworks,
    timezone: organisation.timezone,
    workSchedule,
    description: organisation.description,
    orgDescr: organisation.orgDescr,
    offers: {
      categories,
    },
    property: mappedProperties || [],
    team: organisation.orgSpecialist.map(spec => ({
      id: spec.orgSpecId,
      name: `${spec.name} ${spec.surname}`,
      avatarUrl: spec.avatarUrl,
      role: spec.role,
      specialization: spec.specialization,
      rating: spec.rating,
    })),
  };
};
