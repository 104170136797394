import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Nullable } from 'tsdef';
import { Button, Sidebar, useMediaQuery, BottomSheet, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import { ProfileType } from '../../../../../types/user';
import { ProfileForm } from '../../Profile.definitions';

import { EditProfileForm } from '../Forms/EditProfile/EditProfileForm';
import { UploadPhotoSidebar } from './UploadPhotoSidebar';

export type EditProfileSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  profile: ProfileType;
  setPhoto: (image?: Nullable<File>) => void;
  isEditMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  photo?: Nullable<File>;
};

export const EditProfileSidebar = ({
  profile,
  isOpen,
  setIsOpen,
  setPhoto,
  photo,
  isEditMode,
  setEditMode,
}: EditProfileSidebarProps) => {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<ProfileForm>>();

  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setEditMode(false);
    setIsOpen(false);
    setPhoto(undefined);
  };

  const content = (
    <EditProfileForm
      ref={formRef}
      profile={profile}
      photo={photo}
      setPhoto={setPhoto}
      onSubmit={handleClose}
      setSubmitButtonActive={setIsSubmitActive}
      setUploadOpen={setUploadOpen}
      isEditMode={isEditMode}
      setEditMode={setEditMode}
    />
  );
  const footerBody = (
    <Button
      disabled={!isSubmitActive}
      design="primary"
      mt="16px"
      width="100%"
      size="large"
      type="submit"
      onClick={() => {
        formRef.current && formRef.current.handleSubmit();
      }}
    >
      {t('profile.applyChanges')}
    </Button>
  );

  const generalProps = {
    isOpen,
    label: t('profile.editProfile'),
    onClose: handleClose,
    FooterBody: footerBody,
  };

  const uploadComponent = (
    <UploadPhotoSidebar
      isOpen={isUploadOpen}
      setIsOpen={setUploadOpen}
      isEdit={!!profile.avatarUrl}
      setPhoto={setPhoto}
      setEditMode={setEditMode}
    />
  );

  return (
    <>
      {isLarge ? (
        <Sidebar {...generalProps}>{content}</Sidebar>
      ) : (
        <BottomSheet {...generalProps} detent="content-height" content={content} />
      )}
      {uploadComponent}
    </>
  );
};
