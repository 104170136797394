import { H2, BodySmall, Avatar } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { WelcomWrapper } from '../style';

const Welcome = () => {
  const { user } = useAppSelector(selectUser);
  return (
    <WelcomWrapper>
      <Avatar size="xl" url="https://i.pravatar.cc/300" />
      <BodySmall large mb="8px" mt="30px">
        Welcome back
      </BodySmall>
      <H2 mb="32px" mt="0px">
        {user.name}
      </H2>
      <BodySmall large={false} textAlign="center">
        You have successfully logged <br />
        into your account!
      </BodySmall>
    </WelcomWrapper>
  );
};

export default Welcome;
