import { useTranslation } from 'react-i18next';
import { Flex, Sidebar, useMediaQuery, BottomSheet, getMinWidthMediaQuery, BodyLarge } from '@beauty/beauty-market-ui';
import DeleteAccountIcon from '../../../../../assets/info-image.png';
import { DeleteAccountForm } from '../Forms/DeleteAccountForm';

export type DeleteAccountSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const DeleteAccountSidebar = ({ isOpen, setIsOpen }: DeleteAccountSidebarProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setIsOpen(false);
  };

  const content = (
    <Flex flexDirection="column">
      <BodyLarge large mb="16px">
        {t('profile.whyDelete')}
      </BodyLarge>
      <DeleteAccountForm onClose={handleClose} />
    </Flex>
  );

  const generalProps = {
    isOpen,
    onClose: handleClose,
    label: t('profile.deletingAccount'),
  };

  return isLarge ? (
    <Sidebar {...generalProps} descriptor={t('profile.whyDelete')}>
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" height="100%">
        <Flex pt="94px">
          <img src={DeleteAccountIcon} alt="" />
        </Flex>
        <DeleteAccountForm onClose={handleClose} />
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};
