import { useRef } from 'react';
import { Div, useClickOutside } from '@beauty/beauty-market-ui';
import { DropdownWrapper, StyledButton } from './style';
import { MenuProps } from './types';

const Menu = ({ onChange, open, children, placement = 'bottom-start' }: MenuProps) => {
  const dropdownRef = useRef<HTMLUListElement>(null);

  useClickOutside(dropdownRef, () => {
    onChange(false);
  });

  return (
    <Div position="relative" ref={dropdownRef}>
      <StyledButton>
        {open && (
          <DropdownWrapper role="listbox" tabIndex={-1} placement={placement}>
            {children}
          </DropdownWrapper>
        )}
      </StyledButton>
    </Div>
  );
};

export default Menu;
