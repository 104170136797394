import { UKFlag, IsraelFlag, RussiaFlag } from '@beauty/beauty-market-ui';

export const languageOptions = [
  {
    flag: <UKFlag width="21px" height="21px" />,
    language: 'English',
    sortLan: 'en',
  },
  {
    flag: <IsraelFlag width="21px" height="21px" />,
    language: 'Hebrew',
    sortLan: 'he',
  },
  {
    flag: <RussiaFlag width="21px" height="21px" />,
    language: 'Russian',
    sortLan: 'ru',
  },
];
