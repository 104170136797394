import { useTranslation } from 'react-i18next';
import { EventCard, Flex, Avatar, Link } from '@beauty/beauty-market-ui';
import { handleSeeDirection } from '../../../../helpers/organisation';
import { useAppSelector } from '../../../../store/hooks';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import { OrganisationInfo } from '../constants';

type OrganisationProps = {
  info: OrganisationInfo;
};

const Organisation = ({ info }: OrganisationProps) => {
  const { t } = useTranslation();
  const { geolocation } = useAppSelector(searchState);

  return (
    <Flex mt="12px" mb="20px">
      <Avatar size="s" url={info.icon} rating="" />
      <Flex flexDirection="column" mt="-8px" ml="16px">
        <EventCard
          title={info.category}
          label={info.name}
          placeholder={info.address
            .filter(Boolean)
            .map(part => part)
            .join(', ')}
          isExtended
        />
        <Link size="s" mt="-6px" onClick={() => handleSeeDirection(info.coordinates, geolocation)}>
          {t('appointments.seeDirections')}
        </Link>
      </Flex>
    </Flex>
  );
};

export { Organisation };
