import styled from 'styled-components';
import { colors, Flex } from '@beauty/beauty-market-ui';

export const FAQCardWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 510px;
  width: 100%;
  border: 1px solid ${colors.grey.light};
  border-radius: 24px;
  padding: 25px;
  padding-right: 30px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const FAQCardsWrapper = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  @media screen and (max-width: 992px) {
    margin-bottom: 80px;
    gap: 32px;
  }
`;

export const FAQContentWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 80px;

  @media screen and (min-width: 640px) {
    flex-direction: row;
    align-items: start;
  }
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const FAQHelperWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-width: 290px;
  height: 100%;
  max-height: 176px;
  gap: 24px;
  padding: 24px;
  border: 1px solid ${colors.grey.light};
  border-radius: 16px;
`;

export const FAQQuestionsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 100%;

  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 30px;
  }

  @media screen and (min-width: 1024px) {
    gap: 40px;
  }
`;

export const SliderWrapper = styled(Flex)`
  margin-top: 29px;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    margin-top: 34px;
    margin-bottom: 56px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const FAQHeaderWrapper = styled(Flex)`
  white-space: nowrap;
  text-wrap: wrap;
  align-items: start;
  margin-top: 24px;

  @media screen and (min-width: 992px) {
    margin-top: 40px;
  }
`;

export const BreadcrumbsWrapper = styled(Flex)`
  margin-bottom: 29px;

  @media screen and (min-width: 768px) {
    margin-bottom: 46px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 69px;
  }
`;

export const FAQChevronWrapper = styled(Flex)`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 4px 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
