import { memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { H6, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';

export const OurPartnersServices = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.ourPartnersServices.title')}</PrivacyItemTitleWrapper>
        <BodyLarge large mb="24px">
          {t('termsOfService.ourPartnersServices.article1')}
        </BodyLarge>
        <BodySmall mb="24px">{t('termsOfService.ourPartnersServices.article2')}</BodySmall>
        <H6 mb="24px">{t('termsOfService.ourPartnersServices.article3')}</H6>
        <BodySmall mb="16px">{t('termsOfService.ourPartnersServices.article4')}</BodySmall>
        <BodySmall mb="24px">{t('termsOfService.ourPartnersServices.article5')}</BodySmall>
        <H6 mb="24px">{t('termsOfService.ourPartnersServices.article6')}</H6>
        <BodySmall mb="24px">{t('termsOfService.ourPartnersServices.article7')}</BodySmall>
      </PrivacyItemWrapper>
    );
  }),
);
