import { useState } from 'react';
import { SubscriptionPlanType } from '../../types';
import { BottomSection } from './BottomSection';
import { TariffPlansList } from './components/TariffPlansList';
import { TariffPlansTable } from './components/TariffPlansTable/TariffPlansTable';
import { TariffPlansTitle } from './components/TariffPlansTitle';
import { TariffPlansWrapper } from './style';

export const TariffPlans = () => {
  const [active, setActive] = useState(0);

  const bestPlan = SubscriptionPlanType.PRO;

  return (
    <TariffPlansWrapper>
      <TariffPlansTitle value={active} onChange={setActive} />
      <TariffPlansList value={active} />
      <TariffPlansTable bestPlan={bestPlan} />
      <BottomSection />
    </TariffPlansWrapper>
  );
};
