import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, TagButton } from '@beauty/beauty-market-ui';
import { documents } from '../constants';

type MenuProps = {
  onSetActive: (activeTab: number) => void;
};

const DocsCategories = ({ onSetActive }: MenuProps) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(0);

  const onTagsClick = (id: number) => {
    setActive(id);
    onSetActive(id);
  };

  return (
    <Flex mb="40px">
      <TagButton
        text={t('docs.all')}
        design={active === 0 ? 'active' : 'default'}
        number={documents.length}
        onClick={() => onTagsClick(0)}
        mr="12px"
      />
      <TagButton
        text={t('docs.requested')}
        design={active === 1 ? 'active' : 'default'}
        number={documents.filter(doc => !doc.isFilled).length}
        onClick={() => onTagsClick(1)}
        mr="12px"
      />
      <TagButton
        text={t('docs.completed')}
        design={active === 2 ? 'active' : 'default'}
        number={documents.filter(doc => doc.isFilled).length}
        onClick={() => onTagsClick(2)}
      />
    </Flex>
  );
};

export { DocsCategories };
