import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Container } from '../../components';

export const StyledContainer = styled(Container)`
  display: block !important;
  margin-top: 24px;
  margin-bottom: 80px;
  ${mediaQueries.md} {
    display: flex !important;
    margin-top: 40px;
    margin-bottom: 0px;
  }
`;

export const StyledFlex = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  ${mediaQueries.md} {
    flex-direction: row;
  }
`;

export const ImgWrapper = styled(Flex)`
  img {
    width: 100%;
    border-radius: 24px;
  }
`;
