import { StyledButton } from './style';

type ApplicationButtonProps = {
  iconSource: string;
  iconSrc: string;
  design: string;
  altText: string;
};

export const ApplicationButton = ({ iconSource, design, altText, iconSrc }: ApplicationButtonProps) => (
  <StyledButton design={design}>
    <img src={iconSource} alt={altText} />
    <img src={iconSrc} alt={altText} />
  </StyledButton>
);
