import { useTranslation } from 'react-i18next';
import { Div, H2, BodySmall } from '@beauty/beauty-market-ui';

const SentEmailNotification = () => {
  const { t } = useTranslation();

  return (
    <Div textAlign="center">
      <H2 mb="16px">{t('signUp.checkInbox')}</H2>
      <BodySmall>{t('registration.resetInfo')}</BodySmall>
    </Div>
  );
};

export default SentEmailNotification;
