import { forwardRef, memo } from 'react';
import { useField } from 'formik';
import { Textfield, InputProps, Div } from '@beauty/beauty-market-ui';
import { useStatus } from '../hooks/use-status';

type FormikTextfieldProps = InputProps & {
  rows: number;
  name: Pick<InputProps, 'name'>;
  id: string;
  placeholder?: string;
  disableDefaultOnBlur?: boolean;
  infoCaption?: string;
};

export const FormikTextfield = memo(
  forwardRef((props: FormikTextfieldProps, ref) => {
    const {
      placeholder,
      name,
      onBlur: explicitOnBlur,
      clearable,
      disableDefaultOnBlur,
      infoCaption,
      variant = 'default',
      ...otherExplicitProps
    } = props;
    const [formikProps, meta] = useField(name as string);
    const { onBlur: formikDefaultOnBlur, value, ...otherFormikProps } = formikProps;

    const status = useStatus(meta);

    return (
      <Div>
        <Textfield
          autoComplete="nope"
          width="100%"
          ref={ref}
          placeholder={placeholder}
          aria-label={placeholder}
          nonValid={status === 'invalid'}
          caption={status === 'invalid' ? `${meta.error}${infoCaption ? ` - ${infoCaption}` : ``}` : infoCaption}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
            !disableDefaultOnBlur && formikDefaultOnBlur(event);
            explicitOnBlur?.(event);
          }}
          value={value || props.defaultValue}
          {...otherFormikProps}
          {...otherExplicitProps}
          {...{ variant }}
        />
      </Div>
    );
  }),
);
