import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appointmentsReducer from './redux-slices/appointmentsSlice';
import categoriesReducer from './redux-slices/categoriesSlice';
import headerReducer from './redux-slices/headerSlice';
import historyReducer from './redux-slices/historySlice';
import offersFilterReducer from './redux-slices/offersFilterSlice';
import organisationReducer from './redux-slices/organisationSlice';
import searchReducer from './redux-slices/searchSlice';
import userReducer from './redux-slices/userSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  search: searchReducer,
  header: headerReducer,
  offersFilter: offersFilterReducer,
  history: historyReducer,
  appointments: appointmentsReducer,
  organisation: organisationReducer,
  categories: categoriesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
