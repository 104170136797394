import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DEFAULT_MIN_PRICE, DEFAULT_MAX_PRICE, DEFAULT_MIN_DISTANCE, DEFAULT_MAX_DISTANCE } from '../../constants';
import { RangeType } from '../../types';
import { RootState } from '../store';

export interface FilterState {
  sorting: { value: string; isDefault: boolean; appliedFiltersCount: number };
  looking: { value: string[]; isDefault: boolean; appliedFiltersCount: number };
  priceRange: { isDefault: boolean; appliedFiltersCount: number } & RangeType;
  distanceRange: { isDefault: boolean; appliedFiltersCount: number } & RangeType;
  language: { value: string[]; isDefault: boolean; appliedFiltersCount: number };
}

export const initialState: FilterState = {
  sorting: { value: 'rate', isDefault: true, appliedFiltersCount: 0 },
  looking: { value: [], isDefault: false, appliedFiltersCount: 0 },
  priceRange: {
    min: DEFAULT_MIN_PRICE,
    max: DEFAULT_MAX_PRICE,
    isDefault: true,
    appliedFiltersCount: 0,
  },
  distanceRange: {
    min: DEFAULT_MIN_DISTANCE,
    max: DEFAULT_MAX_DISTANCE,
    isDefault: true,
    appliedFiltersCount: 0,
  },
  language: {
    value: (() => {
      if (localStorage.getItem('i18nextLng') === 'en') return ['EN'];
      if (localStorage.getItem('i18nextLng') === 'ru') return ['RU'];
      return ['HE'];
    })(),
    isDefault: true,
    appliedFiltersCount: 0,
  },
};

export const offersFilterSlice = createSlice({
  name: 'offersFilter',
  initialState,
  reducers: {
    updateSorting: (state, action: PayloadAction<{ sorting: string }>) => {
      const isDefault = initialState.sorting.value === action.payload.sorting;
      state.sorting.value = action.payload.sorting;
      state.sorting.isDefault = isDefault;

      if (!isDefault) {
        state.sorting.appliedFiltersCount = 1;
      }
    },
    updateLooking: (state, action: PayloadAction<{ looking: string[] }>) => {
      const { payload } = action;
      const newFilterValues = _.without(payload.looking, ...initialState.looking.value);
      const isDefault = newFilterValues.length === 0;
      state.looking.value = payload.looking;
      state.looking.isDefault = isDefault;

      if (!isDefault) {
        state.looking.appliedFiltersCount = newFilterValues.length;
      }
    },
    updatePriceRange: (state, action: PayloadAction<RangeType>) => {
      const { payload } = action;
      const isDefault = _.isEqual(_.pick(initialState.priceRange, Object.keys(payload)), payload);
      state.priceRange = { ...state.priceRange, ...payload };
      state.priceRange.isDefault = isDefault;

      if (!isDefault) {
        state.priceRange.appliedFiltersCount = 1;
      }
    },
    updateDistanceRange: (state, action: PayloadAction<RangeType>) => {
      const { payload } = action;
      const isDefault = _.isEqual(_.pick(initialState.distanceRange, Object.keys(payload)), payload);
      state.distanceRange = { ...state.distanceRange, ...payload };
      state.distanceRange.isDefault = isDefault;

      if (!isDefault) {
        state.distanceRange.appliedFiltersCount = 1;
      }
    },
    updateLanguage: (state, action: PayloadAction<{ language: string[] }>) => {
      const { payload } = action;
      const newFilterValues = _.without(payload.language, ...initialState.language.value);
      const isDefault = newFilterValues.length === 0;

      state.language.value = payload.language;
      state.language.isDefault = isDefault;

      if (!isDefault) {
        state.language.appliedFiltersCount = newFilterValues.length;
      }
    },
    resetState: () => initialState,
  },
});

export const { updateSorting, updateLooking, updatePriceRange, updateDistanceRange, updateLanguage, resetState } =
  offersFilterSlice.actions;

export const selectOffersFilter = (state: RootState) => state.offersFilter;

export const selectAppliedFiltersCount = (state: RootState) => {
  let appliedFiltersCount = 0;
  Object.values(state.offersFilter).forEach(filterValue => {
    if (!filterValue.isDefault) {
      appliedFiltersCount += filterValue.appliedFiltersCount;
    }
  });

  return appliedFiltersCount;
};

export default offersFilterSlice.reducer;
