import { memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { GENERAL_SECTIONS_BODY_PARTS_COUNT, GENERAL_SECTIONS_COUNT } from '../constants';
import { Section } from './Section';

// TODO Refactor to enable eslint checks
export const General = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.general.title')}</PrivacyItemTitleWrapper>

        {Array(GENERAL_SECTIONS_COUNT)
          .fill(0)
          .map((__, index) => (
            <Section
              // eslint-disable-next-line react/no-array-index-key
              key={index + 1}
              title={t(`termsOfService.general.section${index + 1}.title`)}
              body={Array(GENERAL_SECTIONS_BODY_PARTS_COUNT[index])
                .fill(0)
                .map((___, i) => t(`termsOfService.general.section${index + 1}.body.part${i + 1}`))}
            />
          ))}
        <BodyLarge large>{t(`termsOfService.general.section${GENERAL_SECTIONS_COUNT}.body.note`)}</BodyLarge>
      </PrivacyItemWrapper>
    );
  }),
);
