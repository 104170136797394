import { BodyLarge, BodySmall, Flex, H6, Toggle } from '@beauty/beauty-market-ui';
import { NotificationItemWrapper } from './style';

type NotificationItemProps = {
  id: string;
  type: string;
  title: string;
  info: string;
  checked: boolean;
  onChange: (id: string, type: string, checked: boolean) => void;
  disabled?: boolean;
  isLarge?: boolean;
};

const NotificationItem = ({
  id,
  type,
  title,
  info,
  checked,
  onChange,
  isLarge,
  disabled = false,
}: NotificationItemProps) => (
  <NotificationItemWrapper>
    <Flex flexDirection="column">
      {isLarge ? <H6>{title}</H6> : <BodyLarge large>{title}</BodyLarge>}
      <BodySmall>{info}</BodySmall>
    </Flex>
    <Toggle
      size="small"
      checked={checked}
      onChange={e => onChange(id, type, e.currentTarget.checked)}
      disabled={disabled}
    />
  </NotificationItemWrapper>
);

export { NotificationItem };
