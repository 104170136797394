import { telAvivBounds, telAvivGeolocation } from '../../../../constants';
import { BoundsType, GeolocationType } from '../../../../types/general';

export const getPlaceByPlaceId: (x: string) => Promise<{ bounds: BoundsType; geolocation: GeolocationType }> = async (
  placeId: string,
) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

  const res = await fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK' && data.results.length > 0) {
        const result = data.results[0];
        const { northeast: NE, southwest: SW } = result.geometry.bounds;
        return { bounds: { NE, SW }, geolocation: result.geometry.location };
      }
      return { bounds: { NE: telAvivBounds.NE, SW: telAvivBounds.SW }, geolocation: telAvivGeolocation };
    })
    .catch(error => ({ bounds: { NE: telAvivBounds.NE, SW: telAvivBounds.SW }, geolocation: telAvivGeolocation }));
  return res || { bounds: { NE: telAvivBounds.NE, SW: telAvivBounds.SW }, geolocation: telAvivGeolocation };
};
