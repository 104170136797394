import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/redux-slices/userSlice';
import { menuItems } from './const';
import { BurgerWrapper, LogoutButton } from './style';

type MenuClientProps = {
  setLogoutOpen: (isOpen: boolean) => void;
};

const MenuClient = ({ setLogoutOpen }: MenuClientProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);

  const navigate = useNavigate();
  const location = useLocation();
  const menuOptions = menuItems(user.userId || '1');

  const currentOption = menuOptions.findIndex(element => element.link === location.pathname);

  const changeMenu = (id: number) => {
    navigate(menuOptions[id].link);
  };

  return (
    <BurgerWrapper>
      <Menu
        zIndex={zIndex.menuClient}
        currentOption={currentOption}
        onChange={id => changeMenu(id)}
        options={menuOptions}
      >
        <LogoutButton onClick={() => setLogoutOpen(true)}>{t('logout.logoutButton')}</LogoutButton>
      </Menu>
    </BurgerWrapper>
  );
};

export default MenuClient;
