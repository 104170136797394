import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllCategories } from '../../page/Organisation/types';
import { RootState } from '../store';

export interface CategoriesState {
  categories: AllCategories[] | null;
}

const initialState: CategoriesState = {
  categories: null,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategories: (state, action: PayloadAction<{ categories: AllCategories[] }>) => {
      state.categories = action.payload.categories;
    },
    removeCategories: () => initialState,
  },
});

export const { addCategories, removeCategories } = categoriesSlice.actions;

export const selectCategories = (state: RootState) => state.categories;

export default categoriesSlice.reducer;
