import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getMinWidthMediaQuery, NotifyContainer, useMediaQuery } from '@beauty/beauty-market-ui';
import { ProtectedRoute } from '../components';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from '../components/Footer/Footer';
// import Header from '../components/Header/Header';
import Header from '../components/Header/HeaderNew';
import Main from '../components/Main/Main';
import ScrollUp from '../components/ScrollUp/ScrollUp';
import { AboutUs } from '../page/AboutUs/AboutUs';
import Booking from '../page/Booking/Booking';
import Appointments from '../page/Client/Appointments/Appointments';
import { Balance } from '../page/Client/Balance/Balance';
import { Docs } from '../page/Client/Docs/Docs';
// import Favourites from '../page/Client/Favourites/Favourites';
import Profile from '../page/Client/Profile/Profile';
import { ContactUs } from '../page/ContactUs/ContactUs';
import EmailVerification from '../page/EmailVerification/EmailVerification';
import FAQ from '../page/FAQ/FAQ';
import FAQQuestions from '../page/FAQ/FAQQuestions';
import { ForBusiness } from '../page/ForBusiness/ForBusiness';
import { AllOffers } from '../page/HomePage/components/AllOffers';
import Home from '../page/HomePage/Home';
import { JoinBusiness } from '../page/JoinBusiness/JoinBusiness';
import LoginSMS from '../page/Login/componets/LoginSMS';
import LogInto from '../page/Login/componets/LogInto';
import Welcome from '../page/Login/componets/Welcome';
import Login from '../page/Login/Login';
import { LoginIntro } from '../page/Login/LoginIntro';
import PhoneVerification from '../page/Login/Registration/PhoneVerification';
import Registration from '../page/Login/Registration/Registration';
import ResetPassword from '../page/Login/ResetPassword/ResetPassword';
import UpdatePassword from '../page/Login/ResetPassword/UpdatePassword';
import SentEmailNotification from '../page/Login/SentEmailNotification/SentEmailNotification';
import SignUp from '../page/Login/SignUp/SignUp';
import { MobileSearch } from '../page/MobileSearch';
import { Notifications } from '../page/Notifications/Notifications';
import Organisation from '../page/Organisation/Organisation';
import { PrivacyPolicy } from '../page/PrivacyPolicy/PrivacyPolicy';
import ServiceUnavailable from '../page/ServiceUnavailable/ServiceUnavailable';
import Support from '../page/Support/Support';
import { TermsOfService } from '../page/TermsOfService/TermsOfService';
import { TermsOfUse } from '../page/TermsOfUse/TermsOfUse';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { updatePaths } from '../store/redux-slices/historySlice';
import { resetState } from '../store/redux-slices/offersFilterSlice';
import { selectUser } from '../store/redux-slices/userSlice';
import { RouterUrl } from './routes';

const pagesWithoutFooter = [RouterUrl.AllOffers, RouterUrl.Root] as string[];

const Router = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(updatePaths(location.pathname));
    location.pathname === RouterUrl.Homepage && dispatch(resetState());
  }, [location]);

  const isMobile = !useMediaQuery(getMinWidthMediaQuery('md'));

  return (
    <ErrorBoundary fallback={<ServiceUnavailable />}>
      {/* <Header /> */}
      <Header />
      <Main>
        <ScrollUp>
          <Routes>
            <Route path={RouterUrl.Root} element={<Navigate to={RouterUrl.AllOffers} />} />
            <Route path={RouterUrl.Balance} element={<Balance />} />
            <Route path={RouterUrl.Homepage} element={<Home />} />
            <Route path={RouterUrl.AllOffers} element={<AllOffers />} />
            <Route path={RouterUrl.LoginIntro} element={<LoginIntro />} />
            <Route path={RouterUrl.Login} element={<Login />}>
              <Route index path={RouterUrl.Login} element={<LogInto />} />
              <Route path={RouterUrl.LoginSMS} element={<LoginSMS />} />
              <Route path={RouterUrl.PhoneVerification} element={<PhoneVerification />} />
              <Route path={RouterUrl.Registration} element={<Registration />} />
              <Route path={RouterUrl.SignUp} element={<SignUp />} />
              <Route path={RouterUrl.EmailVerification} element={<EmailVerification />} />
              <Route path={RouterUrl.ResetPassword} element={<ResetPassword />} />
              <Route path={RouterUrl.UpdatePassword} element={<UpdatePassword />} />
              <Route path={RouterUrl.SentEmailNotification} element={<SentEmailNotification />} />
            </Route>
            <Route path={RouterUrl.Welcome} element={<Welcome />} />
            <Route path={`${RouterUrl.Organisation}/:orgId`} element={<Organisation />} />
            <Route path={`${RouterUrl.Booking}/:orgId`} element={<Booking />} />
            <Route path={RouterUrl.FAQ} element={<FAQ />} />
            <Route path={RouterUrl.FAQQuestions} element={<FAQQuestions />} />
            <Route
              path={`${RouterUrl.ClientProfile}/:id`}
              element={<ProtectedRoute isAllowed={!!user.userId} component={<Profile />} />}
            />
            <Route
              path={`${RouterUrl.ClientAppointments}/:id`}
              element={<ProtectedRoute isAllowed={!!user.userId} component={<Appointments />} />}
            />
            <Route
              path={`${RouterUrl.ClientDocs}/:id`}
              element={<ProtectedRoute isAllowed={!!user.userId} component={<Docs />} />}
            />
            {/* <Route
              path={`${RouterUrl.ClientFavourites}/:id`}
              element={<ProtectedRoute isAllowed={!!user.userId} component={<Favourites />} />}
            /> */}
            <Route path={RouterUrl.Support} element={<Support />} />
            <Route path={RouterUrl.PrivacyPolicy} element={<PrivacyPolicy />} />
            <Route path={RouterUrl.AboutUs} element={<AboutUs />} />
            <Route path={RouterUrl.ContactUs} element={<ContactUs />} />
            <Route path={RouterUrl.ForBusiness} element={<ForBusiness />} />
            <Route
              path={RouterUrl.Search}
              element={isMobile ? <MobileSearch /> : <Navigate to={RouterUrl.Homepage} />}
            />
            <Route path={RouterUrl.TermsOfService} element={<TermsOfService />} />
            <Route path={RouterUrl.TermsOfUse} element={<TermsOfUse />} />
            <Route path={RouterUrl.BusinessJoin} element={<JoinBusiness />} />
            <Route path={RouterUrl.Notifications} element={<Notifications />} />
          </Routes>
        </ScrollUp>
      </Main>
      {/* {!pagesWithoutFooter.includes(location.pathname) ? <Footer /> : null} */}
      <NotifyContainer position={isMobile ? 'top-center' : 'top-right'} />
    </ErrorBoundary>
  );
};

export default Router;
