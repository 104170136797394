import { profileAPI } from '../../helpers/profile';
import { AccountResponse, PatchNotificationResponse } from '../../types/user';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const updateNotification = createAppAsyncThunk<
  PatchNotificationResponse,
  { id: string; type: string; enabled: boolean }
>('user/updateNotification', (data, thunkAPI) => {
  const { getState, rejectWithValue } = thunkAPI;
  const params = {
    query: {
      profileId: getState().user.user.userId,
      notificationId: data.id,
    },
    data: { enabled: data.enabled },
    rejectWithValue,
  };
  return profileAPI.updateNotification(params);
});

export const disconnectTelegram = createAppAsyncThunk<void, void>(
  'user/disconnectTelegram',
  (_, { getState, rejectWithValue }) =>
    profileAPI.disconnectTelegram({ id: getState().user.user.userId, rejectWithValue }),
);

export const fetchAccountByTelegramId = createAppAsyncThunk<AccountResponse, number>(
  'user/fetchAccountByTelegramId',
  (id, { rejectWithValue }) => profileAPI.fetchAccountByTelegramId({ id, rejectWithValue }),
);
