import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints } from '../api/endpoints/endpoints';
import { SubscriptionDataType, SubscriptionPlanType, SubscriptionPriceType } from '../types';

type ResponseData = {
  id: string;
  orgLimit: number;
  planType: SubscriptionPlanType;
  price: SubscriptionPriceType[];
  public: boolean;
};

const groupPricesByCurrency = (prices: SubscriptionPriceType[]) =>
  prices.reduce((acc, arg) => {
    if (!acc[arg.currency]) acc[arg.currency] = {};
    acc[arg.currency][arg.planType] = arg;
    return acc;
  }, {});

export const useGetSubscriptions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionDataType[]>([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: HeadOrganisationEndpoints.SubscriptionPlans,
      });

      if (error?.data) alert('Error with loading subscription plans');
      if (response) {
        const updatedData = response.data
          .filter(
            (subscription: ResponseData) => subscription.public && subscription.planType !== SubscriptionPlanType.FREE,
          )
          .map((subscription: ResponseData) => ({
            id: subscription.id,
            limit: subscription.orgLimit,
            planType: subscription.planType,
            prices: groupPricesByCurrency(subscription.price),
            public: subscription.public,
          }));

        setSubscriptionPlans(updatedData);
      }
      setIsLoading(false);
    };

    fetchSubscriptions();

    return setIsLoading(false);
  }, []);

  return {
    isLoading,
    subscriptionPlans,
  };
};
