import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Flex,
  ExtendedSearchInput,
  Link,
  Separator,
  Caption,
  theme,
  useClickOutside,
  Scroll,
} from '@beauty/beauty-market-ui';
import { FilterType } from '../../../../constants';
import { openLink } from '../../../../helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  searchState,
  setCitiesList,
  setFilterType,
  setIsSearchStart,
  setOrganisations,
  setSearchValues,
  updateServiceValue,
} from '../../../../store/redux-slices/searchSlice';
import { OrganisationCard } from './OrgnisationCard';
import { RegionSearch } from './RegionSearch';
import {
  DropListWrapper,
  DropWrapper,
  DropdownWrapper,
  ItemWrapper,
  OptionsItem,
  SearchDropdownWrapper,
} from './style';
import { ServiceSearchProps } from './types';

// TODO Refactor to enable eslint checks
export const ServiceSearch = ({
  list,
  apiLocationsList,
  location = '',
  inputValue = '',
  placeholder = 'Search for service',
  topHint = 'Matching requests',
  bottomHint = 'Matching oranisations',
  regionButtonCaption,
  seeMore = 'Show more',
  hide = 'Show less',
  onSelect,
  onGetLocation,
  onGetService, // search organisation from search input value
  onEnterService,
  onClickService,
  onClickLocation,
  onGetCurrentLocation,
  ...other
}: ServiceSearchProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [isLocationSearchOpen, setIsLocationSearchOpen] = useState<boolean>(false);
  const [isServiceListOpen, setIsServiceListOpen] = useState<boolean>(false);
  const [locationToSearch, setLocationToSearch] = useState('');
  const [isShownMore, setIsShownMore] = useState<boolean>(false);
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>();

  const dropdownRef = useRef<HTMLUListElement>(null);
  const regionSearchRef = useRef<HTMLUListElement>(null);
  const searchRef = useRef<HTMLElement>(null);

  const dispatch = useAppDispatch();

  const { organisations, isSearchStart, service } = useAppSelector(searchState);

  useClickOutside(searchRef, () => {
    setIsServiceListOpen(false);
  });

  useClickOutside(regionSearchRef, () => {
    setIsLocationSearchOpen(false);
  });

  const handleSetLocation = (loc: string) => {
    onGetLocation(loc);
    setIsLocationSearchOpen(false);
    /* if (inputValue) {
      onClickLocation && onClickLocation(loc);
    } else {
      inputElement && inputElement.focus();
    } */
    inputElement && inputElement.focus();
  };

  const selectOption = useCallback(
    (index: number) => {
      if (selectedOption !== index) {
        setSelectedOption(index);
        onSelect(list[index]);
      }
    },
    [selectedOption, list],
  );

  const handleInputLocation = (value: string) => {
    setLocationToSearch(value);
  };

  // click on dropdown item handler
  const handleServiceClick = (item: string, index: number) => {
    selectOption(index);
    dispatch(setFilterType(FilterType.SEARCH));
    dispatch(updateServiceValue(item));
    onGetService(item);
    setIsServiceListOpen(false);
    if (!location) {
      setIsLocationSearchOpen(true);
    } else onClickService && onClickService(item);
  };

  const handleInputChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateServiceValue((e?.target as HTMLInputElement)!.value));
    if ((e?.target as HTMLInputElement)!.value !== '') onGetService((e?.target as HTMLInputElement)!.value);
    else {
      dispatch(setFilterType(FilterType.CATEGORY));
      dispatch(setIsSearchStart(false));
    }
  };

  const handleInputFocus = () => {
    // dispatch(setIsSearchStart(false));
    setIsServiceListOpen(true);
    // dispatch(setSearchValues({ values: [] }));
  };

  const getRef = (refFrom: HTMLInputElement | null) => setInputElement(refFrom);

  const handleInputEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      if (!location) {
        setIsServiceListOpen(false);
        setIsLocationSearchOpen(true);
      } else onEnterService && onEnterService();
    }
  };

  const handleRightIconClick = () => {
    dispatch(setCitiesList([]));
    setIsLocationSearchOpen(true);
    setIsServiceListOpen(false);
  };

  const handleCrossClick = () => {
    dispatch(setIsSearchStart(false));
    dispatch(setFilterType(FilterType.CATEGORY));
    dispatch(updateServiceValue(''));
    dispatch(setSearchValues({ values: [] }));
    dispatch(setOrganisations([]));
  };

  const handleLinkClick = () => {
    setIsShownMore(!isShownMore);
    inputElement && inputElement.focus();
  };

  // TODO Need to be refactor. Question to @Surgeon83b
  const getScrollHeight = () => {
    if (list?.length < 6) {
      return `${32 * list.length}px`;
    }

    if (isShownMore && list) {
      if (32 * list.length > 360) {
        return '360px';
      }
      return `${32 * list.length}px`;
    }

    return '160px';
  };

  const scrollHeight = getScrollHeight();

  useEffect(() => {
    !isServiceListOpen && setIsShownMore(false);
  }, [isServiceListOpen]);

  return (
    <Flex ref={searchRef} flexDirection="column" width="100%" maxWidth="620px" position="relative" {...other}>
      <ExtendedSearchInput
        onGetRef={getRef}
        id="Search"
        design="white"
        placeholder={placeholder}
        onRightIconClick={handleRightIconClick}
        onCrossClick={handleCrossClick}
        onChange={debounce(handleInputChange, 1000)}
        onKeyUp={handleInputEnter}
        onFocus={handleInputFocus}
        onBlur={() => {
          setIsServiceListOpen(false);
          dispatch(setIsSearchStart(false));
        }}
        location={location}
        valueFrom={inputValue}
        isRightZoneActive={isLocationSearchOpen}
      />
      <DropWrapper isRight={isLocationSearchOpen}>
        {isLocationSearchOpen && (
          <DropListWrapper ref={regionSearchRef}>
            <RegionSearch
              placeholder={t('search.regionPlaceholder')}
              bottomHint={t('search.regionHint')}
              buttonCaption={regionButtonCaption}
              onSetLocation={handleSetLocation}
              isOpen={isLocationSearchOpen}
              onChangeOpen={(isOpen: boolean) => setIsLocationSearchOpen(isOpen)}
              onSetSearch={handleInputLocation}
              onGetCurrentLocation={onGetCurrentLocation}
              onGetService={onGetService}
            />
          </DropListWrapper>
        )}
        {isServiceListOpen && list?.length ? (
          <DropListWrapper width="100%">
            <SearchDropdownWrapper isExtended>
              <Flex flexDirection="column">
                <Caption lowline color={theme.colors.grey.dark} mb="16px">
                  {topHint}
                </Caption>

                <Scroll height={scrollHeight}>
                  <DropdownWrapper ref={dropdownRef} role="listbox" tabIndex={-1}>
                    {(!isShownMore ? list?.slice(0, 5) : list)?.map((item, index) => (
                      <ItemWrapper
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        role="option"
                        aria-selected={selectedOption === index}
                        tabIndex={0}
                        onClick={() => handleServiceClick(item, index)}
                      >
                        <OptionsItem>{item}</OptionsItem>
                      </ItemWrapper>
                    ))}
                  </DropdownWrapper>
                </Scroll>

                <Separator mb={list?.length < 6 ? '16px' : '8px'} />

                {list?.length > 5 && (
                  <Link size="s" mb={isShownMore ? '0px' : '32px'} onClick={handleLinkClick}>
                    {!isShownMore ? seeMore : hide}
                  </Link>
                )}
              </Flex>

              {!isShownMore && (
                <>
                  <Caption lowline color={theme.colors.grey.dark} mb="8px">
                    {bottomHint}
                  </Caption>

                  <Flex flexDirection="column">
                    {/* {organisationsList.map(organisation => (
                      <OrganisationCard key={organisation.name} {...organisation} />
                    ))} */}
                    {isSearchStart && organisations.length ? (
                      <OrganisationCard
                        key={organisations[0].name}
                        {...organisations[0]}
                        iconUrl={organisations[0].mainPhoto || ''}
                        address=""
                        onClick={() =>
                          organisations[0].id !== '0' && openLink(organisations[0].id, `?org=${organisations[0].name}`)
                        }
                      />
                    ) : null}
                  </Flex>
                </>
              )}
            </SearchDropdownWrapper>
          </DropListWrapper>
        ) : null}
      </DropWrapper>
    </Flex>
  );
};
