import { useTranslation } from 'react-i18next';
import { BodyLarge, Button, colors, getMinWidthMediaQuery, H1, useMediaQuery } from '@beauty/beauty-market-ui';
import CRM1x from '../../assets/CRM@1x.png';
import CRM2x from '../../assets/CRM@2x.png';
import { setImgUrl } from '../../helpers/utils';
import { useGetUrl } from '../../hooks/useGetUrl';
import { RouterUrl } from '../../routes/routes';
import { BottomContent, BottomWrapper, ImageWrapper } from './style';

export const BottomSection = () => {
  const { t } = useTranslation();
  const { business } = useGetUrl(RouterUrl.AccountSetting);

  const mediaQuery = getMinWidthMediaQuery('lg');
  const isLarge = useMediaQuery(mediaQuery);

  return (
    <BottomWrapper>
      <BottomContent>
        <H1 color={colors.white.standard} mb={isLarge ? '40px' : '24px'}>
          {t('business.bottomSection.tryBMForBusiness')}
        </H1>
        <BodyLarge color={colors.white.standard} mb={isLarge ? '24px' : '32px'}>
          {t('business.bottomSection.oneSolution')}
        </BodyLarge>
        <Button design="withShadow" width={isLarge ? 'max-content' : '100%'} onClick={() => window.open(business)}>
          {t('business.bottomSection.tryForFree')}
        </Button>
      </BottomContent>
      <ImageWrapper>
        <img src={setImgUrl([CRM1x, CRM2x])} alt="CRM" />
      </ImageWrapper>
    </BottomWrapper>
  );
};
