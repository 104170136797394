import * as Yup from 'yup';

export enum SendMessageFormFields {
  Email = 'email',
  FullName = 'fullName',
}

export type SendForm = {
  [SendMessageFormFields.Email]: string;
  [SendMessageFormFields.FullName]: string;
};

export const initialSendFormValues: SendForm = {
  [SendMessageFormFields.Email]: '',
  [SendMessageFormFields.FullName]: '',
};

export const sendFormValidationSchema = Yup.object({
  [SendMessageFormFields.Email]: Yup.string().required('Field cannot be empty').email('Email is not valid'),
  [SendMessageFormFields.FullName]: Yup.string().required('Field cannot be empty'),
});
