import { useTranslation } from 'react-i18next';
import { BodyLarge, Flex, CalendarIcon } from '@beauty/beauty-market-ui';
import { FormikDropdown } from '../../../../../../components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from '../../../../../../components/functional/formik/formik-input/FormikInput';
import { genders } from '../../../constants';
import { ProfileFormFields } from '../../../Profile.definitions';

type MainInformationFormProps = {
  setGenderIndex: (index: number) => void;
  genderIndex: number;
};

export const MainInformationForm = ({ genderIndex, setGenderIndex }: MainInformationFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyLarge large mb="16px">
        {t('profile.mainInformation')}
      </BodyLarge>
      <Flex flexDirection="column" mb="24px">
        <FormikInput
          design="white"
          id={ProfileFormFields.Name}
          name={ProfileFormFields.Name}
          placeholder={t('profile.name')}
          mb="16px"
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.Surname}
          name={ProfileFormFields.Surname}
          placeholder={t('profile.surname')}
          mb="16px"
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.DateOfBirth}
          name={ProfileFormFields.DateOfBirth}
          iconLeft={<CalendarIcon />}
          placeholder={t('profile.dateOfBirth')}
          mb="16px"
        />
        <FormikDropdown
          id={ProfileFormFields.Gender}
          name={ProfileFormFields.Gender}
          placeholder={t('profile.gender')}
          currentOption={genderIndex}
          options={genders.map(genderOption => ({
            item: t(`profile.genders.${genderOption}`),
            disabled: false,
          }))}
          isSeparator
          setIndex={setGenderIndex}
        />
      </Flex>
    </>
  );
};
