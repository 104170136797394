import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Promo, H3, Button, SlidersIcon, PinIcon } from '@beauty/beauty-market-ui';
import { ShareOrganisationModal } from '../../../components';
import { FavouritePopup } from '../../../components/FavouritePopup';
import { FilterType, OurChoiceOrganisations, getSelectedLanguage } from '../../../constants';
import { getFullOrganisationLink, getTranslation, sortByOurChoice } from '../../../helpers/utils';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { useToggle } from '../../../hooks/useToggle';
import { useAppSelector } from '../../../store/hooks';
import { selectHistory } from '../../../store/redux-slices/historySlice';
import { selectAppliedFiltersCount, selectOffersFilter } from '../../../store/redux-slices/offersFilterSlice';
import { searchState } from '../../../store/redux-slices/searchSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { AllOffersHeader, AllOffersButtons, OffersGrid, AllOffersContainer } from '../style';

interface OffersListProps {
  onFilterOpen: () => void;
  onChangeView: (value: string) => void;
}

export const OffersList = ({ onFilterOpen, onChangeView }: OffersListProps) => {
  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const [isFavouriteModalOpen, setIsFavouriteModalOpen] = useToggle(false);
  const [organisationId, setOrganisationId] = useState<string>('');

  const handleModalOpen = (id: string) => {
    setOrganisationId(id);
    setShareModalOpen();
  };

  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const { isForceFetch } = useAppSelector(selectUser);
  const { topCategory } = useAppSelector(selectHistory).history;
  const { sorting } = useAppSelector(selectOffersFilter);
  const { isLoading, organisations: categoryOrganisations } = useGetOrganisations(topCategory, isForceFetch);
  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);

  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;
  const selectedFiltersCount = useAppSelector(selectAppliedFiltersCount);

  const organizationsList = useMemo(
    () =>
      sortByOurChoice(organisations).map(organisation => (
        <Promo
          key={organisation.id}
          image={organisation.mainPhoto}
          headline={organisation.category.map(cat => getTranslation(cat, language)).join(', ')}
          title={/* organisation.orgName ? getTranslation(organisation.orgName, language) : */ organisation.name}
          rating={organisation.rating}
          reviews={t('organisation.reviews', { count: organisation.review })}
          badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
          photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
          href={
            organisation.id !== '0' &&
            getFullOrganisationLink(organisation.id, `?org=${organisation.name}&topCategory=${topCategory}`)
          }
          onShareClick={() => organisation.id !== '0' && handleModalOpen(organisation.id)}
          onFavouriteClick={setIsFavouriteModalOpen}
          isActive
          isBlueHover
          isBlank={false}
        />
      )),
    [organisations, topCategory, t, OurChoiceOrganisations],
  );

  const buttonProps = {
    design: 'quaternary',
    size: 'small',
    width: '100%',
  };

  return (
    <>
      <AllOffersContainer>
        <AllOffersHeader>
          <H3>{`${t('home.chooseFrom')} ${organizationsList?.length} ${t('home.offerings')}`}</H3>
          <AllOffersButtons>
            {/* <Button
              {...buttonProps}
              onClick={onFilterOpen}
              prefix={<SlidersIcon height="15" width="15" />}
              count={selectedFiltersCount || false}
            >
              {t('home.filters')}
            </Button> */}
            <Button {...buttonProps} onClick={() => onChangeView('map')} prefix={<PinIcon height="15" width="15" />}>
              {t('home.showMap')}
            </Button>
          </AllOffersButtons>
        </AllOffersHeader>
        <OffersGrid>{organizationsList}</OffersGrid>
      </AllOffersContainer>

      {isShareModalOpen && (
        <ShareOrganisationModal
          handelModalClose={setShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          organisationId={organisationId}
        />
      )}
      <FavouritePopup isOpen={isFavouriteModalOpen} handlePopupClose={setIsFavouriteModalOpen} />
    </>
  );
};
