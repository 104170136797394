import { useEffect, useState } from 'react';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { UserEndpoints } from '../api/endpoints/endpoints';

export const useGetDayCount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<Nullable<number>>(null);

  useEffect(() => {
    let isDayCountLoading = true;

    const fetchDayCount = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: UserEndpoints.DayCount,
      });

      if (isDayCountLoading) {
        setCount(response?.status === 200 ? response?.data : 0);
        setIsLoading(false);
      }
    };

    fetchDayCount();

    return () => {
      isDayCountLoading = false;
    };
  }, []);

  return {
    isLoading,
    count,
  };
};
