import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import {
  SpecialistCard,
  Avatar,
  Slider,
  H3,
  H5,
  Sidebar,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
  Separator,
  Review,
} from '@beauty/beauty-market-ui';
import { ShareOrganisationModal } from '../../../components';
import { FavouritePopup } from '../../../components/FavouritePopup';
import { useToggle } from '../../../hooks/useToggle';
import { ReviewType, TeamMateType } from '../../../types';
import { CardWrapper, ReviewsList } from '../style';
import { Portfolio } from './Portfolio';
import { SpecCard } from './SpecCard';
import { SpecialistInformation } from './SpecialistInformation';

interface TeamProps {
  team: TeamMateType[];
  orgId: string;
  reviews?: ReviewType[];
}

const Team = ({ team, orgId, reviews }: TeamProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFavouriteModalOpen, setIsFavouriteModalOpen] = useToggle();
  const [isShareModalOpen, setIsShareModalOpen] = useToggle();
  const [specialistIndex, setSpecialistIndex] = useState(-1);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  // TODO: uncomment after Sidebar info added
  const handleClickOpen = index => {
    setSpecialistIndex(index);
    // setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const slides = team.map((teammate, i) => (
    <CardWrapper key={teammate.id}>
      <SpecialistCard
        avatar={<Avatar size="l" url={teammate.avatarUrl} rating={teammate.rating} />}
        name={teammate.name}
        description={teammate.specialization}
        onClick={() => handleClickOpen(i)}
      />
    </CardWrapper>
  ));

  const getReviews: (reviewTypes: ReviewType[]) => Nullable<JSX.Element[]> = () =>
    reviews
      ? reviews.map((review, i) => (
          <Review
            avatarUrl={review.avatarUrl}
            username={review.username}
            date={review.date}
            rating={review.rating}
            text={t(`organisation.review.review${i + 1}`)}
            key={review.id}
            isSidebar
          />
        ))
      : null;

  const title = <H3>{t('organisation.about.meetTheTeam')}</H3>;
  const someSidebarProps = {
    label: t('organisation.specialists.meetTheTeam'),
    descriptor: t('organisation.org-1.name'),
  };
  const content = (
    <>
      <SpecCard
        name={team[specialistIndex]?.name}
        info={team[specialistIndex]?.description}
        imgUrl={team[specialistIndex]?.avatarUrl}
        onShare={setIsShareModalOpen}
        onLike={setIsFavouriteModalOpen}
      />
      <Separator mb="32px" />
      <SpecialistInformation languages={['ru', 'en', 'he']} />
      <Separator mt="24px" mb="32px" />
      <Portfolio />
      <Separator mt="24px" mb="32px" />
      <H5 mb="24px">{t('organisation.specialists.reviewsAbout')}</H5>
      <ReviewsList>{reviews?.length ? getReviews(reviews) : t('organisation.rating.noReviews')}</ReviewsList>
      <FavouritePopup isOpen={isFavouriteModalOpen} handlePopupClose={setIsFavouriteModalOpen} />
      {isShareModalOpen && (
        <ShareOrganisationModal
          handelModalClose={setIsShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          organisationId={orgId}
        />
      )}
    </>
  );

  return (
    <>
      <Slider title={title} slides={slides} marginTop={isLarge ? '160px' : '85px'} isPaginationEnabled />
      {isLarge ? (
        <Sidebar {...someSidebarProps} onClose={handleClose} isOpen={isOpen}>
          {content}
        </Sidebar>
      ) : (
        <BottomSheet
          {...someSidebarProps}
          snapPoints={[0.7]}
          initialSnap={0}
          isOpen={isOpen}
          onClose={handleClose}
          content={content}
          detent="content-height"
        />
      )}
    </>
  );
};

export default Team;
