import { AxiosError, AxiosResponse } from 'axios';
import { RouterUrl } from '../../../routes/routes';

export class AccessDenied {
  public resolve = (response: AxiosResponse) => response;

  public reject = async (error: AxiosError) => {
    if (error?.response?.status === 403) {
      window.location.href = RouterUrl.NotPermission;
    }
    if (error?.response?.status === 402) {
      window.location.href = RouterUrl.Subscription;
    }

    return Promise.reject(error);
  };
}
