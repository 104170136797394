import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterType, telAvivBounds, telAvivGeolocation } from '../../constants';
import { SearchItem } from '../../page/HomePage/components/Search/types';
import { BoundsType, GeolocationType } from '../../types/general';
import { OrganisationRenderType } from '../../types/organisation';
import { RootState } from '../store';

export interface SearchState {
  isSearchInMain: boolean;
  geolocation: GeolocationType;
  bounds: BoundsType;
  location: string;
  service: string;
  selectedOrganization: string;
  searchValues: string[];
  organisations: OrganisationRenderType[];
  filterType: FilterType | null;
  isCurrentLocation: boolean;
  citiesList: SearchItem[];
  isSearchStart: boolean;
  currentLocationBounds: BoundsType;
}

const initialState: SearchState = {
  isSearchInMain: true,
  geolocation: telAvivGeolocation,
  bounds: telAvivBounds,
  location: '',
  service: '',
  selectedOrganization: '',
  searchValues: [],
  organisations: [],
  filterType: null,
  isCurrentLocation: true,
  citiesList: [],
  isSearchStart: false,
  currentLocationBounds: telAvivBounds,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchPosition: (state, action: PayloadAction<{ isSearchInMain: boolean }>) => {
      state.isSearchInMain = action.payload.isSearchInMain;
    },
    updateServiceValue: (state, action: PayloadAction<string>) => {
      state.service = action.payload;
    },
    updateLocationValue: (state, action: PayloadAction<{ location: string }>) => {
      state.location = action.payload.location;
    },
    setGeolocation: (state, action: PayloadAction<GeolocationType>) => {
      state.geolocation = { lat: action.payload?.lat, lng: action.payload?.lng };
    },
    setBounds: (state, action: PayloadAction<{ bounds: BoundsType; geolocation: GeolocationType }>) => {
      state.bounds = action.payload.bounds;
      state.geolocation = action.payload.geolocation;
    },
    setCurrentLocationBounds: (state, action: PayloadAction<{ bounds: BoundsType }>) => {
      state.currentLocationBounds = action.payload.bounds;
    },
    setSearchValues: (state, action: PayloadAction<{ values: string[] }>) => {
      state.searchValues = action.payload.values;
    },
    setOrganisations: (state, action: PayloadAction<OrganisationRenderType[]>) => {
      state.organisations = [...action.payload];
    },
    setFilterType: (state, action: PayloadAction<FilterType | null>) => {
      state.filterType = action.payload;
    },
    setIsCurrentLocation: (state, action: PayloadAction<boolean>) => {
      state.isCurrentLocation = action.payload;
    },
    setCitiesList: (state, action: PayloadAction<SearchItem[]>) => {
      state.citiesList = action.payload;
    },
    setIsSearchStart: (state, action: PayloadAction<boolean>) => {
      state.isSearchStart = action.payload;
    },
    resetSearch: () => initialState,
  },
});

export const {
  updateSearchPosition,
  updateServiceValue,
  updateLocationValue,
  setGeolocation,
  setBounds,
  setSearchValues,
  setOrganisations,
  setFilterType,
  setIsCurrentLocation,
  setCitiesList,
  setIsSearchStart,
  setCurrentLocationBounds,
  resetSearch,
} = searchSlice.actions;

export const searchState = (state: RootState) => state.search;

export default searchSlice.reducer;
