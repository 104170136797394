import { Button, Sidebar, H3 } from '@beauty/beauty-market-ui';

type DocSidebarProps = {
  checkedId: number | undefined;
  isSidebarOpen: boolean;
  onClick: (isOpenState: boolean) => void;
};

const DocsSidebar = ({ checkedId, isSidebarOpen, onClick }: DocSidebarProps) => (
  <Sidebar
    label="Current document"
    descriptor={`Doc #${checkedId} is opened`}
    open={isSidebarOpen}
    handleClose={() => onClick(false)}
    FooterBody={
      <>
        <Button
          size="large"
          width="100%"
          design="secondary"
          onClick={() => {
            console.log('Cancelled');
            onClick(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          width="100%"
          onClick={() => {
            console.log('Accepted');
            onClick(false);
          }}
        >
          Continue
        </Button>
      </>
    }
  >
    <H3>Under construction. Come again later!</H3>
  </Sidebar>
);

export { DocsSidebar };
