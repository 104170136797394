import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { H4, BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper } from '../../../style';
import { transComponents } from '../constants';

export const GeographicLimitations = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <H4 mb="24px">{t('termsOfUse.geographicLimitations.title')}</H4>
        <BodySmall mb="16px">
          <Trans components={transComponents}>{t('termsOfUse.geographicLimitations.article1')}</Trans>
        </BodySmall>
        <BodySmall mb="16px">{t('termsOfUse.geographicLimitations.article2')}</BodySmall>
      </PrivacyItemWrapper>
    );
  }),
);
