import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const Container = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme, scrollColor }) => scrollColor || theme.colors.grey.standard};
    border-radius: 15px;
  }

  @media screen and (max-width: 992px) {
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`;
