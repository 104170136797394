import styled from 'styled-components';
import { Flex, colors, calendarColors, StarFilledIcon, mediaQueries } from '@beauty/beauty-market-ui';

export const StarsWrapper = styled(Flex)<{ hoveredStars: number }>`
  gap: 16px;
  margin-bottom: 16px;
  * {
    cursor: pointer;
  }

  svg.hovered path {
    fill: ${calendarColors.yellow.light};
  }
`;

export const StarFillIconStyled = styled(StarFilledIcon)<{ fillColor: string }>`
  width: 40px;
  height: 40px;
  ${mediaQueries.md} {
    width: 48px;
    height: 48px;
  }
  path {
    fill: ${({ fillColor }) => fillColor};
  }
`;

export const BadgeWrapper = styled(Flex)<{ isActive: boolean }>`
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 124px;
  height: 140px;
  border-radius: 10px;
  border: 1px solid ${({ isActive }) => (isActive ? colors.blue.standard : colors.grey.standard)};
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const FormikWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  form > div > div > div {
    justify-content: end;
  }
`;

export const ColumnFlex = styled(Flex)`
  flex-direction: column;
`;
