import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { H3, Slider } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../constants';
import { getFullOrganisationLink, getTranslatedString } from '../../../helpers/utils';
import { TextWithTranslations } from '../../../types';

type HotOfferType = {
  id: string;
  name: string;
  rating: string | null;
  review: number;
  photoCount: number;
  mainPhoto?: string | null;
  category?: TextWithTranslations[] | TextWithTranslations;
};

interface HotOffersProps {
  offers: HotOfferType[];
}

const HotOffers = ({ offers }: HotOffersProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getSelectedLanguage();

  const preparedQuery = (query: string) =>
    location.search.replace(/(\[?|&])org=[^&]*/, `$1org=${encodeURIComponent(query)}`);

  const slides = offers.map(offer => (
    <OrganisationCard
      id={offer.id}
      key={offer.id}
      title={offer.name}
      rating={offer.rating}
      image={offer.mainPhoto}
      headline={getTranslatedString(language, offer.category)}
      href={getFullOrganisationLink(offer.id, preparedQuery(offer.name))}
      reviews={t('organisation.reviews', { count: offer.review })}
      photos={t('organisation.photos', { count: offer.photoCount ? offer.photoCount - 1 : 0 })}
      badge={OurChoiceOrganisations.includes(offer.id) ? t('organisation.ourChoice') : ''}
      isBlank={false}
      isBlueHover
    />
  ));

  const title = <H3>{t('organisation.otherBranches')}</H3>;

  return <Slider title={title} slides={slides} width="100%" />;
};

export default HotOffers;
