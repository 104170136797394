import { Wrapper } from '../style';
import { AuthorisationData } from './molecules/AuthorisationData';
import { ChangePassword } from './molecules/ChangePassword';
import { DeletingAccount } from './molecules/DeletingAccount';
import { InviteFriends } from './molecules/InviteFriends';
import { LogoutAccount } from './molecules/LogoutAccount';
import { Notifications } from './molecules/Notifications';
// import { PaymentMethods } from './molecules/PaymentMethods';
// import { SocialNetworks } from './molecules/SocialNetworks';
import { RegionSettings } from './molecules/RegionSettings';

type AdditionalInfoProps = {
  onDeleteAccountClick: () => void;
  onLogoutClick: () => void;
  onChangeEmailClick: () => void;
  onChangePhoneClick: () => void;
  onAddCardClick?: () => void;
};

export const AdditionalInfo = ({
  onDeleteAccountClick,
  onLogoutClick,
  onChangeEmailClick,
  onChangePhoneClick,
}: AdditionalInfoProps) => (
  <Wrapper>
    {/* <PaymentMethods payments={profile.payments} onAddCardClick={onAddCardClick} /> */}
    <AuthorisationData onChangeEmailClick={onChangeEmailClick} onChangePhoneClick={onChangePhoneClick} />
    <Notifications />
    {/* <SocialNetworks items={profile.contact.socialNetwork} /> */}
    <ChangePassword />
    <RegionSettings />
    {/* <InviteFriends profile={profile} /> */}
    <LogoutAccount onLogoutClick={onLogoutClick} />
    <DeletingAccount onDeleteAccountClick={onDeleteAccountClick} />
  </Wrapper>
);
