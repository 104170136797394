import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { BodyLarge, BodySmall, colors, getMinWidthMediaQuery, H2, Icon, useMediaQuery } from '@beauty/beauty-market-ui';
import { getTableData } from '../../helpers';
import { StyledCheckedIcon } from '../../style';
import { AdditionalColumn, AdditionalColumns, Head, MainColumn, Row, TableWrapper, TariffTableWrapper } from './style';

export const TariffPlansTable = ({ bestPlan }) => {
  const { t } = useTranslation();
  const tableData = getTableData(t);

  const small = getMinWidthMediaQuery('sm');
  const isSmall = useMediaQuery(small);

  return (
    <TariffTableWrapper>
      <H2>{t('business.tariffPlans.table.compareFeatures')}</H2>
      <TableWrapper>
        <MainColumn>
          {tableData.categories.map((cat, index, arr) =>
            index === 0 ? (
              <Head key={cat} lowline large enableSeparator textAlign="start">
                {cat}
              </Head>
            ) : (
              <Row key={cat} enableSeparator={index !== arr.length - 1} justifyContent="flex-start">
                {cat}
              </Row>
            ),
          )}
        </MainColumn>
        <AdditionalColumns>
          {tableData.columns.map((col, colIndex) => (
            <AdditionalColumn key={colIndex}>
              {col.map((row, rowIndex, arr) => {
                const icon = row ? <StyledCheckedIcon color={colors.blue.standard} /> : <Icon />;
                const text = isSmall ? <BodySmall lowline>{row}</BodySmall> : <BodyLarge large>{row}</BodyLarge>;
                return rowIndex === 0 ? (
                  <Head
                    key={rowIndex}
                    lowline
                    large
                    enableSeparator={isSmall}
                    textAlign={isSmall ? 'start' : 'center'}
                    color={row === bestPlan ? colors.blue.standard : colors.black.standard}
                  >
                    {capitalize(`${row}`)}
                  </Head>
                ) : (
                  <Row
                    key={rowIndex}
                    justifyContent={isSmall ? 'start' : 'center'}
                    enableSeparator={rowIndex !== arr.length - 1 && isSmall}
                  >
                    {typeof row === 'string' ? text : icon}
                  </Row>
                );
              })}
            </AdditionalColumn>
          ))}
        </AdditionalColumns>
      </TableWrapper>
    </TariffTableWrapper>
  );
};
