import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, H7, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import {
  CHANGES_ARTICLES_COUNT,
  CHANGES_SECTIONS_BODY_PARTS_COUNT,
  CHANGES_SECTIONS_COUNT,
  CHANGES_SUBSECTIONS_COUNT,
  CHANGES_SUBSECTIONS_PARTS_COUNT,
  transComponents,
} from '../constants';
import { Section } from './Section';

// TODO Refactor to enable eslint checks
export const Changes = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.changes.title')}</PrivacyItemTitleWrapper>
        {Array(CHANGES_SECTIONS_COUNT)
          .fill(0)
          .map((__, index) => (
            <Section
              // eslint-disable-next-line react/no-array-index-key
              key={index + 1}
              title={t(`termsOfService.changes.section${index + 1}.title`)}
              body={Array(CHANGES_SECTIONS_BODY_PARTS_COUNT[index])
                .fill(0)
                .map((___, i) => t(`termsOfService.changes.section${index + 1}.body.part${i + 1}`))}
              articles={
                CHANGES_ARTICLES_COUNT[index]
                  ? Array(CHANGES_ARTICLES_COUNT[index])
                      .fill(0)
                      .map((_x, j) => t(`termsOfService.changes.section${index + 1}.article${j + 1}`))
                  : undefined
              }
              subSection={
                CHANGES_SUBSECTIONS_COUNT[index]
                  ? Array(CHANGES_SUBSECTIONS_COUNT[index])
                      .fill(0)
                      .map((____, subIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Flex key={subIndex + 1} flexDirection="column" mb="24px">
                          <H7 mb="16px">
                            <Trans components={transComponents}>
                              {t(
                                `termsOfService.changes.section${index + 1}.subsections.subsection${
                                  subIndex + 1
                                }.title`,
                              )}
                            </Trans>
                          </H7>
                          {CHANGES_SUBSECTIONS_PARTS_COUNT[index][subIndex]
                            ? Array(CHANGES_SUBSECTIONS_PARTS_COUNT[index][subIndex])
                                .fill(0)
                                .map((_____, j) => (
                                  <BodySmall
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={j}
                                    mb={j === CHANGES_SUBSECTIONS_PARTS_COUNT[index][subIndex] - 1 ? '0px' : '16px'}
                                  >
                                    <Trans components={transComponents}>
                                      {t(
                                        `termsOfService.changes.section${index + 1}.subsections.subsection${
                                          subIndex + 1
                                        }.body.part${j + 1}`,
                                      )}
                                    </Trans>
                                  </BodySmall>
                                ))
                            : null}
                          {index === 1 && subIndex === 1 ? (
                            <BodyLarge large mt="24px">
                              {t(
                                `termsOfService.changes.section${index + 1}.subsections.subsection${
                                  subIndex + 1
                                }.body.note`,
                              )}
                            </BodyLarge>
                          ) : null}
                        </Flex>
                      ))
                  : undefined
              }
            />
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
