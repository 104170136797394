import { EnumType } from 'typescript';
import { apiRequest } from '../api/api';
import { SearchEndpoints } from '../api/endpoints/endpoints';
import { SearchItem } from '../page/HomePage/components/Search/types';
import { BoundsType } from '../types/general';

export const fetchFoundOrganisations = async (
  isFiltered: boolean,
  toSearch: string,
  bounds: BoundsType,
  minPrice: number,
  maxPrice: number,
  minDistance: number,
  maxDistance: number,
  availableFor: string,
  spokenLanguages: string,
  openShabbat: boolean,
) => {
  const [error, response] = await apiRequest.get({
    endpoint: SearchEndpoints.Root,
    endpointParams: `${toSearch}&NE_lat=${bounds.NE.lat}&NE_lng=${bounds.NE.lng}&SW_lat=${bounds.SW.lat}&SW_lng=${
      bounds.SW.lng
    }${
      isFiltered
        ? `&minDistance=${minDistance}&maxDistance=${maxDistance}${
            false && spokenLanguages.length ? `&spokenLanguages=${spokenLanguages}` : ''
          }${availableFor.length ? `&availableFor=${availableFor}` : ''}${openShabbat ? `&schedule=SATURDAY` : ''}`
        : ''
    }`,
  });

  return response ? response.data : error?.data;
};

export const getPlaces = async (query: string) => {
  const request = {
    input: query,
    types: ['geocode'],
    // componentRestrictions: { country: 'il' },
  };
  const autocompleteService = new google.maps.places.AutocompleteService();
  const result: SearchItem[] = [];
  await autocompleteService.getPlacePredictions(request, (predictions, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      predictions?.forEach(prediction => {
        result.push({
          text: prediction.terms
            .slice(0, 2)
            .map(item => item.value)
            .join(', '),
          placeId: prediction.place_id,
        });
      });
    }
  });
  return result;
};
