import { useEffect, useState } from 'react';

export const useScrollY = () => {
  const [posY, setPosY] = useState<number>(0);

  const handleScroll = () => {
    setPosY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return posY;
};
