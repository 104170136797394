import { Avatar, Flex, BodySmall, theme } from '@beauty/beauty-market-ui';
import { StyledFlex } from './style';

type OrganisationCardProps = {
  id: string;
  name: string;
  iconUrl: string;
  address: string;
  onClick: () => void;
};

export const OrganisationCard = ({ id, name, iconUrl, address, onClick }: OrganisationCardProps) => (
  <StyledFlex onClick={onClick}>
    <Flex alignItems="center" justifyContent="center">
      <Avatar size="xs" url={iconUrl} />
    </Flex>

    <Flex flexDirection="column" justifyContent="end">
      <BodySmall mb="2px" height="20px">
        {name}
      </BodySmall>
      <BodySmall height="20px" color={theme.colors.grey.standard}>
        {address}
      </BodySmall>
    </Flex>
  </StyledFlex>
);
