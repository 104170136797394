import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BodySmall, Flex, BodyLarge, SupportIcon, Link } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';
import { FAQHelperWrapper } from '../style';

const FAQHelper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <FAQHelperWrapper>
      <Flex flexDirection="column" justifyContent="space-between">
        <BodyLarge large mb="8px" fontWeight="600">
          {t('faq.helper.title')}
        </BodyLarge>
        <BodySmall lowline>{t('faq.helper.description')}</BodySmall>
      </Flex>
      <Link
        to={() => navigate(RouterUrl.Support)}
        con={<SupportIcon style={{ fill: 'none' }} />}
        href={RouterUrl.Support}
        size="s"
        fill="none"
      >
        {t('faq.helper.goToSupport')}
      </Link>
    </FAQHelperWrapper>
  );
};

export default FAQHelper;
