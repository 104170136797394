import { Form } from 'formik';
import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';

export const StyledFlex = styled(Flex)<{ gap: string }>`
  ${({ gap }) => `gap: ${gap};`}
`;

export const StyledFormikInput = styled(FormikInput)`
  svg {
    width: 20px !important;
    height: 12px !important;
    margin-bottom: 3px;
    fill: none !important;
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 0;
  margin-top: 40px;
  padding: 24px;
  width: 100%;
  max-width: 620px;
  box-sizing: border-box;
  ${mediaQueries.md} {
    margin-left: 40px;
    padding: 0;
  }
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  form {
    width: 100%;
  }
`;

export const PersonalInfoWrapper = styled(Flex)`
  height: fit-content;
  @media (max-width: 992px) {
    padding: 24px;
    flex-direction: column;
    & > div:first-child > div:nth-child(2) {
      display: none;
    }
  }
`;

export const BackWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
