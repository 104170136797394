import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Nullable } from 'tsdef';
import {
  CheckCircleIcon,
  GearIcon,
  H2,
  TagButton,
  getMinWidthMediaQuery,
  useMediaQuery,
  Flex,
} from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import { NoData } from '../../components/NoData';
import { getSelectedLanguage } from '../../constants';
import { changeNotificationStatus, getNotificationCard, markAllAsRead } from '../../helpers/notifications';
import { useGetNotifications } from '../../hooks/useGetNotifications';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectUser,
  setNotifications,
  updateNotificationStatus,
  setActiveNotificationId,
} from '../../store/redux-slices/userSlice';
import { NotificationStatus } from '../../types/notifications';
import { CardsWrapper, MenuWrapper, StyledButton, StyledLink, TitleWrapper, Wrapper } from './style';

const shownNots = {
  0: 'new',
  1: 'archive',
};

export const Notifications = () => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [isFetch, setFetch] = useState(false);

  const { notifications, user } = useAppSelector(selectUser);
  const { nots, isLoading } = useGetNotifications(user.userId, isFetch);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const setRead = async (index: number) => {
    if (!notifications[shownNots[activeTab]][index].isRead) {
      const response = await changeNotificationStatus(notifications[shownNots[activeTab]][index].id);
      response?.status === NotificationStatus.VIEWED &&
        dispatch(updateNotificationStatus({ isNew: !activeTab, index }));
    }
  };

  const handleMarkAll = async () => {
    const response = await markAllAsRead(user.userId);
    response && setFetch(true);
  };

  useEffect(() => {
    if (!isLoading) {
      nots && dispatch(setNotifications(nots));
      setFetch(false);
    }
  }, [isLoading]);

  const setRatingOpen = (id: Nullable<string>) => {
    dispatch(setActiveNotificationId(id));
  };

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <H2>{t('notifications.title')}</H2>
          <StyledButton
            size="small"
            design="tertiary"
            suffix={<GearIcon width="20px" height="20px" />}
            onClick={() => navigate(`${RouterUrl.ClientProfile}/${user.userId}`)}
          >
            {t('notifications.settings')}
          </StyledButton>
        </TitleWrapper>
        <MenuWrapper>
          {isMobile && (
            <Flex width="100%" justifyContent="end">
              <StyledLink
                design={isMobile ? 'blue' : 'gray'}
                size="xs"
                icon={<CheckCircleIcon width="16px" height="16px" />}
                onClick={handleMarkAll}
                mb="25px"
              >
                {t('notifications.markAll')}
              </StyledLink>
            </Flex>
          )}
          <Flex width="100%" justifyContent={isMobile ? 'start' : 'space-between'} alignItems="center">
            <Flex gap="12px">
              <TagButton
                text={t('notifications.new')}
                number={notifications.new.length || undefined}
                design={activeTab ? 'default' : 'active'}
                onClick={() => setActiveTab(0)}
              />
              {!!notifications.archive.length && (
                <TagButton
                  text={t('notifications.archive')}
                  number={notifications.archive.length}
                  design={activeTab ? 'active' : 'default'}
                  onClick={() => setActiveTab(1)}
                />
              )}
            </Flex>
            {!isMobile && (
              <Flex>
                <StyledLink
                  design="gray"
                  size="xs"
                  icon={<CheckCircleIcon width="16px" height="16px" />}
                  onClick={handleMarkAll}
                >
                  {t('notifications.markAll')}
                </StyledLink>
              </Flex>
            )}
          </Flex>
        </MenuWrapper>
        {notifications && !!notifications[shownNots[activeTab]]?.length ? (
          <CardsWrapper>
            {notifications[shownNots[activeTab]].map((item, index) =>
              getNotificationCard(t, navigate, user.userId, item, index, setRead, setRatingOpen, language, dispatch),
            )}
          </CardsWrapper>
        ) : (
          <NoData title={t('notifications.noDataTitle')} info={t('notifications.noDataInfo')} />
        )}
      </Wrapper>
    </Container>
  );
};
