import { stringForSendSupportEmail, stringToOpenSite } from '../../constants';
import { setLink } from '../../helpers/utils';
import { RouterUrl } from '../../routes/routes';
import { StyledLi, StyledUl } from '../../style';

// TODO Review and refactor
export const termsOfUseTitles = [
  'introduction',
  'feedback',
  'privacy',
  'siteAccess',
  'ageRequirements',
  'allowedActivities',
  'yourCommitments',
  'notAllowed',
  'userContent',
  'intellectualProperty',
  'content',
  'thirdParty',
  'disclaimer',
  'jurisdiction',
  'geographicLimitations',
];

export const termsOfServiceTitlesSectionsCount = [4, 4, 0, 0, 0, 0, 0, 0, 0];

export const SUMMARY_ARTICLES_COUNT = 4;

export const YOUR_COMMITMENTS_SECTIONS_COUNT = 2;
export const YOUR_COMMITMENTS_SECTIONS_BODY_PARTS_COUNT = [0, 2];
export const YOUR_COMMITMENTS_SUBSECTIONS_COUNT = [2, 0];
export const YOUR_COMMITMENTS_SECTION_1_SUBSECTIONS_COUNT = 2;

export const AGE_REQUIREMENTS_SECTIONS_COUNT = 1;
export const AGE_REQUIREMENTS_SECTIONS_BODY_PARTS_COUNT = [7];

export const NOT_ALLOWED_ARTICLES_COUNT = 4;

export const USER_CONTENT_ARTICLES_COUNT = 1;
export const USER_CONTENT_SUBSECTION_1_PARTS_COUNT = 3;

export const INTELLECTUAL_PROPERTY_ARTICLES_COUNT = 5;

export const HOW_TO_WORK_ARTICLES_COUNT = 3;
export const HOW_TO_WORK_SECTIONS_COUNT = 2;
export const HOW_TO_WORK_SECTIONS_BODY_PARTS_COUNT = [1, 2];

export const PAYING_SECTIONS_COUNT = 4;
export const PAYING_SECTIONS_BODY_PARTS_COUNT = [1, 3, 1, 3];

export const CHANGES_SECTIONS_COUNT = 9;
export const CHANGES_SECTIONS_BODY_PARTS_COUNT = [1, 0, 0, 0, 1, 1, 5, 4, 2];
export const CHANGES_SUBSECTIONS_COUNT = [0, 2, 1, 0, 0, 0];
export const CHANGES_ARTICLES_COUNT = [0, 1, 0, 3, 0, 0];
export const CHANGES_SUBSECTIONS_PARTS_COUNT = [0, [1, 3], [2], 0, 0, 0];

export const TERMINATION_ARTICLES_COUNT = 2;

export const GENERAL_SECTIONS_COUNT = 9;
export const GENERAL_SECTIONS_BODY_PARTS_COUNT = [1, 1, 1, 1, 1, 1, 1, 4, 3];

export const transComponents = {
  ul: <StyledUl />,
  li: <StyledLi />,
  aTerms: setLink(RouterUrl.TermsOfService),
  aSite: setLink(stringToOpenSite),
  aWidget: setLink(''),
  aApp: <div />,
  aEmail: setLink(stringForSendSupportEmail),
  aPrivacy: setLink(RouterUrl.PrivacyPolicy),
  aProfile: setLink(`${RouterUrl.ClientProfile}/:id`),
};
