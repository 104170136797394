import { TFunction } from 'react-i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';
import { UserActions } from '../../types/user';
import { getUserNotifyContent } from './user';

type NotifyActions = UserActions;

export const getNotifyContent = (
  status: NotifyStatus,
  action: NotifyActions,
  t: TFunction<'translation', undefined>,
  message?: string,
) => {
  switch (action) {
    case UserActions.ChangeNotificationLanguage:
    case UserActions.ChangePassword:
      return getUserNotifyContent(status, action, t, message);
    default:
      return '';
  }
};
