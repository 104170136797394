import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../api/api';
import { ServicesEndpoints } from '../api/endpoints/endpoints';
import { getSelectedLanguage } from '../constants';
import { getTranslation } from '../helpers/utils';
import { orderedTopCategories, ServiceIcons } from '../page/HomePage/constants';
import { TopCategory, TopCategoryWithIcon } from '../types/general';

export const useGetTopCategories = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [topCategoriesWithIcons, setTopCategoriesWithIcons] = useState<TopCategoryWithIcon[]>([]);
  const language = getSelectedLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    let isTopCategoriesLoading = true;

    const fetchTopCategories = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: ServicesEndpoints.TopCategories,
      });

      if (isTopCategoriesLoading) {
        if (response) {
          const topCategoriesFromApi: TopCategory[] = response.data.map(category => ({
            id: category.id,
            title: category.title,
          }));
          setTopCategoriesWithIcons([
            { icon: ServiceIcons.All, name: t('home.services.all'), id: 'All' },
            ...orderedTopCategories.map(category => {
              const currentTopCategory = topCategoriesFromApi.find(
                item => item.title?.text === category,
              ) as TopCategory;
              return {
                icon: currentTopCategory.title!.text ? ServiceIcons[currentTopCategory.title!.text] : null,
                name: getTranslation(currentTopCategory?.title, language),
                id: currentTopCategory.title!.text,
              };
            }),
          ]);

          setIsLoading(false);
        } else alert('error while loading categories');
      }
    };

    fetchTopCategories();

    return () => {
      isTopCategoriesLoading = false;
    };
  }, [orderedTopCategories, language, t]);

  return {
    isLoading,
    topCategoriesWithIcons,
  };
};
