import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H2, DropdownMenu, Flex, Option } from '@beauty/beauty-market-ui';
import { Container } from '../../../components';
import { DocsCategories } from './components/DocsCategories';
import { DocsSidebar } from './components/DocsSidebar';
import { DocumentsList } from './components/DocumentsList';
import { organizations } from './constants';

const Docs = () => {
  const { t, i18n } = useTranslation();
  const [activeTag, setActiveTag] = useState<number>(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [checkedId, setCheckedId] = useState<number | undefined>(undefined);

  const organizationOptions = organizations.map(organization =>
    organization.translationKey ? { ...organization, text: t(organization.translationKey) } : organization,
  );

  const [options, setOptions] = useState<Option[]>(organizationOptions);

  useEffect(() => {
    setOptions(options.map(option => (option.translationKey ? { ...option, text: t(option.translationKey) } : option)));
  }, [i18n.language]);

  const handleClick = (id: number) => {
    setIsSidebarOpen(true);
    setCheckedId(id);
  };

  return (
    <>
      <Container flexDirection="column" mt="70px">
        <H2 mb="40px">{t('docs.documents')}</H2>
        <Flex flexDirection="row-reverse" justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
          <Flex flexDirection="row" justifyContent="flex-end" alignItems="center" minWidth="180px" height="32px">
            <DropdownMenu
              onChange={setOptions}
              options={options}
              selectedOption={options.find(option => option.selected)}
            />
          </Flex>
          <DocsCategories onSetActive={setActiveTag} />
        </Flex>
        <DocumentsList activeTag={activeTag} onButtonClick={id => handleClick(id)} />
      </Container>
      <DocsSidebar
        checkedId={checkedId}
        isSidebarOpen={isSidebarOpen}
        onClick={(isOpenState: boolean) => setIsSidebarOpen(isOpenState)}
      />
    </>
  );
};

export { Docs };
