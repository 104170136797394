import BusinessFAQ from '../../assets/businessFAQ.png';
import ClientFAQ from '../../assets/clientFAQ.png';
import { RouterUrl } from '../../routes/routes';
import { FaqQuestionsCategories, FAQQuestionsVariants } from './types';

export const clientFaqCategories = [
  FaqQuestionsCategories.About,
  FaqQuestionsCategories.Account,
  FaqQuestionsCategories.Profile,
  FaqQuestionsCategories.Appointments,
  FaqQuestionsCategories.Reviews,
];

export const businessFaqCategories = [
  FaqQuestionsCategories.GetStarted,
  FaqQuestionsCategories.OrganisationAccount,
  FaqQuestionsCategories.Calendar,
];

export const questionsInCategories = {
  client: [2, 2, 5, 4, 1],
  business: [4, 10, 7],
};

export const articlesInQuestions = {
  client: [[3, 3], [2, 3], [1, 2, 1, 1, 3], [1, 2, 1, 1], [3]],
  business: [
    [1, 2, 2, 3],
    [4, 2, 1, 3, 4, 3, 1, 2, 2, 3],
    [2, 1, 2, 2, 1, 1, 1],
  ],
};

export const faqCards = [
  {
    image: ClientFAQ,
    title: 'titleForCustomers',
    description: 'descriptionForCustomers',
    link: `${RouterUrl.FAQQuestions}?type=${FAQQuestionsVariants.Client}`,
  },
  {
    image: BusinessFAQ,
    title: 'titleForBusinesses',
    description: 'descriptionForBusinesses',
    link: `${RouterUrl.FAQQuestions}?type=${FAQQuestionsVariants.Business}`,
  },
];
