import { useState } from 'react';
import { Nullable } from 'tsdef';
import { Flex, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { REQUEST_STATUS } from '../../../api/constants';
import { Container, Crumbs } from '../../../components';
import { ChangeEmailPopup } from '../../../components/ChangeEmailPopup';
import { useGetProfile } from '../../../hooks/useGetProfile';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { ActionType } from '../../../types/user';
import { AdditionalInfo } from './components/AdditionalInfo';
import { ProfileAlerts } from './components/molecules/ProfileAlerts';
import { PersonalInfo } from './components/PersonalInfo';
import { ProfileSidebar } from './components/Sidebars/ProfileSidebar';

const Profile = () => {
  const [action, setAction] = useState<ActionType>(null);
  const [status, setStatus] = useState<string>(REQUEST_STATUS.NONE);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [newEmail, setNewEmail] = useState('');
  const [newRegion, setNewRegion] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [photo, setPhoto] = useState<Nullable<File> | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);

  const handleClick = (actionType: ActionType) => {
    setAction(actionType);
    setIsSidebarOpen(true);
  };

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const { isLoading } = useGetProfile();
  const { profile } = useAppSelector(selectUser);

  const ContainerComponent = isLarge ? Container : Flex;
  const containerProps = !isLarge && { flexDirection: 'column', mt: '8px' };

  return (
    <>
      {isLarge && <Crumbs />}
      <ContainerComponent {...containerProps}>
        {!isLoading && profile && (
          <>
            <PersonalInfo profile={profile} onEditProfileClick={() => handleClick('editProfile')} />
            <AdditionalInfo
              onDeleteAccountClick={() => handleClick('deleteAccount')}
              onLogoutClick={() => handleClick('logout')}
              onChangePhoneClick={() => handleClick('changePhone')}
              onChangeEmailClick={() => handleClick('changeEmail')}
            />
            <ProfileSidebar
              action={action}
              setAction={setAction}
              profile={profile}
              isOpen={isSidebarOpen}
              setIsOpen={setIsSidebarOpen}
              setPopupOpen={setPopupOpen}
              setNewEmail={setNewEmail}
              newRegion={newRegion}
              setNewRegion={setNewRegion}
              newNumber={newNumber}
              setNewNumber={setNewNumber}
              setPhoto={setPhoto}
              photo={photo}
              isEditMode={editMode}
              setEditMode={setEditMode}
            />
            {isPopupOpen && <ChangeEmailPopup email={newEmail} handlePopupClose={() => setPopupOpen(false)} />}
          </>
        )}
        {action && status !== REQUEST_STATUS.NONE && <ProfileAlerts type={action} status={status} />}
      </ContainerComponent>
    </>
  );
};

export default Profile;
