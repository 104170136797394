import { useTranslation } from 'react-i18next';
import { H3 } from '@beauty/beauty-market-ui';
import { Services } from '../../../components';
import { CategoryType } from '../../../types';
import { OfferingsWrapper } from '../style';

interface OfferingsProps {
  categories: CategoryType[];
  organisationName: string;
}

const Offerings = ({ categories, organisationName }: OfferingsProps) => {
  const { t } = useTranslation();

  return categories.find(category => category.offersCount) ? (
    <OfferingsWrapper>
      <H3>{t('organisation.offerings')}</H3>
      <Services offersCategories={categories} organisationName={organisationName} />
    </OfferingsWrapper>
  ) : null;
};

export default Offerings;
