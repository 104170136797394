import { useTranslation } from 'react-i18next';
import { Flex, Sidebar, useMediaQuery, BottomSheet, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../../assets/info-image.png';
import { ActionType } from '../../../../../types/user';
import { ChangePhoneForm } from '../Forms/ChangePhoneForm';

export type ChangePhoneSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAction: (action: ActionType) => void;
  setNewRegion: (region: string) => void;
  setNewNumber: (number: string) => void;
};

export const ChangePhoneSidebar = ({
  isOpen,
  setIsOpen,
  setAction,
  setNewRegion,
  setNewNumber,
}: ChangePhoneSidebarProps) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const content = (
    <Flex flexDirection="column" width="100%">
      <ChangePhoneForm
        setNewRegion={setNewRegion}
        setNewNumber={setNewNumber}
        onSmsCodeFormOpen={() => setAction('smsCode')}
      />
    </Flex>
  );

  const generalProps = {
    isOpen,
    onClose: () => setIsOpen(false),
    label: t('profile.changePhone'),
    descriptor: t('profile.newPhoneDescription'),
  };

  return isLarge ? (
    <Sidebar {...generalProps}>
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" height="100%">
        <Flex pt="94px">
          <img src={InfoIcon} alt="" />
        </Flex>
        <ChangePhoneForm
          setNewRegion={setNewRegion}
          setNewNumber={setNewNumber}
          onSmsCodeFormOpen={() => setAction('smsCode')}
        />
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};
