import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';
import { EmailForm } from '../page/Login/Login.definitions';
import { getHost } from './utils';

export const sendEmailLink = async (
  data: EmailForm,
  accType?: AccType,
  language?: string,
  id?: string,
  orgId?: string,
  referral?: string,
) => {
  const requestDataUser = {
    email: data.email.toLowerCase(),
    host: getHost(),
    language,
    accType,
    referral,
  };
  const requestDataClient = {
    email: data.email.toLowerCase(),
    host: getHost(),
    language,
    accType,
    referral,
    id,
    orgId,
  };
  const requestData = id && orgId ? requestDataClient : requestDataUser;
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.Root,
    data: requestData,
  });
  return response ? { ...response.data, statusCode: response.status } : error?.data;
};
