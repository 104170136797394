import { useTranslation } from 'react-i18next';
import {
  IsraelFlag,
  UKFlag,
  USAFlag,
  PolandFlag,
  BelarusFlag,
  UkraineFlag,
  GeorgiaFlag,
  RussiaFlag,
  PortugalFlag,
} from '@beauty/beauty-market-ui';
import BarbershopOffer from './assets/offer_background_barbershop.png';
import NailsOffer from './assets/offer_background_nails.png';
import { setLink } from './helpers/utils';
import { RouterUrl } from './routes/routes';
import { StyledLi, StyledOl, StyledUl } from './style';

export const breadcrumbs = [
  { name: 'Home', onItemClick: () => console.log('onItemClick') },
  { name: 'Barbershops', onItemClick: () => console.log('onItemClick') },
  { name: 'Blacksmith Barbershop', onItemClick: () => console.log('onItemClick') },
];

export const hotOffers = [
  {
    id: '1',
    image: NailsOffer,
    headline: 'Nails',
    title: 'Burberry Nail Studio Haifa',
    rating: '8.3',
    reviewsCount: '92',
    photosCount: '43',
  },
  {
    id: '2',
    image: BarbershopOffer,
    headline: 'Barbershop',
    title: 'Rafael Barber Zone',
    rating: '9.2',
    reviewsCount: '145',
    photosCount: '53',
  },
  {
    id: '3',
    image: NailsOffer,
    headline: 'Nails',
    title: 'Burberry Nail Studio Yaffo',
    rating: '8.9',
    reviewsCount: '132',
    photosCount: '89',
  },
];

export const userCards = [
  { type: 'Mastercard', number: '4879621105870179', holderName: 'John Doe' },
  { type: 'Visa', number: '4879621105871101', holderName: 'John Doe' },
  { type: 'Visa', number: '4879621105876789', holderName: 'John Doe' },
];

export const DEFAULT_MIN_PRICE = 0;
export const DEFAULT_MAX_PRICE = 1000;
export const DEFAULT_PRICE_STEP = 10;

export const DEFAULT_MIN_DISTANCE = 0;
export const DEFAULT_MAX_DISTANCE = 100;
export const DEFAULT_DISTANCE_STEP = 1;

export const excludeMenuRouteList = [
  RouterUrl.Root,
  RouterUrl.Login,
  RouterUrl.LogOut,
  RouterUrl.Booking,
  RouterUrl.ForBusiness,
];

export const excludeFooterRouteList = [
  RouterUrl.Login,
  RouterUrl.LogOut,
  RouterUrl.ClientAppointments,
  RouterUrl.Booking,
  RouterUrl.Search,
  RouterUrl.Notifications,
];

export const SUPPORT_EMAIL = 'support@beautymarket.co.il';
export const PARTNER_EMAIL = 'partner@beautymarket.co.il';
export const SMS_CODE_EXPIRATION_TIME = 60;

export const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

export const selectedLocale = localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN';

export const getSelectedLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.resolvedLanguage.toUpperCase();
};

export const urlWithLang = (url: string) => {
  const { i18n } = useTranslation();
  const lng = i18n.resolvedLanguage.toLowerCase();
  return `${url}?lng=${lng}`;
};

// Test URL
export const MARKET_TEST_URL = 'https://bmarket-test.nolink.ink';
export const CRM_TEST_URL = 'https://crm0.nolink.ink';
const SERVER_TEST_URL = 'https://api-test.nolink.ink';

// Prod URL
export const MARKET_URL = 'https://beautymarket.co.il';
export const CRM_URL = 'https://crm.beautymarket.co.il';
const SERVER_URL = 'https://api.beautymarket.co.il';

// Pred prod
export const MARKET_PRED_URL = 'https://beautymarket.dev';
export const CRM_PRED_URL = 'https://crm.beautymarket.dev';
const SERVER_PRED_URL = 'https://api.beautymarket.dev';

export const PARTNER_TERMS_URL = 'https://storage.googleapis.com/beautymarketapi/Beauty%20Market%20Partner%20Terms.pdf';
export const stringForSendSupportEmail = 'mailto: support@beautymarket.co.il';
export const stringToOpenSite = 'http://beautymarket.co.il';

export const transComponents = {
  br: <br />,
  ul: <StyledUl />,
  ol: <StyledOl />,
  li: <StyledLi />,
  aTerms: setLink(RouterUrl.TermsOfService),
  aSite: setLink(stringToOpenSite),
  aWidget: setLink(''),
  aApp: <span />,
  aEmail: setLink(stringForSendSupportEmail),
  aPrivacy: setLink(RouterUrl.PrivacyPolicy),
  aProfile: setLink(`${RouterUrl.ClientProfile}/:id`),
  aContact: setLink(RouterUrl.ContactUs),
  aSignup: setLink(RouterUrl.SignUp, 'm'),
  aGooglePolicy: setLink(
    'https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes',
    's',
    true,
  ),
  aHowLongSection: setLink('#howLong', 's'),
};

export const emailReg =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
export const dateReg =
  /^((((0?[1-9]([-./])(0?[1-9]|1[0-2]))|([12][0-9]([-./])((0?[13-9])|1[0-2]))|(((1[0-9])|(2[0-8]))([-./])0?2)|(30([-./])((0?[13-9])|1[0-2]))|(31([-./])((0?[13578])|10|12)))(\5|\8|\15|\17|\21)(19|20)\d{2})|(29([-./])0?2(\27)(([0-9]{2}((0[48])|([2468][048])|([13579][26])))|((([02468][048])|([13579][26]))00))))$/;

export enum InvalidVariants {
  Exists = 'exists',
  Server = 'serverError',
  Email = 'email',
  FullName = 'fullName',
  InvalidPhone = 'invalidPhone',
  InvalidOTP = 'invalidOTP',
  ExpiredOTP = 'expiredOTP',
  NotFound = 'notFound',
  Password = 'password',
  MaxAttempts = 'maxAttempts',
  Unknown = 'unknown',
  TooMany = 'tooMany',
}

export const invalidHints = {
  [InvalidVariants.Exists]: 'registration.accountExists',
  [InvalidVariants.Server]: 'validation.server',
  [InvalidVariants.Email]: 'validation.emailNotValid',
  [InvalidVariants.FullName]: 'validation.fullName',
  [InvalidVariants.InvalidPhone]: 'registration.invalidPhone',
  [InvalidVariants.InvalidOTP]: 'registration.invalidOTP',
  [InvalidVariants.ExpiredOTP]: 'registration.expiredOTP',
  [InvalidVariants.NotFound]: 'login.isNotFound',
  [InvalidVariants.Password]: 'login.wrongPassword',
  [InvalidVariants.MaxAttempts]: 'validation.maxAttempts',
  [InvalidVariants.Unknown]: 'validation.unknown',
  [InvalidVariants.TooMany]: 'validation.tooMany',
};

export enum AccType {
  USER = 'USER',
  BUSINESS = 'BUSINESS',
}

export const zIndex = {
  minusOne: -1,
  zero: 0,
  one: 1,
  header: 6,
  search: 5,
  searchInputWrapper: 2,
  searchDropdownWrapper: 2,
  searchMobileWrapper: 2,
  menuClient: 1,
  bookingPopupDisabled: 3,
  bookingPopupActive: 10,
  footerContainer: 2,
  servicesWrapper: 5,
  customBottomSheet: 10,
  dropdown: 10,
  benefitCard: 2,
  managingCardWrapper: 2,
  googleMapWrapper: 4,
  googleFilterResultsWrapper: 11,
  loginInfoWrapper: 2,
  organisationContentWrapper: 1,
};

export const weekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const SHOW_RADIUS = 3;
export const KM_IN_DEGREES = 0.01;

export enum FilterType {
  SEARCH = 'search',
  CATEGORY = 'category',
}

export const regionListCode = [
  {
    code: '+972',
    country: 'Israel',
    flag: <IsraelFlag />,
  },
  {
    code: '+44',
    country: 'United Kingdom',
    flag: <UKFlag />,
  },
  {
    code: '+1',
    country: 'United States',
    flag: <USAFlag />,
  },
  {
    code: '+48',
    country: 'Poland',
    flag: <PolandFlag />,
  },
  {
    code: '+375',
    country: 'Belarus',
    flag: <BelarusFlag />,
  },
  {
    code: '+7',
    country: 'Russia',
    flag: <RussiaFlag />,
  },
  {
    code: '+380',
    country: 'Ukraine',
    flag: <UkraineFlag />,
  },
  {
    code: '+995',
    country: 'Georgia',
    flag: <GeorgiaFlag />,
  },
  {
    code: '+351',
    country: 'Portugal',
    flag: <PortugalFlag />,
  },
];

export const telAvivGeolocation = { lat: 32.0853, lng: 34.7817676 };
export const telAvivBounds = { NE: { lat: 32.146611, lng: 34.8519761 }, SW: { lat: 32.029252, lng: 34.7425159 } };

export enum SpecialistsRoles {
  MANAGER = 'MANAGER',
  SPECIALIST = 'SPECIALIST',
}

export const clearBooking = {
  service: null,
  timeslot: null,
  specialist: null,
};

export const INITIAL_DAYS_LOADED = 13;
export const NEXT_DAYS_LOADED = 13;

export const OurChoiceOrganisations = [
  '3a517527-063c-48d0-b3ed-c4a56c3c3012',
  'fe6a47a3-8e62-4706-8eb7-cbf1869f44dc',
  '42e151de-bce8-4e41-8b5f-9938b447e26d ',
];

export const MAX_STARS_COUNT = 5;
export const MAX_REVIEW_PHOTO_COUNT = 3;

export const Y_OFFSET_FOR_DOWN_LAYOUT = 150;

export const MAX_MARK = 5;

export enum Currency {
  ILS = 'ILS',
  USD = 'USD',
  EUR = 'EUR',
  BYN = 'BYN',
  GEL = 'GEL',
  PLN = 'PLN',
  RUB = 'RUB',
  UAH = 'UAH',
  GBP = 'GBP',
}

export enum Language {
  HE = 'HE',
  RU = 'RU',
  EN = 'EN',
}

export const EmptyProfile = {
  name: '',
  surname: '',
  dateOfBirth: '',
  gender: '',
  phoneId: '',
  code: '',
  number: '',
  emailId: '',
  email: '',
  addressId: '',
  country: '',
  city: '',
  fullAddress: '',
  avatarUrl: '',
  currency: '',
  accountNotification: [],
  telegram: null,
  idNumber: '',
  language: Language.EN,
};

export const getLanguageOptions = t => ({
  ids: [Language.HE, Language.RU, Language.EN],
  data: {
    [Language.EN]: {
      id: Language.EN,
      icon: <UKFlag />,
      value: t('language.english'),
    },
    [Language.RU]: {
      id: Language.RU,
      icon: <RussiaFlag />,
      value: t('language.russian'),
    },
    [Language.HE]: {
      id: Language.HE,
      icon: <IsraelFlag />,
      value: t('language.hebrew'),
    },
  },
});

export const instagramDomain = 'https://www.instagram.com';
