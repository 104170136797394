import styled, { css } from 'styled-components';
import { BodySmall, Flex, TelegramMonoIcon, colors, Button } from '@beauty/beauty-market-ui';

export const StyledBodySmall = styled(BodySmall)`
  @media screen and (max-width: 576px) {
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const NotificationItemWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;

  label {
    min-width: 40px;
  }
`;

export const PopupWrapper = styled(Flex)`
  & > div:first-child > div:first-child > div:first-child {
    padding-bottom: 0;
  }
`;

export const TelegramIcon = styled(TelegramMonoIcon)`
  path {
    stroke: none !important;
    fill: ${colors.white.standard};
  }
`;

export const StyledButton = styled(Button)<{ isRed: boolean }>`
  ${({ isRed }) =>
    isRed &&
    css`
      background-color: ${colors.red.standard}!important;
      border: none !important;
    `}
`;
