export const currencies = {
  'profile.currency.usd': 'USD',
  'profile.currency.eur': 'EUR',
  'profile.currency.ils': 'ILS',
};

export const languages = {
  'profile.language.english': 'en',
  'profile.language.russian': 'ru',
  'profile.language.hebrew': 'he',
};

export const reasonsForDelete = ['Irrelevance', 'Security issues', 'Dissatisfaction with the user experience'];
export const genders = ['male', 'female'];

export const appointmentNotificationId = '84d396b6-0bf4-4761-a522-4dbb0f15b231';
export const marketingNotificationId = '47243ab6-2fbb-49b3-b50d-bbadd8dfe9cf';
export const telegramNotificationIds = [appointmentNotificationId, marketingNotificationId];
