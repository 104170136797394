import { apiRequest } from '../api/api';
import { ReviewEndpoints } from '../api/endpoints/endpoints';
import { AppointmentReviewPayload } from '../types/review';
import { toFormData } from './utils';

export const sendAppointmentReview = async (data: AppointmentReviewPayload) => {
  const [error, response] = await apiRequest.post({
    endpoint: ReviewEndpoints.Appointment,
    data: toFormData(data),
  });
  return response ? response.data : error?.data;
};
