import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Flex } from '@beauty/beauty-market-ui';
import { AlertType, REQUEST_STATUS } from '../../../../../api/constants';

export enum ProfileActions {
  None = 'NONE',
  ChangePassword = 'CHANGE_PASSWORD',
  EditProfile = 'EDIT_PROFILE',
  DeleteAccount = 'DELETE_ACCOUNT',
  DisconnectTelegram = 'DISCONNECT_TELEGRAM',
}

export const getProfileAlertsContent = (type: ProfileActions, status: string, t) => {
  const contentMap = {
    [REQUEST_STATUS.SUCCESS]: {
      [ProfileActions.ChangePassword]: {
        title: t('profile.alerts.title.changePasswordSuccess'),
        subtitle: t('profile.alerts.subtitle.changePasswordSuccess'),
      },
      [ProfileActions.EditProfile]: {
        title: t('profile.alerts.title.editProfileSuccess'),
        subtitle: t('profile.alerts.subtitle.editProfileSuccess'),
      },
      [ProfileActions.DisconnectTelegram]: {
        title: t('profile.alerts.title.disconnectTelegramSuccess'),
        subtitle: t('profile.alerts.subtitle.disconnectTelegramSuccess'),
      },
    },
    [REQUEST_STATUS.FAILURE]: {
      [ProfileActions.ChangePassword]: {
        title: t('profile.alerts.title.changePasswordError'),
        subtitle: t('profile.alerts.subtitle.changePasswordError'),
      },
      [ProfileActions.EditProfile]: {
        title: t('profile.alerts.title.editProfileError'),
        subtitle: t('profile.alerts.subtitle.editProfileError'),
      },
      [ProfileActions.DeleteAccount]: {
        title: t('profile.alerts.title.deleteAccountError'),
        subtitle: t('profile.alerts.subtitle.deleteAccountError'),
      },
      [ProfileActions.DisconnectTelegram]: {
        title: t('profile.alerts.title.disconnectTelegramError'),
        subtitle: t('profile.alerts.subtitle.disconnectTelegramError'),
      },
    },
  };

  return contentMap[status]?.[type] || { title: '', subtitle: '' };
};

export const Alerts = ({ type, status }) => {
  const { t } = useTranslation();
  const { title, subtitle } = getProfileAlertsContent(type, status, t);

  switch (status) {
    case REQUEST_STATUS.SUCCESS:
    case REQUEST_STATUS.FAILURE:
      return (
        <Flex position="absolute">
          <Alert title={title} subtitle={subtitle} type={AlertType[status]} isAlertVisible />
        </Flex>
      );
    default:
      return null;
  }
};

export const ProfileAlerts = memo(Alerts);
