import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { SUMMARY_ARTICLES_COUNT, transComponents } from '../constants';

// TODO Refactor to enable eslint checks
export const Summary = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.summary.title')}</PrivacyItemTitleWrapper>
        {Array(SUMMARY_ARTICLES_COUNT)
          .fill(0)
          .map((__, i) => (
            <BodySmall
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              mb="24px"
              ml={i === 2 ? '16px' : '0px'}
            >
              <Trans components={transComponents}>{t(`termsOfService.summary.article${i + 1}`)}</Trans>
            </BodySmall>
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
