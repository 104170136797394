import { useTranslation } from 'react-i18next';
import { H3, colors, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { BenefitFlex, BenefitWrapper, StyledImg, CardButton } from '../style';

type BenefitProps = {
  imageUrl: string;
  number: string;
  title: string;
  info: string;
  isImageLeft: boolean;
};

export const Benefit = ({ imageUrl, number, title, info, isImageLeft }: BenefitProps) => {
  const { t } = useTranslation();
  const { business } = useGetUrl();
  const handleOpenBusiness = () => window.open(business, '_blank');
  return (
    <BenefitWrapper flexDirection={['column', isImageLeft ? 'row' : 'row-reverse']}>
      <StyledImg src={imageUrl} alt="benefitImage" />
      <BenefitFlex>
        <BodyLarge large color={colors.grey.standard}>
          {number}
        </BodyLarge>
        <H3>{title}</H3>
        <BodySmall>{info}</BodySmall>
        <CardButton design="primary" size="small" onClick={handleOpenBusiness}>
          {t('business.joinBusiness')}
        </CardButton>
      </BenefitFlex>
    </BenefitWrapper>
  );
};
