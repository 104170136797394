import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5, Promo, Div, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { FilterType, OurChoiceOrganisations, getSelectedLanguage } from '../../../../constants';
import { getFullOrganisationLink, getTranslation, openLink, sortByOurChoice } from '../../../../helpers/utils';
import { useGetOrganisations } from '../../../../hooks/useGetOrganisations';
import { useAppSelector } from '../../../../store/hooks';
import { selectHistory } from '../../../../store/redux-slices/historySlice';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import { MarkerType } from '../../../../types';
import { FilterResultsWrapper, FilterResultsContent } from './style';

type FilterResultsSidebarProps = {
  sidebarLabel: string;
  hoveredItemId?: string;
  onHover?: (organisation: MarkerType) => void;
  onLeave?: () => void;
  setShareModalOpen?: () => void;
  setFavouriteModalOpen?: () => void;
  setShareOrganisationId?: (orgId: string) => void;
};

const FilterResultsSidebar = ({
  sidebarLabel,
  hoveredItemId,
  onHover,
  onLeave,
  setShareModalOpen,
  setFavouriteModalOpen,
  setShareOrganisationId,
}: FilterResultsSidebarProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const { topCategory } = useAppSelector(selectHistory).history;
  const { isLoading, organisations: categoryOrganisations } = useGetOrganisations(topCategory);
  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);
  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleModalOpen = (id: string, type: 'share' | 'favourite') => {
    setShareOrganisationId?.(id);
    type === 'share' ? setShareModalOpen?.() : setFavouriteModalOpen?.();
  };

  const organisationsResults = useMemo(
    () =>
      sortByOurChoice(organisations).map(organisation => (
        <Div
          key={organisation.id}
          onMouseEnter={() =>
            onHover &&
            onHover({
              ...organisation,
              image: organisation.mainPhoto || '',
              headline: organisation.category.map(cat => getTranslation(cat, language)).join(', '),
              title: organisation.name,
            })
          }
          onMouseLeave={onLeave}
        >
          <Promo
            image={organisation.mainPhoto}
            headline={organisation.category?.map(cat => getTranslation(cat, language)).join(', ')}
            title={/* organisation.orgName ? getTranslation(organisation.orgName, language) : */ organisation.name}
            rating={organisation.rating}
            reviews={t('organisation.reviews', { count: organisation.review })}
            photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
            badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
            href={
              organisation.id !== '0' &&
              getFullOrganisationLink(organisation.id, `?org=${organisation.name}&service=${topCategory}`)
            }
            onShareClick={() => organisation.id !== '0' && handleModalOpen(organisation.id, 'share')}
            // onFavouriteClick={() => organisation.id !== '0' && handleModalOpen(organisation.id, 'favourite')}
            isHighlighted={hoveredItemId === organisation.id}
            // isBlueHover={organisation.isActive}
            // isActive={organisation.isActive}
          />
        </Div>
      )),
    [organisations, t, hoveredItemId, OurChoiceOrganisations],
  );

  const filterResults = [...organisationsResults];

  const content = (
    <>
      <H5>{sidebarLabel}</H5>
      {/* <Caption>Organization name</Caption> */}
      <FilterResultsContent>{filterResults}</FilterResultsContent>
    </>
  );

  return isLarge ? <FilterResultsWrapper>{content}</FilterResultsWrapper> : null;
};

export default FilterResultsSidebar;
