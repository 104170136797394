import { useTranslation } from 'react-i18next';
import { Popup, Button, getMinWidthMediaQuery, useMediaQuery, BottomSheet } from '@beauty/beauty-market-ui';

type FavouritePopupProps = {
  isOpen: boolean;
  handlePopupClose: () => void;
};

export const FavouritePopup = ({ isOpen, handlePopupClose }: FavouritePopupProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const PopupFooterBody = (
    <>
      <Button
        design="secondary"
        size={isLarge ? 'large' : 'medium'}
        width="100%"
        onClick={handlePopupClose}
        mb={!isLarge ? '8px' : ''}
      >
        {t('favourites.modals.cancel')}
      </Button>
      <Button size={isLarge ? 'large' : 'medium'} width="100%" onClick={handlePopupClose}>
        {t('favourites.modals.delete')}
      </Button>
    </>
  );

  return isLarge ? (
    <Popup
      title={t('favourites.modals.organisation.title')}
      info={t('favourites.modals.organisation.body')}
      open={isOpen}
      handleClose={handlePopupClose}
      FooterBody={PopupFooterBody}
    />
  ) : (
    <BottomSheet
      isOpen={isOpen}
      onClose={handlePopupClose}
      label={t('favourites.modals.organisation.title')}
      descriptor={t('favourites.modals.organisation.body')}
      content={PopupFooterBody}
      detent="content-height"
    />
  );
};
