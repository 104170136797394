// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@beauty/beauty-market-ui';
import { useGetDayCount } from '../../../hooks';
import { ColumnFlex, StyledH7 } from '../style';

export const TodayStatisticsSection = () => {
  // const [active, setActive] = useState(0);
  const { t } = useTranslation();
  const { count } = useGetDayCount();

  return (
    <ColumnFlex mb="80px">
      <StyledH7 textAlign="center" color={colors.white.standard}>
        {count !== null && `${count} ${t('home.bookToday')}`}
      </StyledH7>
      {/* <Flex justifyContent="center">
        <SegmentTab
          tabs={[t('organisation.about.services'), t('home.hotDeals')]}
          activeTab={active}
          onToggle={setActive}
        />
      </Flex> */}
    </ColumnFlex>
  );
};
