import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { Button, Flex, getMinWidthMediaQuery, useMediaQuery, BodyLarge, Div } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../components/functional/formik/formik-input/FormikInput';
import { AccType, InvalidVariants } from '../../../../../constants';
import { updateEmail } from '../../../../../helpers/profile';
import { getHost, getInvalidType } from '../../../../../helpers/utils';
import {
  EmailForm,
  EmailFormFields,
  emailFormValidationSchema,
  initialEmailFormValues,
} from '../../Profile.definitions';
import { FormWrapper } from '../../style';

type ChangeEmailFormProps = {
  email: string;
  onClose: () => void;
  onPopupOpen: () => void;
  setNewEmail: (email: string) => void;
};

export const ChangeEmailForm = ({ email, onClose, onPopupOpen, setNewEmail }: ChangeEmailFormProps) => {
  const { t } = useTranslation();
  const [submitActive, setSubmitActive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleChangeEmail = async (data: EmailForm) => {
    setLoading(true);
    const payload = {
      email,
      newEmail: data[EmailFormFields.Email],
      accType: AccType.USER,
      host: getHost(),
      language: localStorage.getItem('i18nextLng')!.toUpperCase(),
    };

    const response = await updateEmail(payload);
    if (response.success) {
      onClose();
      setInvalidType(null);
      setNewEmail(payload.newEmail);
      onPopupOpen();
    } else setInvalidType(getInvalidType(response.statusCode, 'changeEmail'));
    setLoading(false);
  };

  const formikContextValue = {
    initialValues: initialEmailFormValues,
    validationSchema: emailFormValidationSchema(t),
    onSubmit: handleChangeEmail,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, initialValues, values }) => {
        setSubmitActive(isValid && !_.isEqual(initialValues, values) && !invalidType); // TODO Refactor set state
        return (
          <FormWrapper>
            <Form>
              <Flex width="100%" flexDirection="column">
                <BodyLarge mb="16px">{t('profile.enterNewEmail')}</BodyLarge>
                <FormikInput
                  autoFocus
                  id={EmailFormFields.Email}
                  name={EmailFormFields.Email}
                  placeholder="Email"
                  invalidType={invalidType}
                  onInput={() => setInvalidType(null)}
                />

                <Div mt="41px">
                  <Button
                    disabled={!submitActive || isLoading}
                    size={isLarge ? 'large' : 'medium'}
                    width="100%"
                    type="submit"
                  >
                    {t('profile.change')}
                  </Button>
                </Div>
              </Flex>
            </Form>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
