import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from '@beauty/beauty-market-ui';
import { getPathWithoutId } from '../../hooks/helpers';
import useGetBreadCrumbs from '../../hooks/useGetBreadCrumbs';
import { useGetSearchParams } from '../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectHistory,
  setFrom,
  setOrganisation,
  setTopCategory,
  setView,
} from '../../store/redux-slices/historySlice';
import { BreadcrumbsContainer } from './style';

type CrumbsProps = {
  variant?: 'pathname';
};

const Crumbs = (props: CrumbsProps) => {
  const { variant } = props;
  const { t } = useTranslation();
  const search = useGetSearchParams()[0];
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { name } = useAppSelector(selectHistory).history.organisation;
  const { orgId } = useParams();

  if (search && name === '') {
    switch (getPathWithoutId(location.pathname)) {
      case `${RouterUrl.Organisation}/`:
        dispatch(setFrom(search.from || ''));
        dispatch(setOrganisation({ name: search.org, path: `${RouterUrl.Organisation}/${orgId}` }));
        dispatch(setTopCategory(search.topCategory));
        dispatch(setView(search.view));
        break;
      case `${RouterUrl.Booking}/`:
        dispatch(setFrom(search.from || ''));
        dispatch(setOrganisation({ name: search.org, path: `${RouterUrl.Organisation}/${orgId}` }));
        dispatch(setTopCategory(search.topCategory));
        dispatch(setView(search.view));
        break;
      default:
        break;
    }
  }

  const breadcrumbs = useGetBreadCrumbs(t, variant);

  return (
    <BreadcrumbsContainer mb="40px">
      <Breadcrumbs items={breadcrumbs} />
    </BreadcrumbsContainer>
  );
};
export default Crumbs;
