import { useTranslation } from 'react-i18next';
import { Flex, Sidebar } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../../assets/info-image.png';
import { AddCardForm } from '../Forms/AddCard/AddCardForm';

type AddCardSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const AddCardSidebar = ({ isOpen, setIsOpen }: AddCardSidebarProps) => {
  const { t } = useTranslation();

  return (
    <Sidebar
      open={isOpen}
      label={t('profile.card.addCard')}
      handleClose={() => {
        setIsOpen(false);
      }}
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" height="100%">
        <Flex pt="94px">
          <img src={InfoIcon} alt="" />
        </Flex>

        <AddCardForm
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Flex>
    </Sidebar>
  );
};

export { AddCardSidebar };
