import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { OrganisationEndpoints } from '../api/endpoints/endpoints';
import { useAppDispatch } from '../store/hooks';
import { setForceFetch } from '../store/redux-slices/userSlice';
import { OrganisationRenderType } from '../types/organisation';

export const useGetOrganisations = (topCategory: string, isFetch?: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<OrganisationRenderType[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isOrganisationsLoading = true;

    const fetchOrganisations = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: OrganisationEndpoints.Organisations,
        endpointParams: topCategory !== 'All' ? encodeURIComponent(topCategory) : 'all',
      });

      if (isOrganisationsLoading) {
        setOrganisations(response?.data);
        setIsLoading(false);
      }
    };

    fetchOrganisations();
    dispatch(setForceFetch(false));

    return () => {
      isOrganisationsLoading = false;
    };
  }, [topCategory, isFetch]);

  return {
    isLoading,
    organisations,
  };
};
