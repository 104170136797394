import styled from 'styled-components';
import { Caption, colors } from '@beauty/beauty-market-ui';

export const StyledCaption = styled(Caption)`
  color: ${colors.grey.dark} !important;

  &::before {
    content: '•';
    color: ${colors.grey.dark};
    padding: 0 5px;
  }
`;
