import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5 } from '@beauty/beauty-market-ui';
import Img1 from '../../../assets/portfolio/vera1.png';
import Img2 from '../../../assets/portfolio/vera2.png';
import Img3 from '../../../assets/portfolio/vera3.png';
import Img4 from '../../../assets/portfolio/vera4.png';
import Img5 from '../../../assets/portfolio/vera5.png';
import Img6 from '../../../assets/portfolio/vera6.png';
import Img7 from '../../../assets/portfolio/vera7.png';
import Img8 from '../../../assets/portfolio/vera8.png';
import { GappedFlex } from '../../../style';
import { PortfolioImage, ShowButton, ShowMoreWrapper } from '../style';

const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img1, Img2];

// TODO Refactor to enable eslint checks
export const Portfolio = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const isShowedButton = useMemo(() => images.length > 8, [images]);

  return (
    <>
      <H5 mb="24px">{t('organisation.specialists.portfolio')}</H5>
      <ShowMoreWrapper height={isExpanded ? 'auto' : '216px'}>
        <GappedFlex gap="8px" flexWrap="wrap">
          {images.map((image, i) => (
            <PortfolioImage
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              src={image}
              alt={`img${i}`}
            />
          ))}
        </GappedFlex>
      </ShowMoreWrapper>
      {isShowedButton && (
        <ShowButton size="small" design="tertiary" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('organisation.about.showLess') : t('organisation.about.showMore')}
        </ShowButton>
      )}
    </>
  );
};
