import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Div, Button, H2, BodySmall } from '@beauty/beauty-market-ui';
import { FormikPasswordInput } from '../../../components/functional/formik/formik-password-input/FormikPasswordInput';
import { transComponents } from '../../../constants';
import { updatePassword } from '../../../helpers/updatePassword';
import { useGetSearchParams } from '../../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../../routes/routes';
import {
  ResetPasswordField,
  ResetPasswordForm,
  initialResetPasswordValue,
  resetPasswordSchema,
} from './ResetPassword.definitions';

const UpdatePassword = () => {
  const [searchParamsAsObject] = useGetSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFormSubmit = useCallback(
    async (data: ResetPasswordForm) => {
      const response = await updatePassword({
        token: searchParamsAsObject!.token,
        password: data.password,
      });

      navigate(RouterUrl.Login);
    },
    [searchParamsAsObject],
  );

  const formikContextValue = {
    initialValues: initialResetPasswordValue,
    validationSchema: resetPasswordSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Div>
      <H2 mb="16px">
        <Trans components={transComponents}>{t('login.newPassword')}</Trans>
      </H2>
      <BodySmall mb="32px">{t('validation.passwordHint')}</BodySmall>

      <Formik {...formikContextValue}>
        {({ isValid, values }) => (
          <Form>
            <FormikPasswordInput
              id={ResetPasswordField.Password}
              name={ResetPasswordField.Password}
              placeholder="Password"
            />

            <Button
              disabled={!isValid || !values.password}
              design="primary"
              mt="24px"
              width="100%"
              size="large"
              type="submit"
            >
              {t('button.continue')}
            </Button>
          </Form>
        )}
      </Formik>
    </Div>
  );
};

export default UpdatePassword;
