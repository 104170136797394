import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, Flex, MastercardMonoIcon, VisaMonoIcon, AmexMonoIcon } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../../components/functional/formik/formik-input/FormikInput';
import { StyledFormikInput } from '../../../style';
import {
  AddCardFormFields,
  AddCardFormType,
  EditAddCardFormValidationSchema,
  initialAddCardFormValues,
} from './AddCardForm.definitions';

const AddCardForm = ({ onClose }) => {
  const { t } = useTranslation();

  const onFormSubmit = async (values: AddCardFormType) => {
    setTimeout(() => {
      console.log(`card info: `, values);
      onClose();
    }, 550);
  };

  const formikContextValue = {
    initialValues: initialAddCardFormValues,
    validationSchema: EditAddCardFormValidationSchema,
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  const getIcon = (firstDigit: string) => {
    switch (firstDigit) {
      case '3':
        return <AmexMonoIcon />;
      case '4':
        return <VisaMonoIcon />;
      case '5':
        return <MastercardMonoIcon />;
      default:
        return '';
    }
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Formik {...formikContextValue}>
        {({ isValid, values }) => (
          <Form>
            <StyledFormikInput
              design="white"
              id={AddCardFormFields.CardNumber}
              name={AddCardFormFields.CardNumber}
              placeholder={t('profile.card.cardNumber')}
              iconRight={getIcon(values.cardNumber.slice(0, 1))}
              mb="16px"
            />
            <FormikInput
              design="white"
              id={AddCardFormFields.Name}
              name={AddCardFormFields.Name}
              placeholder={t('profile.card.name')}
              mb="16px"
            />
            <Flex mb="16px">
              <FormikInput
                design="white"
                id={AddCardFormFields.Expires}
                name={AddCardFormFields.Expires}
                placeholder={t('profile.card.expires')}
              />
              <FormikInput
                design="white"
                id={AddCardFormFields.CVV}
                name={AddCardFormFields.CVV}
                placeholder={t('profile.card.cvv')}
                ml="8px"
                type="password"
              />
            </Flex>
            <Button disabled={!isValid} size="large" width="100%">
              {t('profile.applyChanges')}
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export { AddCardForm };
