interface ImageProps {
  src: string;
  width?: string;
  height?: string;
  alt?: string;
}

const Image = ({ src, width, height, alt, ...rest }: ImageProps) => (
  <img src={src} width={width} height={height} alt={alt} {...rest} />
);

export default Image;
