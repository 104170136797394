import { TFunction, Trans } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Flex, BodySmall, colors } from '@beauty/beauty-market-ui';
import { transComponents } from '../constants';
import {
  articlesInQuestions,
  clientFaqCategories,
  businessFaqCategories,
  questionsInCategories,
} from '../page/FAQ/constants';
import { FAQCategoryObj, FaqQuestionsCategories, FAQQuestionsVariants, FAQSlideType } from '../page/FAQ/types';
import { range } from './utils';

const renderItem = (item: string, i?: number) => (
  <BodySmall key={i} mb="24px" color={colors.grey.dark}>
    <Trans components={transComponents}>{item}</Trans>
  </BodySmall>
);

export const getFaqQuestions = (
  type: FAQQuestionsVariants,
  t: TFunction<'translation', undefined>,
): FAQCategoryObj[] => {
  const faqCategories = type === FAQQuestionsVariants.Client ? clientFaqCategories : businessFaqCategories;
  return range(0, faqCategories.length - 1).map(id => {
    const questionsQty = questionsInCategories[type][id];
    const questionsRange = range(0, questionsQty - 1);
    return {
      id: (id + 1).toString(),
      category: t(`faq.categories.${type}.${faqCategories[id]}.title`),
      questions: questionsRange.map((_, index) => ({
        id: nanoid(),
        title: t(`faq.categories.${type}.${faqCategories[id]}.section${index + 1}.title`),
        answer: (
          <Flex flexDirection="column">
            {range(0, articlesInQuestions[type][id][index] - 1).map((__, articleId) =>
              renderItem(
                t(`faq.categories.${type}.${faqCategories[id]}.section${index + 1}.article${articleId + 1}`),
                articleId,
              ),
            )}
          </Flex>
        ),
      })),
    };
  });
};

export const getFaqCategories = (
  categories: FAQCategoryObj[],
  t: TFunction<'translation', undefined>,
): FAQSlideType[] => {
  if (!categories) return [];
  const sumQuestions = categories.reduce((acc, category) => category.questions.length + acc, 0);

  return [{ id: '0', name: t('faq.all') as FaqQuestionsCategories, count: sumQuestions }].concat(
    categories.map(category => ({
      id: category.id,
      name: category.category,
      count: category.questions.length,
    })),
  );
  // .sort((a: FAQSlideType, b: FAQSlideType) => b.count - a.count);
};
