import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import {
  Button,
  Flex,
  getMinWidthMediaQuery,
  useMediaQuery,
  BodyLarge,
  ListRegionCode,
} from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../components/functional/formik/formik-input/FormikInput';
import { InvalidVariants } from '../../../../../constants';
import { updatePhone } from '../../../../../helpers/profile';
import { getInvalidType, getListRegionCode } from '../../../../../helpers/utils';
import { useAppDispatch } from '../../../../../store/hooks';
import { updateSmsCodeTime } from '../../../../../store/redux-slices/userSlice';
import {
  initialByPhoneValues,
  LoginByPhoneFields,
  LoginByPhoneForm,
  loginPhoneFormValidationSchema,
} from '../../../../Login/Login.definitions';
import { WrapperInput } from './EditProfile/style';

type ChangePhoneFormProps = {
  onSmsCodeFormOpen: () => void;
  setNewRegion: (region: string) => void;
  setNewNumber: (number: string) => void;
};

export const ChangePhoneForm = ({ onSmsCodeFormOpen, setNewRegion, setNewNumber }: ChangePhoneFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [newCode, setNewCode] = useState<string>('+972');
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleChangePhone = async (data: LoginByPhoneForm) => {
    setLoading(true);
    const payload = {
      code: newCode,
      number: data[LoginByPhoneFields.Number],
    };

    const response = await updatePhone(payload);
    if (response?.status === 201) {
      setNewRegion(newCode);
      setNewNumber(payload.number);
      setInvalidType(null);
      onSmsCodeFormOpen();
      dispatch(updateSmsCodeTime(new Date().getTime()));
    } else setInvalidType(getInvalidType(response?.status ?? 0, 'changePhone'));
    setLoading(false);
  };

  const formikContextValue = {
    initialValues: initialByPhoneValues,
    validationSchema: loginPhoneFormValidationSchema(t),
    onSubmit: handleChangePhone,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form style={{ width: '100%' }}>
          <Flex width="100%" flexDirection="column">
            <BodyLarge mb="16px">{t('profile.enterNewPhone')}</BodyLarge>
            <Flex mb="16px" width="100%" justifyContent="space-between">
              <ListRegionCode
                design="white"
                backgroudColor="white"
                id={LoginByPhoneFields.Region}
                name={LoginByPhoneFields.Region}
                currentRegionCode={newCode}
                placeholder={t('profile.region')}
                options={getListRegionCode()}
                handleSelect={code => setNewCode(code)}
              />

              <WrapperInput>
                <FormikInput
                  design="white"
                  id={LoginByPhoneFields.Number}
                  name={LoginByPhoneFields.Number}
                  placeholder={t('profile.phone')}
                  invalidType={invalidType}
                  onInput={() => setInvalidType(null)}
                  focused
                />
              </WrapperInput>
            </Flex>

            <Flex mt="41px">
              <Button
                disabled={!isValid || invalidType || isLoading}
                size={isLarge ? 'large' : 'medium'}
                width="100%"
                type="submit"
              >
                {t('profile.change')}
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
