import { useTranslation } from 'react-i18next';
import { H7, colors } from '@beauty/beauty-market-ui';
import Calendar1x from '../../assets/calendar@1x.png';
import Calendar2x from '../../assets/calendar@2x.png';
import { setImgUrl } from '../../helpers/utils';
import { useGetUrl } from '../../hooks/useGetUrl';
import { StyledButton, TopImageWrapper, TopTitle, TopWrapper } from './style';

export const TopSection = () => {
  const { t } = useTranslation();
  const imgUrls = [Calendar1x, Calendar2x];
  const { business } = useGetUrl();
  const handleOpenBusiness = () => window.open(business, '_blank');
  return (
    <TopWrapper>
      <TopTitle large>{t('business.title')}</TopTitle>
      <H7 width="100%" maxWidth="600px" textAlign="center" m={['0', '0', '0', '0 233px']} color={colors.white.standard}>
        {t('business.titleHint')}
      </H7>
      <StyledButton design="primary" onClick={handleOpenBusiness}>
        {t('business.joinBusiness')}
      </StyledButton>

      <TopImageWrapper>
        <img src={setImgUrl(imgUrls)} alt="Calendar" />
      </TopImageWrapper>
    </TopWrapper>
  );
};
