import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Flex, ListRegionCode } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { InvalidVariants } from '../../../constants';
import { loginPhoneVerification } from '../../../helpers/loginPhoneVerification';
import { getInvalidType, getListRegionCode } from '../../../helpers/utils';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { updateSmsCodeTime } from '../../../store/redux-slices/userSlice';
import {
  initialByPhoneValues,
  LoginByPhoneForm,
  LoginByPhoneFields,
  loginPhoneFormValidationSchema,
} from '../Login.definitions';
import { StyledFlex } from '../style';

const LoginByPhone = () => {
  const [currentRegionCode, setCurrentRegionCode] = useState<string>('+972');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onFormSubmit = useCallback(
    async (data: LoginByPhoneForm) => {
      setLoading(true);
      const phoneVer = { code: currentRegionCode, number: data.number };
      const response = await loginPhoneVerification(phoneVer);

      if (response.status === 'pending') {
        setInvalidType(null);
        dispatch(updateSmsCodeTime(new Date().getTime()));
        navigate(RouterUrl.LoginSMS, { state: { phone: data.number, region: currentRegionCode, ...state } });
      } else setInvalidType(getInvalidType(response.statusCode, 'login'));
      setLoading(false);
    },
    [navigate, currentRegionCode, dispatch, invalidType],
  );

  const formikContextValue = {
    initialValues: initialByPhoneValues,
    validationSchema: loginPhoneFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form>
          <StyledFlex>
            <ListRegionCode
              currentRegionCode={currentRegionCode}
              handleSelect={code => setCurrentRegionCode(code)}
              id={LoginByPhoneFields.Region}
              name={LoginByPhoneFields.Region}
              placeholder={t('registration.region')}
              options={getListRegionCode()}
            />

            <Flex width="100%" ml="8px">
              <FormikInput
                autoFocus
                id={LoginByPhoneFields.Number}
                name={LoginByPhoneFields.Number}
                placeholder={t('registration.phone')}
                invalidType={invalidType}
                onInput={() => setInvalidType(null)}
              />
            </Flex>
          </StyledFlex>

          <Button
            disabled={!isValid || invalidType || isLoading}
            design="primary"
            mt="24px"
            width="100%"
            size="large"
            type="submit"
          >
            {t(`login.continue`)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginByPhone;
