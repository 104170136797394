import { Flex, Avatar, H5, BodySmall, colors, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';

type RatingCardProps = {
  avatarUrl: string;
  title: string;
  info: string;
  isChecked?: boolean;
};

export const RatingCard = ({ avatarUrl, title, info, isChecked = false }: RatingCardProps) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const avatarSize = (() => {
    if (isChecked) return 's';
    if (isMobile) return 'l';
    return 'xl';
  })();

  return (
    <Flex flexDirection="column" alignItems="center">
      <Avatar size={avatarSize} url={avatarUrl} />
      <Flex flexDirection="column" justifyContent="end" mt="16px" mb={isChecked ? '40px' : '104px'} maxWidth="240px">
        <H5 mb="5px" textAlign="center">
          {title}
        </H5>
        <BodySmall lowline textAlign="center" color={colors.grey.dark}>
          {info}
        </BodySmall>
      </Flex>
    </Flex>
  );
};
