import { useMemo } from 'react';
import { FieldMetaProps } from 'formik';

export const getStatus = (touched?: boolean, error?: boolean) => {
  if (touched && error) {
    return 'invalid';
  }
  return 'default';
};

export const useStatus = <V = any>(meta: FieldMetaProps<V>) =>
  useMemo(() => getStatus(meta.touched, Boolean(meta.error)), [meta.error, meta.touched]);
