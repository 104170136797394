import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Flex, Button } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../components/functional/formik/formik-input/FormikInput';
import { getSelectedLanguage } from '../../constants';
import { sendJoinUsRequest } from '../../helpers/sendJoinUsRequest';
import { SendForm, initialSendFormValues, sendFormValidationSchema } from './JoinBusiness.definitions';

export const SendMessageForm = () => {
  const { t } = useTranslation();

  const currentLanguage = getSelectedLanguage();

  const onFormSubmit = async (values: SendForm) => {
    try {
      await sendJoinUsRequest({ ...values, language: currentLanguage, client: 'BUSINESS' });
    } catch (error) {
      console.error('Error', error);
    }
  };

  const formikContextValue = {
    initialValues: initialSendFormValues,
    validationSchema: sendFormValidationSchema,
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Formik {...formikContextValue}>
        {({ isValid, values }) => (
          <Form>
            <Flex width="100%" flexDirection="column">
              <FormikInput mb="24px" design="grey" id="email" name="email" placeholder={t('joinBusiness.email')} />
              <FormikInput design="grey" id="fullName" name="fullName" placeholder={t('joinBusiness.message')} />
            </Flex>
            <Button
              disabled={!isValid || !values.email || !values.fullName}
              type="submit"
              design="primary"
              mt="24px"
              width="100%"
              size="large"
            >
              {t('joinBusiness.send')}
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
