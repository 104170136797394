import React from 'react';
import { Avatar, AvatarStatus, Button, BodySmall, Caption, theme } from '@beauty/beauty-market-ui';
import { BlockWrapper, CardWrapper } from './style';

type NotificationCardProps = {
  icon: string | JSX.Element;
  status: AvatarStatus;
  title: string;
  placeholder: string;
  isRead: boolean;
  label?: string;
  onClick?: (e: Event) => void;
  onLeftButtonClick?: (e: Event) => void;
  onRightButtonClick?: () => void;
  leftButton?: string;
  rightButton?: string;
  onHover?: () => void;
  rtl?: boolean;
};

export const NotificationCard = ({
  icon,
  status = 'hot',
  title,
  placeholder,
  isRead = false,
  label,
  onClick,
  onLeftButtonClick,
  onRightButtonClick,
  leftButton = 'Rebook',
  rightButton = 'Ignore',
  onHover,
  rtl,
}: NotificationCardProps) => (
  <CardWrapper isRead={isRead} onClick={onClick} onMouseEnter={onHover} flexDirection={rtl ? 'row-reverse' : 'row'}>
    <Avatar size="xs" url={icon} status={status} rating="" />
    <BlockWrapper flexDirection="column" alignItems={rtl ? 'end' : 'start'}>
      <BodySmall textAlign={rtl ? 'end' : 'start'}>{title}</BodySmall>
      {label && <Caption>{label}</Caption>}
      <BlockWrapper flexDirection={rtl ? 'row-reverse' : 'row'}>
        {onLeftButtonClick && (
          <Button size="extraSmall" onClick={onLeftButtonClick}>
            {leftButton}
          </Button>
        )}
        {onRightButtonClick && (
          <Button size="extraSmall" design="quaternary">
            {rightButton}
          </Button>
        )}
      </BlockWrapper>
      <Caption lowline color={theme.colors.grey.dark}>
        {placeholder}
      </Caption>
    </BlockWrapper>
  </CardWrapper>
);
