import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RouterUrl } from '../../../routes/routes';
import { LinkWrapper } from '../style';

const CreateNewAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LinkWrapper
      mt="16px"
      mb="32px"
      design="blue"
      size="s"
      justifyContent="center"
      onClick={() => {
        navigate(RouterUrl.SignUp);
      }}
      href=""
    >
      {t('login.createNew')}
    </LinkWrapper>
  );
};

export default CreateNewAccount;
