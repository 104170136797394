import { useState } from 'react';
import { ArrowRightIcon, Button, colors, Flex, H3, H6, H7, Input, CopyIcon } from '@beauty/beauty-market-ui';
import BalanceBanner from '../../../assets/balance-banner.png';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { Container, InputIcon, InputWrapper, StyledInput } from './stlye';

export const Balance = () => {
  const userApp = useAppSelector(selectUser);

  const defaultValue = userApp?.client?.coins ?? '0';
  const [coins] = useState(defaultValue);
  const [crypto] = useState(defaultValue);

  return (
    <Flex flexDirection="column" p="0 8px" gap="56px">
      <Flex flexDirection="column" gap="16px">
        <H3>My balance</H3>
        <InputWrapper>
          <InputIcon width="20" height="20" />
          <Input id="Coins" value={defaultValue} design="grey" type="text" caption="Points of Beauty Market" />
        </InputWrapper>
      </Flex>
      <Flex flexDirection="column" gap="16px">
        <H7>Conversion from BMC to USDT</H7>
        <Flex alignItems="center" gap="8px">
          <StyledInput id="BMC" value={coins} design="grey" type="text" placeholder="BMC" />
          <ArrowRightIcon width="16" height="16" />
          <StyledInput id="USDT" value={crypto} design="grey" type="text" placeholder="USDT" />
        </Flex>
        <Button size="small" width="100%">
          Withdraw
        </Button>
      </Flex>
      <Container image={BalanceBanner}>
        <H6 color={colors.white.standard}>Invite your friends and receive bonus in 50 BMC</H6>
        <Flex gap="16px">
          <Button size="medium" design="withShadow" width="100%">
            Invite friends
          </Button>
          <Button size="medium" design="withShadow">
            <CopyIcon />
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};
