const getRussianPluralForm = (key, count, t) => {
  // 5-20
  if (count >= 5 && count <= 20) {
    return t(`${key}.many`, { count });
  }

  // end 1
  if (count % 10 === 1) {
    return t(`${key}.one`, { count });
  }

  // end 2, 3, 4
  if (count % 10 >= 2 && count % 10 <= 4) {
    return t(`${key}.few`, { count });
  }

  // other
  return t(`${key}.many`, { count });
};

const getEnglishPluralForm = (key, count, t) => {
  if (count === 1) {
    return t(`${key}.one`, { count });
  }
  return t(`${key}.many`, { count });
};

export const getPluralForm = (key, count, language, t) => {
  if (language === 'ru' || language === 'RU') {
    return getRussianPluralForm(key, count, t);
  }
  return getEnglishPluralForm(key, count, t);
};
