import { range } from 'lodash';
import { MAX_MARK } from '../../constants';
import { StarFillIconStyled, StarsWrapper } from './style';

type StarsProps = {
  rating: number;
  hoveredStars: number;
  starSize: string;
  defaultColor: string;
  fillColor: string;
  onClick?: (id: number) => void;
  onHover?: (id: number) => void;
};

export const Stars = ({ rating, hoveredStars, starSize, defaultColor, fillColor, onClick, onHover }: StarsProps) => {
  const stars: JSX.Element[] = range(0, MAX_MARK).map(star => {
    const currentColor =
      (!hoveredStars && star < rating) || (hoveredStars && star < hoveredStars) ? fillColor : defaultColor;
    return (
      <StarFillIconStyled
        id={`star${star + 1}`}
        key={star}
        width={starSize}
        height={starSize}
        fillColor={currentColor}
        onClick={() => {
          onClick && onClick(star + 1);
          onHover && onHover(0);
        }}
        onMouseMove={() => {
          onHover && onHover(star + 1);
        }}
        onMouseLeave={() => {
          onHover && onHover(0);
          onClick && onClick(rating);
        }}
      />
    );
  });

  return <StarsWrapper hoveredStars={hoveredStars}>{stars}</StarsWrapper>;
};
