import { forwardRef, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import _ from 'lodash';
import { Nullable } from 'tsdef';
import { Flex, Avatar, UploadIcon } from '@beauty/beauty-market-ui';
import {
  setNewProfile,
  updateAccount,
  getProfile,
  uploadAvatar,
  deleteAvatar,
} from '../../../../../../helpers/profile';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectUser, updateProfile, updateUser } from '../../../../../../store/redux-slices/userSlice';
import { StyledButton } from '../../../../../../style';
import { ProfileType } from '../../../../../../types/user';
import { genders } from '../../../constants';
import {
  editProfileFormValidationSchema,
  setInitialProfileFormValues,
  ProfileForm,
  ProfileFormFields,
} from '../../../Profile.definitions';

import { ContactsForm } from './ContactsForm';
import { MainInformationForm } from './MainInformationForm';
import { OtherInformationForm } from './OtherInformationForm';

type EditProfileFormProps = {
  profile: ProfileType;
  onSubmit: () => void;
  setSubmitButtonActive: (isActive: boolean) => void;
  setUploadOpen: (isOpen: boolean) => void;
  setPhoto: (image?: Nullable<File>) => void;
  isEditMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  photo?: Nullable<File>;
};

export const EditProfileForm = memo(
  forwardRef((props: EditProfileFormProps, ref) => {
    const { t } = useTranslation();
    const {
      profile,
      onSubmit,
      setSubmitButtonActive,
      setUploadOpen,
      photo = undefined,
      setPhoto,
      isEditMode,
      setEditMode,
    } = props;

    const { user } = useAppSelector(selectUser);

    const [genderIndex, setGenderIndex] = useState(
      profile.gender ? genders.findIndex(item => item === profile.gender.toLowerCase()) : -1,
    );
    const [code, setCode] = useState(profile.code);
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(profile.avatarUrl);
    const [isAvatarUpdated, setAvatarUpdated] = useState(false);
    const [isInfoUpdated, setInfoUpdated] = useState(false);

    const dispatch = useAppDispatch();

    const handleAvatarUpdate = async (image?: Nullable<File>) => {
      if (!image) {
        deleteAvatar(user.userId);
        dispatch(updateUser({ isLogin: true, user: { ...user, avatarUrl: '' } }));
        dispatch(updateProfile({ ...profile, avatarUrl: '' }));
      } else {
        const formData = new FormData();

        try {
          formData.append('file', image);
          const response = await uploadAvatar(user.userId, formData);

          if (response.url) {
            dispatch(updateUser({ isLogin: true, user: { ...user, avatarUrl: response.url } }));
            dispatch(updateProfile({ ...profile, avatarUrl: response.url }));
          }
        } catch {
          console.log('Avatar was not upload');
        }
      }
    };

    const onFormSubmit = async (values: ProfileForm) => {
      onSubmit();

      if (isInfoUpdated) {
        const newProfile = setNewProfile(
          {
            ...values,
            gender: genderIndex >= 0 ? genders[genderIndex].toUpperCase() : '',
          },
          profile,
        );
        const status = await updateAccount(user.userId, newProfile);
        if (status === 200) {
          const profileInfo = await getProfile(user.userId);
          profileInfo && dispatch(updateProfile(profileInfo));

          dispatch(
            updateUser({
              ...user,
              user: {
                ...user,
                name: values.name,
                surname: values.surname,
                email: values[ProfileFormFields.Email],
                idNumber: values.idNumber,
              },
              isLogin: true,
            }),
          );
        }
      }
      isAvatarUpdated && handleAvatarUpdate(photo);
    };

    const formikContextValue = {
      initialValues: setInitialProfileFormValues(profile),
      validationSchema: editProfileFormValidationSchema(t),
      onSubmit: onFormSubmit,
      validateOnMount: true,
    };

    const action = (!isEditMode && !!profile.avatarUrl) || photo ? 'new' : 'upload';

    useEffect(() => {
      if (!isEditMode) setAvatarUrl(profile.avatarUrl);
      else if (photo) setAvatarUrl(photo && URL.createObjectURL(photo));
      else setAvatarUrl(undefined);
    }, [isEditMode, photo, profile.avatarUrl]);

    useEffect(() => {
      setAvatarUpdated(photo !== undefined);
    }, [photo]);

    return (
      <Formik innerRef={ref as (instance: FormikProps<ProfileForm> | null) => void} {...formikContextValue}>
        {({ isValid, initialValues, values }) => {
          setSubmitButtonActive(isValid && (isInfoUpdated || isAvatarUpdated)); // TODO Refactor set state
          useEffect(() => {
            setInfoUpdated(!_.isEqual(initialValues, values) || code !== profile.code);
          }, [values]);

          return (
            <Form>
              <Flex width="100%" flexDirection="column">
                <Flex flexDirection="column" gap="8px" mb="24px" alignItems="start" width="100px">
                  <Avatar
                    size="l"
                    url={avatarUrl}
                    deleteMode={avatarUrl}
                    onDelete={() => {
                      setEditMode(true);
                      setPhoto(null);
                    }}
                  />
                  <StyledButton
                    size="extraSmall"
                    design="tertiary"
                    prefix={<UploadIcon />}
                    onClick={() => {
                      setUploadOpen(true);
                      setEditMode(true);
                      setPhoto(undefined);
                    }}
                  >
                    {`${t(`uploadPhoto.${action}`)}${t('uploadPhoto.photo')}`}
                  </StyledButton>
                </Flex>
                <MainInformationForm genderIndex={genderIndex} setGenderIndex={setGenderIndex} />
                <ContactsForm
                  initialCode={profile.code}
                  setCode={setCode}
                  phone={profile.number}
                  email={profile.email}
                />
                <OtherInformationForm />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    );
  }),
);
