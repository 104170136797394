import { ReactNode } from 'react';
import { BottomSheet, getMinWidthMediaQuery, Sidebar, useMediaQuery } from '@beauty/beauty-market-ui';

type Props = {
  label: string;
  descriptor: string;
  FooterBody: ReactNode;
  children: ReactNode;
  onClose: () => void;
};

export const SidebarSheet = ({ children, ...rest }: Props) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  return isLarge ? (
    <Sidebar isOpen {...rest}>
      {children}
    </Sidebar>
  ) : (
    <BottomSheet isOpen {...rest} content={children} />
  );
};
