import styled, { css } from 'styled-components';
import { Flex, Caption, colors } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../constants';

export const PriceBlock = styled(Flex)`
  gap: 6px;
  align-items: baseline;
`;

export const OldPrice = styled(Caption)`
  text-decoration: line-through;
`;

export const ControlButtons = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

export const IconFlex = styled(Flex)<{ isSelected: boolean }>`
  svg {
    width: 24px;
    height: 24px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      svg path {
        stroke: ${colors.blue.standard};
      }
    `}

  @media screen and (min-width: 992px) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const ServiceBlock = styled(Flex)<{ isSelected: boolean }>`
  cursor: pointer;
  flex-direction: column;
  min-width: 64px;
  height: 46px;
  gap: 4px;
  align-items: center;
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${colors.blue.standard};
      border-bottom: 2px solid ${colors.blue.standard};
    `}
  :hover {
    border-bottom: 2px solid ${colors.blue.standard};
  }

  @media screen and (min-width: 992px) {
    height: 68px;
  }
  span {
    width: max-content;
  }
`;

export const SliderWrapper = styled(Flex)`
  & > div:first-child {
    width: 100%;
    max-width: 440px;
  }

  img {
    width: 100% !important;
  }
`;

export const Wrapper = styled(Flex)<{ isBackground: boolean }>`
  & > div {
    padding: 0 !important;
  }

  ${({ isBackground }) =>
    isBackground &&
    css`
      background-color: ${colors.white.standard};
      & > div {
        z-index: ${zIndex.searchDropdownWrapper - 1};
      }
    `}
`;
