import { memo, forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { Flex, H4, BodySmall } from '@beauty/beauty-market-ui';
import { transComponents } from '../../constants';

type PrivacyItemProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  infos: string[];
  id: string;
};

// TODO Refactor to enable eslint checks
export const PrivacyItem = memo(
  forwardRef((props: PrivacyItemProps, ref) => {
    const { title, infos, id } = props;
    return (
      <Flex mt="40px" flexDirection="column" ref={ref} pt="80px" mb="-80px" id={id}>
        <H4 mb="24px">{title}</H4>
        {infos.map((info, i) => (
          <BodySmall
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            mb={i === infos.length ? '0px' : '16px'}
          >
            <Trans components={transComponents}>{info}</Trans>
          </BodySmall>
        ))}
      </Flex>
    );
  }),
);
