import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouterUrl } from '../../routes/routes';
import { LinkStyled, BodyLargeStyled } from './style';

export const CookieModalContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <BodyLargeStyled mr="4px">{t('cookies.info')}</BodyLargeStyled>
      <LinkStyled href={RouterUrl.PrivacyPolicy} target="_blank">
        {t('cookies.page')}
      </LinkStyled>
    </>
  );
};
