import { useState } from 'react';
import { Link } from 'react-scroll';
import { TagButton, Slider } from '@beauty/beauty-market-ui';
import { CategoryType } from '../../../types';

interface ServicesCategorizerProps {
  categories: CategoryType[];
}

const ServicesCategorizer = ({ categories }: ServicesCategorizerProps) => {
  const [activeTag, setActiveTag] = useState<number>(0);

  const slides = categories.map((category, index) => {
    const design = index === activeTag ? 'active' : 'default';
    return (
      <Link containerId="services" to={`category-${category.id}`} smooth duration={250} key={category.id}>
        <TagButton
          onClick={() => setActiveTag(index)}
          text={category.name}
          number={category.offersCount}
          design={design}
        />
      </Link>
    );
  });

  return <Slider inlineSlider slides={slides} marginBottom="40px" />;
};

export default ServicesCategorizer;
