import { useToggle } from '../../../hooks/useToggle';
import { FavouritePopup } from '../../FavouritePopup';
import { ShareOrganisationModal } from '../ShareOrganisation/ShareOrganisationModal';
import { StyledPromo } from '../style';

export const OrganisationCard = ({
  id,
  image,
  headline,
  title,
  rating,
  reviews,
  badge,
  photos,
  href,
  isBlueHover,
  isBlank,
}) => {
  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const [isFavouriteModalOpen, setIsFavouriteModalOpen] = useToggle();

  const isStub = id === '0';

  return (
    <>
      <StyledPromo
        key={id}
        title={title}
        image={image}
        badge={badge}
        headline={headline}
        reviews={reviews}
        photos={photos}
        rating={rating}
        isBlank={isBlank}
        isBlueHover={isBlueHover}
        href={isStub ? undefined : href}
        onShareClick={isStub ? undefined : setShareModalOpen}
        onFavouriteClick={isStub ? undefined : setIsFavouriteModalOpen}
      />
      {isShareModalOpen && (
        <ShareOrganisationModal
          handelModalClose={setShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          organisationId={id}
        />
      )}
      {isFavouriteModalOpen && <FavouritePopup isOpen handlePopupClose={setIsFavouriteModalOpen} />}
    </>
  );
};
