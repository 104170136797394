import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailReg, passwordReg } from '../../../constants';

// email
export enum EmailField {
  Email = 'email',
}

export type EmailForm = {
  [EmailField.Email]: string;
  language: string;
};

export const initialEmailValue: EmailForm = {
  [EmailField.Email]: '',
  language: 'EN',
};

export const emailSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailField.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

// reset password
export enum ResetPasswordField {
  Password = 'password',
}

export type ResetPasswordForm = {
  [ResetPasswordField.Password]: string;
};

export const initialResetPasswordValue: ResetPasswordForm = {
  [ResetPasswordField.Password]: '',
};

export const resetPasswordSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [ResetPasswordField.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });
