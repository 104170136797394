import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TonConnectButton } from '@tonconnect/ui-react';
import { ArrowLeftIcon, Avatar, calendarIcons, Flex, Link } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, ThunkUser } from '../../store/redux-slices/userSlice';
import { clientMenu } from './helpers';
import Menu from './Menu';
import MenuItem from './MenuItem';
import { HeaderWrapperNew, StyledTonButton } from './style';

const { MarketPlaceBooking } = calendarIcons;

const Header = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userApp = useAppSelector(selectUser);
  const userTelegram = window.Telegram.WebApp.initDataUnsafe;
  const userName = userTelegram?.user?.username ?? '';

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userTelegram.user?.id) {
      dispatch(ThunkUser.fetchAccountByTelegramId(userTelegram.user.id));
    }
  }, [userTelegram?.user?.id]);

  return (
    <HeaderWrapperNew>
      <Flex alignItems="center" gap="8px">
        {location.pathname !== RouterUrl.AllOffers && location.pathname !== RouterUrl.Root && (
          <ArrowLeftIcon height="16" width="16" onClick={() => navigate(-1)} />
        )}
        <MarketPlaceBooking height="24" width="24" />
        <Link design="black" size="xs">
          {userApp.client?.coins ?? '0'}
        </Link>
      </Flex>
      <Flex alignItems="center" gap="4px">
        <Flex alignItems="center" gap="4px" onClick={() => setOpen(true)}>
          <Avatar size="xxs" url={userTelegram?.user?.photo_url} name={userName} />
          <Link design="black" size="xs">
            {userName}
          </Link>
        </Flex>
        <Menu open={open} onChange={setOpen}>
          <StyledTonButton onClick={e => e.stopPropagation()}>
            Wallet
            <TonConnectButton style={{ display: 'none' }} />
          </StyledTonButton>
          {clientMenu(userApp?.client?.id).map(item => (
            <MenuItem
              key={item.item}
              title={item.item}
              disabled={item.disabled}
              selected={item?.link === location.pathname}
              onClick={() => {
                if (item.link !== location.pathname) {
                  item.link && navigate(item.link);
                  setOpen(false);
                }
              }}
            />
          ))}
        </Menu>
      </Flex>
    </HeaderWrapperNew>
  );
};

export default Header;
