import { memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, H4 } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper } from '../../../style';

export const Jurisdiction = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <H4 mb="24px">{t('termsOfUse.jurisdiction.title')}</H4>
        <BodySmall mb="24px">{t('termsOfUse.jurisdiction.article1')}</BodySmall>
      </PrivacyItemWrapper>
    );
  }),
);
