export interface FAQQuestion {
  id: string;
  title: string;
  answer: string | JSX.Element;
}

export interface FAQSlideType {
  id: string;
  name: string;
  count: number;
}

export interface FAQCardProps {
  image: string;
  title: string;
  description: string;
  link: string;
}

export enum FAQQuestionsVariants {
  Client = 'client',
  Business = 'business',
}

export enum FaqQuestionsCategories {
  About = 'about',
  Account = 'account',
  Profile = 'profile',
  Appointments = 'appointments',
  Reviews = 'reviews',
  GetStarted = 'getStarted',
  OrganisationAccount = 'organisationAccount',
  Calendar = 'calendar',
}

export interface FAQCategoryObj {
  id: string;
  category: FaqQuestionsCategories;
  questions: FAQQuestion[];
}
