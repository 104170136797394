import { H3, BodyLarge } from '@beauty/beauty-market-ui';
import { InfoWrapper, ManagingCardWrapper } from '../style';

type ManagingCardProps = {
  imageUrl: string;
  title: string;
  info: string;
};

export const ManagingCard = ({ imageUrl, title, info }: ManagingCardProps) => (
  <ManagingCardWrapper>
    <img src={imageUrl} alt="managingImage" />

    <InfoWrapper>
      <H3 mt={['8px', '8px', '8px', '0px']} mb="16px">
        {title}
      </H3>
      <BodyLarge>{info}</BodyLarge>
    </InfoWrapper>
  </ManagingCardWrapper>
);
