import { Wrapper, Layout, Content, RightBlock } from './style';

interface BookingPopupProps {
  primaryButton: JSX.Element;
  secondaryButton: JSX.Element;
  content?: JSX.Element | null;
  disabled?: boolean;
}

const BookingPopup = ({ primaryButton, secondaryButton, content, disabled }: BookingPopupProps) => (
  <Wrapper disabled={disabled}>
    <Layout>
      <Content>
        {secondaryButton}
        <RightBlock>
          {content}
          {primaryButton}
        </RightBlock>
      </Content>
    </Layout>
  </Wrapper>
);

export default BookingPopup;
