import { useNavigate } from 'react-router-dom';
import { H6, Flex, BodySmall, RightIcon } from '@beauty/beauty-market-ui';
import { FAQCardWrapper, FAQChevronWrapper } from '../style';
import { FAQCardProps } from '../types';

const FAQCard = ({ image, title, description, link }: FAQCardProps) => {
  const navigate = useNavigate();
  return (
    <FAQCardWrapper onClick={() => navigate(link)}>
      <img src={image} alt="" />
      <Flex justifyContent="space-between">
        <Flex flexDirection="column" maxWidth="406px">
          <H6>{title}</H6>
          <BodySmall>{description}</BodySmall>
        </Flex>
        <FAQChevronWrapper>
          <RightIcon width="20px" height="20px" />
        </FAQChevronWrapper>
      </Flex>
    </FAQCardWrapper>
  );
};

export default FAQCard;
