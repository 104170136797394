import { EventStatus } from '../page/Client/Appointments/constants';
import { FullStatus } from '../page/Client/Appointments/types';
import { TextWithTranslations } from './general';

export type TextType = {
  id: string;
  langId: string;
  text: string;
  translation: string[];
};

export type HeadOrganizationType = {
  id: string;
  nameTid: string;
  summaryTid: string;
  currency: string;
  minTimeSlot: number;
  name: TextType;
  summary: TextType;
};

type AddressInfo = {
  country: string;
  city: string;
  street: string;
  building: string;
  office: string;
  postal: string;
  fullAddress: string;
};

export type AddressType = {
  id: string;
  address: AddressInfo[];
};

export type OrganisationType = {
  id: string;
  name: string;
  headOrgId: string;
  label: null;
  contactId: string;
  addressId: string;
  primary: true;
  timezone: string;
  address: AddressType;
  headOrganization: HeadOrganizationType;
  orgName?: TextWithTranslations;
};

export type TitleType = {
  id: string;
  langId: string;
  text: string;
  translation: [
    {
      id: string;
      tid: string;
      langId: string;
      text: string;
    },
    {
      id: string;
      tid: string;
      langId: string;
      text: string;
    },
  ];
};

export type HeadOrganizationServiceType = {
  id: string;
  headOrgId: string;
  categoryId: string;
  titleTid: string;
  descrTid: string;
  duration: number;
  listPrice: number;
  title: TitleType;
  descr: TitleType;
};

export type OrganisationServiceType = {
  id: string;
  orgId: string;
  headOrgServId: string;
  price: number;
  organization: OrganisationType;
  headOrgService: HeadOrganizationServiceType;
};

export type AppointmentType = {
  id: string;
  orgSpecId: string;
  orgServId: string;
  start: string;
  end: string;
  isReviewed: boolean;
  clientId: string;
  status: EventStatus;
  createdAt: string;
  updatedAt: string;
  orgService: OrganisationServiceType;
};

export type CreateAppointmentParamsType = {
  orgServId: string;
  start: string;
  orgSpecId?: string | null;
  clientId?: string;
  cardId?: string;
  saveCard?: boolean;
  paymentMethod?: string;
  clientData?: {
    name: string;
    email?: string;
    number: string;
    code: string;
    idNumber?: string;
  };
};

export enum ActionTypes {
  CREATE = 'create',
  CANCEL = 'cancel',
  UPDATE = 'update',
  CreateByGuest = 'createByGuest',
}

export type PatchAppointmentType = {
  orgSpecId: string;
  orgServId: string;
  start: string;
  end: string;
  status: FullStatus;
};
