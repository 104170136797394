import { useTranslation } from 'react-i18next';
import { BodyLarge, H3, Button, Flex } from '@beauty/beauty-market-ui';

type DeletingAccountProps = {
  onDeleteAccountClick: (isDeleteAccountFormOpen: boolean) => void;
};

const DeletingAccount = ({ onDeleteAccountClick }: DeletingAccountProps) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" mb="60px">
      <H3 mb="8px">{t('profile.deletingAccount')}</H3>
      <BodyLarge large mb="16px">
        {t('profile.areYouSure')}
      </BodyLarge>
      <Button design="quaternary" onClick={() => onDeleteAccountClick(true)}>
        {t('profile.deleteAccount')}
      </Button>
    </Flex>
  );
};

export { DeletingAccount };
