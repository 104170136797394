import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex, Sidebar, useMediaQuery, BottomSheet, getMinWidthMediaQuery, Button } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../../assets/info-image.png';
import { LoginSmsCodeForm } from '../../../../Login/Login.definitions';
import { SendSmsCodeForm } from '../Forms/SendSmsCodeForm';

export type SmsCodeSidebarProps = {
  code: string;
  phone: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SendSmsCodeSidebar = ({ code, phone, isOpen, setIsOpen }: SmsCodeSidebarProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<LoginSmsCodeForm>>();
  const [isSubmitActive, setSubmitActive] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setIsOpen(false);
  };

  const smsCodeForm = (
    <SendSmsCodeForm
      ref={formRef}
      newCode={code}
      newNumber={phone}
      onClose={handleClose}
      setSubmitActive={setSubmitActive}
    />
  );

  const content = (
    <Flex flexDirection="column" width="100%">
      {smsCodeForm}
    </Flex>
  );

  const FooterBody = (
    <Flex width="100%" flexDirection={['row', 'row', 'row', 'column']}>
      <Button
        disabled={!isSubmitActive}
        design="primary"
        mt="16px"
        width="100%"
        size="large"
        type="submit"
        onClick={() => {
          formRef.current && formRef.current.handleSubmit();
        }}
      >
        {t(`button.continue`)}
      </Button>
      <Button design="secondary" mt="16px" width="100%" size="large" onClick={handleClose}>
        {t(`button.cancel`)}
      </Button>
    </Flex>
  );

  const generalProps = {
    isOpen,
    onClose: handleClose,
    label: t('registration.smsCode'),
    descriptor: t('profile.newPhoneDescription'),
    FooterBody,
  };

  return isLarge ? (
    <Sidebar {...generalProps}>
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" height="100%">
        <Flex pt="94px">
          <img src={InfoIcon} alt="" />
        </Flex>
        {smsCodeForm}
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};
