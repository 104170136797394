import styled from 'styled-components';
import { Div, Flex } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';
import Container from '../Container';

export const Wrapper = styled(Div)<{ disabled: boolean }>`
  ${({ disabled }) => `z-index: ${disabled ? zIndex.bookingPopupDisabled : zIndex.bookingPopupActive};`}

  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.08));
`;

export const Layout = styled(Div)`
  height: inherit;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled(Container)`
  height: inherit;
  justify-content: space-between;
  align-items: center;
`;

export const RightBlock = styled(Flex)`
  width: 70%;
  gap: 36px;
  justify-content: flex-end;
`;
