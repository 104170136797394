import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailReg, passwordReg } from '../../constants';

// log in by phone
export enum LoginByPhoneFields {
  Number = 'number',
  Region = 'code',
}

export type LoginByPhoneForm = {
  [LoginByPhoneFields.Number]: string;
};

export const initialByPhoneValues: LoginByPhoneForm = {
  [LoginByPhoneFields.Number]: '',
};

export const loginPhoneFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginByPhoneFields.Number]: Yup.string()
      .typeError(t('validation.wrongPhone'))
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.phoneRequired')),
  });

// log in by email
export enum LoginByEmailFields {
  Email = 'email',
  Password = 'password',
}

export type LoginByEmailForm = {
  [LoginByEmailFields.Email]: string;
  [LoginByEmailFields.Password]: string;
};

export const initialByEmailValues: LoginByEmailForm = {
  [LoginByEmailFields.Email]: '',
  [LoginByEmailFields.Password]: '',
};

export const loginEmailFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginByEmailFields.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [LoginByEmailFields.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });

// sms code form
export enum LoginFormSmsCodeFields {
  SMSCode = 'smscode',
}

export type LoginSmsCodeForm = {
  [LoginFormSmsCodeFields.SMSCode]: string;
};

export const initialSmsCodeValues: LoginSmsCodeForm = {
  [LoginFormSmsCodeFields.SMSCode]: '',
};

export const loginSMSCodeFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginFormSmsCodeFields.SMSCode]: Yup.string()
      .required(t('validation.notEmpty'))
      .matches(/^\d{6}$/, t('validation.smsCode')),
  });

// email
export enum EmailField {
  Email = 'email',
}

export type EmailForm = {
  [EmailField.Email]: string;
};

export const initialEmailValue: EmailForm = {
  [EmailField.Email]: '',
};

export const emailSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailField.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

export const checkEmailSchema = (message: string, t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailField.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

// reset password
export enum ResetPasswordField {
  Password = 'password',
}

export type ResetPasswordForm = {
  [ResetPasswordField.Password]: string;
};

export const initialResetPasswordValues: ResetPasswordForm = {
  [ResetPasswordField.Password]: '',
};

export const resetPasswordSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [ResetPasswordField.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });
