import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { H1, BodySmall } from '@beauty/beauty-market-ui';
import SMSCodeForm from './SMSCodeForm';

const LoginSMS = () => {
  const { t } = useTranslation();
  const { region, phone } = useLocation().state;

  return (
    <>
      <H1 mb="16px">{t('registration.smsCode')}</H1>
      <BodySmall mb="32px">
        {t('login.enterSMSCode')} <br /> {`${region} ${phone}`}
      </BodySmall>
      <SMSCodeForm />
    </>
  );
};

export default LoginSMS;
