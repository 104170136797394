import { Nullable } from 'tsdef';

export enum SpecialistsRoles {
  MANAGER = 'MANAGER',
  SPECIALIST = 'SPECIALIST',
}

type SpecialistsRolesKeys = keyof typeof SpecialistsRoles;
export type SpecialistsRolesValues = (typeof SpecialistsRoles)[SpecialistsRolesKeys];

export type SpecialistType = {
  id: string;
  name: string;
  specialization: string;
  rating: Nullable<number>;
  surname?: string;
  avatarUrl?: string;
  description?: string;
  role?: SpecialistsRolesValues;
  published?: boolean;
  selected?: boolean;
};

export type OrgSpecialistType = {
  orgSpecId: string;
  name: string;
  surname: string;
  avatarUrl: string;
  specialization: string;
  rating: Nullable<number>;
  role?: SpecialistsRolesValues;
};
