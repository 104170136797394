import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { range } from 'lodash';
import {
  Button,
  Sidebar,
  BottomSheet,
  Flex,
  useMediaQuery,
  getMinWidthMediaQuery,
  Loader,
} from '@beauty/beauty-market-ui';
import { MAX_MARK } from '../../constants';
import { useStepper } from '../../hooks/useStepper';
import { useAppDispatch } from '../../store/hooks';
import { setActiveNotificationId, setForceFetch } from '../../store/redux-slices/userSlice';
import { OrgAddressType } from '../../types/organisation';
import { OrgSpecialistType } from '../../types/specialist';
import { FeedbackForm } from './FeedbackForm';
import { ResultInfo } from './ResultInfo';
import { SidebarBody } from './SidebarBody';
import { ColumnFlex } from './style';

export type RatingSidebarProps = {
  appointmentId: string;
  date: string;
  address: OrgAddressType;
  specialist?: OrgSpecialistType;
  setOpen?: (isOpen: boolean) => void;
};

export const nullBadges = (count: number) =>
  range(0, count).reduce((state, cur) => {
    state[cur] = false;
    return state;
  }, {});

export const RatingSidebar = ({ appointmentId, date, address, specialist, setOpen }: RatingSidebarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const [stars, setStars] = useState(0);
  const [orgScore, setOrgScore] = useState(0);
  const [specScore, setSpecScore] = useState(0);
  const [hoveredStars, setHoveredStars] = useState(0);
  const [valid, setValid] = useState(false);
  const [responseStatus, setResponseStatus] = useState<string | null>(null);
  const [secondsLeft, setSecondsLeft] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const formRef = useRef<FormikProps<{ feedback: string }>>(null);

  const title = useMemo(
    () => ({
      1: t('rating.evaluateSpecialist'),
      2: t('rating.evaluateSalon'),
      3: t('rating.feedback'),
    }),
    [t],
  );

  const {
    step,
    StepperComponent,
    handleReset,
    handleNextStep,
    specialistCards,
    salonCards,
    specBadges,
    setSpecBadges,
    salonBadges,
    setSalonBadges,
    isLoadingCards,
  } = useStepper({
    title,
    count: specialist ? 3 : 2,
    isSpecialist: !!specialist,
  });

  const cards = useMemo(() => (step === 1 ? specialistCards : salonCards), [step, specialistCards, salonCards]);

  const [badges, setBadges] = useState(cards ? nullBadges(cards[stars]?.length) : nullBadges(MAX_MARK));

  const handleClick = () => {
    switch (step) {
      case 1:
        setSpecBadges(badges);
        setSpecScore(stars);
        break;
      case 2:
        setSalonBadges(badges);
        setOrgScore(stars);
        break;
      case 3:
        formRef.current?.handleSubmit();
        break;
      case 4:
        dispatch(setActiveNotificationId(null));
        dispatch(setForceFetch(true));
        setOpen && setOpen(false);
        break;
      default:
        setStars(0);
    }
    handleNextStep();
    setStars(0);
  };

  useEffect(() => {
    for (let i = 0; i < hoveredStars; i += 1) {
      document.getElementById(`star${i + 1}`)?.classList.add('hovered');
    }
    for (let i = hoveredStars; i < MAX_MARK; i += 1) {
      document.getElementById(`star${i + 1}`)?.classList.remove('hovered');
    }
  }, [hoveredStars]);

  useEffect(() => {
    setBadges(cards ? nullBadges(cards[stars]?.length) : nullBadges(MAX_MARK));
  }, [stars, cards, step]);

  const content = (
    <ColumnFlex>
      {step < 4 && StepperComponent}
      {(() => {
        switch (step) {
          case 3:
            return (
              <FeedbackForm
                ref={formRef}
                appointmentId={appointmentId}
                orgScore={orgScore}
                specScore={specScore}
                salonCards={salonCards[orgScore]}
                specialistCards={specialistCards[specScore]}
                salonBadges={salonBadges}
                specBadges={specBadges}
                setLoading={setLoading}
                setSecondsLeft={setSecondsLeft}
                setResponseStatus={setResponseStatus}
                setValid={setValid}
              />
            );
          case 4:
            return !isLoading ? (
              <ResultInfo responseStatus={responseStatus} />
            ) : (
              <Flex alignItems="center" justifyContent="center" mt="50%">
                <Loader />
              </Flex>
            );
          default:
            return (
              <SidebarBody
                step={step}
                title={title}
                address={address}
                specialist={specialist}
                stars={stars}
                setStars={setStars}
                cards={cards}
                hoveredStars={hoveredStars}
                setHoveredStars={setHoveredStars}
                isLoadingCards={isLoadingCards}
                badges={badges}
                setBadges={setBadges}
              />
            );
        }
      })()}
    </ColumnFlex>
  );

  const buttonLabel = (() => {
    if (step < 3) return t('button.next');
    if (step === 3) return t('button.sendReview');
    return `${t(`button.close`)} ${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ''}`;
  })();

  const footer = (
    <Button
      disabled={(() => {
        if (step <= 2) return !stars;
        if (stars < 4) return !valid;
        return false;
      })()}
      design="primary"
      width="100%"
      size="large"
      onClick={handleClick}
    >
      {buttonLabel}
    </Button>
  );

  const generalProps = {
    isOpen: true,
    onClose: () => {
      dispatch(setActiveNotificationId(null));
      setOpen && setOpen(false);
      handleReset();
    },
    label: t('rating.rateAppointment'),
    descriptor: `${date}, ${address.info}`,
    FooterBody: footer,
  };

  useEffect(() => {
    if (!secondsLeft) {
      dispatch(setActiveNotificationId(null));
      setOpen && setOpen(false);
    }
  }, [secondsLeft]);

  return isMobile ? (
    <BottomSheet {...generalProps} content={content} />
  ) : (
    <Sidebar {...generalProps}>{content}</Sidebar>
  );
};
