import * as Yup from 'yup';

export enum AddCardFormFields {
  CardNumber = 'cardNumber',
  Name = 'nameAndSurname',
  Expires = 'expireDate',
  CVV = 'cvv',
}

export type AddCardFormType = {
  [AddCardFormFields.CardNumber]: string;
  [AddCardFormFields.Name]: string;
  [AddCardFormFields.Expires]: string;
  [AddCardFormFields.CVV]: string;
};

export const initialAddCardFormValues: AddCardFormType = {
  [AddCardFormFields.CardNumber]: '',
  [AddCardFormFields.Name]: '',
  [AddCardFormFields.Expires]: '',
  [AddCardFormFields.CVV]: '',
};

export const EditAddCardFormValidationSchema = Yup.object({
  [AddCardFormFields.CardNumber]: Yup.string()
    .matches(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
      'Credit Card number is invalid. Only digits are permitted',
    )
    .required('Field cannot be empty'),
  [AddCardFormFields.Name]: Yup.string()
    .matches(/^[a-zA-Z]{1,20}\s[a-zA-Z]{1,20}$/, 'Enter valid Name & Surname')
    .required('Field cannot be empty'),
  [AddCardFormFields.Expires]: Yup.string()
    .matches(/0[1-9]|1[0-2]\/[0-9]{2}/, 'Must match mm/yy pattern')
    .required('Field cannot be empty'),
  [AddCardFormFields.CVV]: Yup.string()
    .matches(/^[0-9]{3}$/, 'only 3 digits are possible')
    .required('Field cannot be empty'),
});
