import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, TagButton } from '@beauty/beauty-market-ui';
import { getFaqCategories } from '../../../helpers/faq';
import { SliderWrapper } from '../style';
import { FAQCategoryObj } from '../types';

type FAQCategoriesProps = {
  categories: FAQCategoryObj[];
  activeCategoryId: string;
  setActiveCategoryId: (category: string) => void;
};

export const FAQCategories = ({ categories, activeCategoryId, setActiveCategoryId }: FAQCategoriesProps) => {
  const { t } = useTranslation();
  const slides = useMemo(() => {
    const tabCategories = getFaqCategories(categories, t);

    return tabCategories.map(category => {
      const design = category.id === activeCategoryId ? 'active' : 'default';
      return (
        <TagButton
          onClick={() => setActiveCategoryId(`${category.id}`)}
          text={category.name}
          number={category.count}
          key={category.id}
          design={design}
        />
      );
    });
  }, [categories, activeCategoryId]);

  return (
    <SliderWrapper>
      <Slider inlineSlider slides={slides} />
    </SliderWrapper>
  );
};
