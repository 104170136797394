import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Button } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../../../../components';
import { ChangePasswordForm } from '../../Forms/ChangePasswordForm';
import { ChangePasswordFormType } from './ChangePassword.definitions';

export const ChangePasswordSidebar = ({ onClose }) => {
  const { t } = useTranslation();

  const [isDisabled, setDisabled] = useState(true);

  const formRef = useRef<FormikProps<ChangePasswordFormType>>();

  return (
    <SidebarSheet
      label={t('profile.password.changePassword')}
      descriptor={t('profile.password.writeOldAndNewPassword')}
      onClose={onClose}
      FooterBody={
        <Button size="large" type="submit" disabled={isDisabled} onClick={formRef.current?.handleSubmit} width="100%">
          {t('profile.password.updatePassword')}
        </Button>
      }
    >
      <ChangePasswordForm ref={formRef} onClose={onClose} onDisable={setDisabled} />
    </SidebarSheet>
  );
};
