import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { UserEndpoints } from '../api/endpoints/endpoints';
import { useAppDispatch } from '../store/hooks';
import { writeAppointments } from '../store/redux-slices/appointmentsSlice';
import { setForceFetch } from '../store/redux-slices/userSlice';

export const useGetAppointments = (userId?: string, isFetch?: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isAppointmentsLoading = true;

    const fetchAppointments = async () => {
      setIsLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: UserEndpoints.Appointments,
        endpointParams: userId,
      });

      if (isAppointmentsLoading) {
        dispatch(writeAppointments({ data: !error ? response?.data : [] }));
        setIsLoading(false);
      }
    };

    if (userId || isFetch) fetchAppointments();
    else dispatch(writeAppointments({ data: [] }));
    dispatch(setForceFetch(false));

    return () => {
      isAppointmentsLoading = false;
    };
  }, [userId, isFetch]);

  return {
    isLoading,
  };
};
