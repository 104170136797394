import styled from 'styled-components';
import { Flex, Link, BodyLarge, mediaQueries } from '@beauty/beauty-market-ui';

export const StyledButton = styled(Flex)`
  gap: 16px;
  ${mediaQueries.md} {
    justify-content: flex-end;
    margin-left: auto;
  }
`;

export const LinkStyled = styled(Link)`
  display: inline !important;
`;

export const BodyLargeStyled = styled(BodyLarge)`
  display: inline !important;
`;
