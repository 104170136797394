import { useEffect, useState } from 'react';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { NotificationEndpoints } from '../api/endpoints/endpoints';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectUser, setNotifications } from '../store/redux-slices/userSlice';
import {
  NotificationStatus,
  NotificationsResponse,
  Notification,
  RenderNotificationsType,
} from '../types/notifications';

export const useGetNotifications = (userId: string, isFetch?: boolean) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nots, setNots] = useState<Nullable<RenderNotificationsType>>(null);
  const dispatch = useAppDispatch();
  const { activeNotificationId } = useAppSelector(selectUser);

  useEffect(() => {
    let isNotificationsLoading = true;

    const fetchNotifications = async () => {
      setIsLoading(true);
      const [, responseNew] = await apiRequest.get({
        endpoint: NotificationEndpoints.Notifications,
        endpointParams: { profileId: userId },
      });
      const [, responseArchive] = await apiRequest.get({
        endpoint: NotificationEndpoints.Notifications,
        endpointParams: { profileId: userId, isArchive: true },
      });

      if (isNotificationsLoading) {
        if (responseNew?.status === 200 && responseArchive?.status === 200) {
          const newNotifications: Notification[] = (responseNew.data as unknown as NotificationsResponse)?.map(
            notification => {
              const {
                id,
                appointmentId,
                type,
                createdAt: time,
                status,
                specialist,
                specAvatar,
                specialization,
                organization,
                orgAvatar,
                address,
              } = notification;
              return {
                id,
                appointmentId,
                type,
                time,
                status,
                isRead: status === NotificationStatus.VIEWED,
                specialist,
                specAvatar,
                specialization,
                organization,
                orgAvatar,
                fullAddress: address.fullAddress,
              };
            },
          );

          const archiveNotifications: Notification[] = (responseArchive.data as unknown as NotificationsResponse)?.map(
            notification => {
              const {
                id,
                appointmentId,
                type,
                createdAt: time,
                status,
                specialist,
                specAvatar,
                specialization,
                organization,
                orgAvatar,
                address,
              } = notification;
              return {
                id,
                appointmentId,
                type,
                time,
                status,
                isRead: status === NotificationStatus.VIEWED,
                specialist,
                specAvatar,
                specialization,
                organization,
                orgAvatar,
                fullAddress: address.fullAddress,
              };
            },
          );

          setNots({ new: newNotifications, archive: archiveNotifications });
          dispatch(setNotifications({ new: newNotifications, archive: archiveNotifications }));
          setIsLoading(false);
        }
      }
    };

    (!activeNotificationId || isFetch) && !!userId && fetchNotifications();

    return () => {
      isNotificationsLoading = false;
    };
  }, [userId, activeNotificationId, isFetch]);

  return {
    isLoading,
    nots,
  };
};
