import { BodyLarge } from '@beauty/beauty-market-ui';
import { ClusterMarkerWrapper } from './style';

interface ClusterMarkerProps {
  zoom: number;
  markersNumber: number;
  lat?: number;
  lng?: number;
  zIndex?: number;
  onClick?: () => void;
}

const ClusterMarker = ({ zoom, markersNumber, onClick }: ClusterMarkerProps) => (
  <ClusterMarkerWrapper zoom={zoom} onClick={onClick}>
    <BodyLarge lowline>{markersNumber}</BodyLarge>
  </ClusterMarkerWrapper>
);

export default ClusterMarker;
