import { useTranslation } from 'react-i18next';
import { Flex, H2, H4, H3, BodySmall, BodyLarge, Separator, Div } from '@beauty/beauty-market-ui';
import { SendMessageForm } from './SendMessageForm';
import { StyledContainer, StyledFlex } from './style';

export const JoinBusiness = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer mt="72px" flexDirection="column">
      <StyledFlex>
        <H2 mb="24px" width="100%">
          {t('joinBusiness.title')}
        </H2>
        <Flex width="100%" flexDirection="column">
          <BodyLarge mb="8px">{t('contactUs.info')}</BodyLarge>
          <H4>test@beautymarket.co.il</H4>
        </Flex>
      </StyledFlex>
      <Div mb="86px">
        <Separator />
      </Div>
      <StyledFlex>
        <Flex width="100%" flexDirection="column">
          <BodySmall mb="8px">{t('contactUs.hint')}</BodySmall>
          <H3 mb="24px">{t('contactUs.sendMessage')}</H3>
        </Flex>
        <SendMessageForm />
      </StyledFlex>
    </StyledContainer>
  );
};
