import { t } from 'i18next';
import { Nullable } from 'tsdef';
import { Button } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { GeolocationType } from '../../../types/general';
import { SendEmailFormFields } from './components/Appointments.definitions';
import { TodoType, BodyButtonProps, FullStatus } from './types';

export type OrganisationInfo = {
  name: string;
  address: string[];
  icon: string;
  category: string;
  coordinates: Nullable<GeolocationType>;
};

export const buttons = {
  filled: {
    first: 'edit',
    second: 'download',
  },
  nonFilled: {
    first: 'startFilling',
  },
};

export const popupBody = {
  cancel: {
    title: 'Are you sure you want to cancel appointment?',
    leftButton: 'no',
    rightButton: 'yes',
  },
  manage: {
    title: 'What do you want to change your appointment?',
    cancelled: {
      topButton: 'rebookAppointment',
    },
    confirmed: {
      topButton: 'rescheduleAppointment',
      bottomButton: 'addToCalendar',
    },
    past: {
      topButton: 'rebookAppointment',
      middleButton: 'writeReview',
      bottomButton: 'sendReceipt',
    },
  },
};

export enum EventStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  PAST = 'PAST',
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  NOSHOW = 'NO_SHOW',
  UNCLOSED = 'UNCLOSED',
}

export const statusList: FullStatus[] = [
  FullStatus.ALL,
  FullStatus.CONFIRMED,
  FullStatus.PAST,
  FullStatus.WAITING,
  FullStatus.CANCELLED,
];
export const confirmedList = [EventStatus.CONFIRMED, EventStatus.PENDING, EventStatus.INPROGRESS];

export const ratings = [
  {
    title: 'Professionalism',
    rating: 2,
  },
  {
    title: 'Punctuality',
    rating: 4,
  },
  {
    title: 'Answer questions',
    rating: 4,
  },
  {
    title: 'Explains conditions',
    rating: 6,
  },
  {
    title: 'Attitude',
    rating: 4,
  },
];

export const popupTitle = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return `${t('docs.cancel')} ${t('appointments.appointment')}`;
    case 'manage':
      return `${t('docs.manage')} ${t('appointments.event')}`;
    default:
      return '';
  }
};

export const popupInfo = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return t('appointments.cancelAppointment');
    case 'manage':
      return t('appointments.manageAppointment');
    default:
      return '';
  }
};

export const bottomSheetLabel = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return `${t('docs.cancel')} ${t('appointments.appointment')}`;
    case 'manage':
      return `${t('docs.manage')} ${t('appointments.appointment')}`;
    case 'send':
      return t(`appointments.${popupBody.manage.past.bottomButton}`);
    default:
      return '';
  }
};

export const bottomSheetDescriptor = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return t('appointments.cancelAppointment');
    case 'manage':
      return t('appointments.manageAppointment');
    case 'send':
      return t(`appointments.${popupBody.manage.past.bottomButton}`);
    default:
      return '';
  }
};

export const BodyButton = ({ label, onClick, disabled = false, ...other }: BodyButtonProps) => (
  <Button disabled={disabled} width="100%" size="large" design="quaternary" mb="16px" onClick={onClick} {...other}>
    {label}
  </Button>
);

export const SendEmailInput = () => (
  <FormikInput
    design="white"
    id={SendEmailFormFields.Email}
    name={SendEmailFormFields.Email}
    placeholder={t('profile.email')}
    defaultValue=""
    mb="16px"
  />
);
