import styled from 'styled-components';
import { Flex, mediaQueries, colors } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';

export const Card = styled(Flex)`
  z-index: ${zIndex.benefitCard} !important;
  box-sizing: border-box;
  flex-direction: column;
  height: 360px;
  width: 327px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);

  ${mediaQueries.md} {
    max-width: 343px;
    width: 100%;
    height: 417px;
  }
`;

export const StarIconWrapper = styled(Flex)`
  justify-content: center;
  gap: 7px;
`;

export const InfoWrapper = styled(Flex)`
  gap: 8px;
  height: 160px;
  padding: 24px 16px 0px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white.standard};

  ${mediaQueries.md} {
    height: 197px;
  }
`;

export const ImgWrapper = styled(Flex)<{ imgUrl: string; isRight: boolean }>`
  height: 200px;
  ${({ imgUrl }) => `background-image:url(${imgUrl});`}
  ${({ isRight }) => `background-position: ${isRight ? 'right' : 'center'};`}
  background-size: cover;
  background-repeat: no-repeat;

  ${mediaQueries.md} {
    height: 220px;
  }
`;
