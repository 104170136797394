import { memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { PAYING_SECTIONS_BODY_PARTS_COUNT, PAYING_SECTIONS_COUNT } from '../constants';
import { Section } from './Section';

// TODO Refactor to enable eslint checks
export const PayingForPartners = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.payingForPartners.title')}</PrivacyItemTitleWrapper>
        {Array(PAYING_SECTIONS_COUNT)
          .fill(0)
          .map((__, index) => (
            <Section
              // eslint-disable-next-line react/no-array-index-key
              key={index + 1}
              title={t(`termsOfService.payingForPartners.section${index + 1}.title`)}
              body={Array(PAYING_SECTIONS_BODY_PARTS_COUNT[index])
                .fill(0)
                .map((___, i) => t(`termsOfService.payingForPartners.section${index + 1}.body.part${i + 1}`))}
            />
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
