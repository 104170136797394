import styled from 'styled-components';
import { calendarIcons, Div, Flex, Input } from '@beauty/beauty-market-ui';

export const StyledInput = styled(Input)`
  & input {
    width: 100%;
  }
`;

export const InputWrapper = styled(Div)`
  position: relative;
  box-sizing: border-box;

  pointer-events: none;

  &:hover {
    cursor: none;
  }

  div {
    width: 100%;
  }

  & input {
    width: 100%;
    padding-left: 40px;
    padding-top: 14px;
  }
`;

export const InputIcon = styled(calendarIcons.MarketPlaceBooking)`
  position: absolute;
  z-index: 5;
  top: 35%;
  left: 16px;

  transform: translate(0, -50%);
`;

export const Container = styled(Flex)<{ image: string }>`
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 220px;
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 0;

  cursor: pointer;
  overflow: hidden;

  margin-bottom: 80px;

  background: ${({ image }) => `url(${image}) center/cover`};

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    pointer-events: all;
    inset: 0 0 0 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(125, 121, 249, 0) 45.18%, #7070ff 98.13%);
  }

  &:after {
    z-index: 1;
    content: '';
    position: absolute;
    pointer-events: all;
    inset: 0 0 0 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 70%, rgba(125, 121, 249, 0) 45.18%, #7070ff 98.13%);
  }

  * {
    position: relative;
    z-index: 2;
  }
`;
