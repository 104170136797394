import { TFunction, useTranslation } from 'react-i18next';
import Enterprice from '../../assets/enterprice.png';
import Masters from '../../assets/masters.png';
import Salons from '../../assets/salons.png';
import { ManagingCard } from './components/ManagingCard';
import { AdaptiveFlex, ManagingTitle, ManagingWrapper } from './style';

const managingCards = (t: TFunction<'translation', undefined>) => [
  {
    imageUrl: Masters,
    title: t(`business.managing.mastersTitle`),
    info: t(`business.managing.mastersInfo`),
  },
  {
    imageUrl: Salons,
    title: t(`business.managing.salonsTitle`),
    info: t(`business.managing.salonsInfo`),
  },
  {
    imageUrl: Enterprice,
    title: t(`business.managing.enterpriceTitle`),
    info: t(`business.managing.enterpriceInfo`),
  },
];

export const Managing = () => {
  const { t } = useTranslation();

  return (
    <ManagingWrapper>
      <ManagingTitle>{t('business.managing.title')}</ManagingTitle>
      <AdaptiveFlex gap={['16px', '24px']}>
        {managingCards(t).map(card => (
          <ManagingCard key={card.title} {...card} />
        ))}
      </AdaptiveFlex>
    </ManagingWrapper>
  );
};
