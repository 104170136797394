import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailReg } from '../../constants';

export enum SendMessageFormFields {
  Email = 'email',
  Message = 'message',
}

export type SendForm = {
  [SendMessageFormFields.Email]: string;
  [SendMessageFormFields.Message]: string;
};

export const initialSendFormValues: SendForm = {
  [SendMessageFormFields.Email]: '',
  [SendMessageFormFields.Message]: '',
};

export const sendFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [SendMessageFormFields.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required('Field cannot be empty'),
    [SendMessageFormFields.Message]: Yup.string().min(10, t('validation.min10')).required('Field cannot be empty'),
  });
