import { TFunction } from 'react-i18next';
import { apiRequest } from '../api/api';
import { UserEndpoints } from '../api/endpoints/endpoints';
import { supportCards } from '../page/Support/constants';

export const sendToSupport = async (data: FormData) => {
  const [error, response] = await apiRequest.post({
    endpoint: UserEndpoints.SendToSupport,
    data,
  });

  return { response: response?.data, error };
};

export const getSectionTitle = (sectionId: number, t: TFunction<'translation', undefined>) =>
  t(`support.sections.${supportCards.find(card => +card.id === sectionId)?.item}.title`);
