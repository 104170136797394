import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedLanguage } from '../constants';
import { fetchOrganisationDetails, fetchSpecialistsDetails } from '../helpers/organisation';
import { mapOrganisation } from '../page/Organisation/helpers';
import { Organisation } from '../page/Organisation/types';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setOrganisation, selectOrganisation, setSpecialists } from '../store/redux-slices/organisationSlice';
import { updateBooking } from '../store/redux-slices/userSlice';

export const useGetOrganisation = (orgId: string, isBooking?: boolean) => {
  const [mappedOrganisation, setMappedOrganisation] = useState<Organisation | null>();
  const [isLoading, setLoading] = useState(false);
  const selectedLanguage = getSelectedLanguage();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { organisation } = useAppSelector(selectOrganisation);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchOrganisation = async () => {
      setLoading(true);
      const orgDetails = await fetchOrganisationDetails(orgId);
      if (!abortController.signal.aborted) {
        dispatch(
          setOrganisation({
            organisation: orgDetails,
          }),
        );
        setLoading(false);
      }
    };

    const fetchSpecialists = async () => {
      const result = await fetchSpecialistsDetails(orgId);
      if (!abortController.signal.aborted) {
        dispatch(setSpecialists({ specialists: result.orgSpecialist }));
      }
    };

    if (!abortController.signal.aborted) {
      fetchOrganisation();
      fetchSpecialists();
      !isBooking &&
        dispatch(
          updateBooking({
            timeslot: null,
            specialist: null,
          }),
        );
    }

    return () => {
      abortController.abort();
    };
  }, [orgId]);

  useEffect(() => {
    organisation && setMappedOrganisation(mapOrganisation(organisation, selectedLanguage, t));
  }, [organisation, selectedLanguage, t]);

  return {
    isLoading,
    organisation: mappedOrganisation,
  };
};
