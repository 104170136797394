import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum SupportFormFields {
  Email = 'email',
  Subject = 'subject',
  Category = 'category',
  Message = 'message',
  Files = 'files',
}

export type SupportFormTypes = {
  [SupportFormFields.Email]: string;
  [SupportFormFields.Subject]: string;
  [SupportFormFields.Category]: string | undefined;
  [SupportFormFields.Message]: string;
  [SupportFormFields.Files]: File[];
};

export const initialSupportValues: SupportFormTypes = {
  [SupportFormFields.Email]: '',
  [SupportFormFields.Subject]: '',
  [SupportFormFields.Category]: '',
  [SupportFormFields.Message]: '',
  [SupportFormFields.Files]: [],
};

export const supportFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [SupportFormFields.Email]: Yup.string().required(t('validation.notEmpty')).email(t('validation.emailNotValid')),
    [SupportFormFields.Message]: Yup.string().trim().min(10).max(300).required(t('validation.notEmpty')),
    [SupportFormFields.Subject]: Yup.string().trim().min(10).max(300).required(t('validation.notEmpty')),
  });
