import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';
import { EmailForm } from '../page/Login/ResetPassword/ResetPassword.definitions';
import { getHost } from './utils';

export const resetPassword = async (data: EmailForm) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.ResetPassword,
    data: {
      email: data.email.toLowerCase(),
      host: getHost(),
      accType: AccType.USER,
      language: data.language,
    },
  });
  return response ? response.data : error?.data;
};
