import styled from 'styled-components';
import { BodyLarge, Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const TariffTableWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  margin-bottom: 64px;
  width: 100%;

  ${mediaQueries.sm} {
    gap: 24px;
  }
`;

export const TableWrapper = styled(Flex)`
  overflow: auto;
  width: 100%;
`;

export const MainColumn = styled(Div)`
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  grid-template-rows: repeat(1fr, 11);
  width: 200px;
  padding: 0 16px;
  border-radius: 16px;
  left: -8px;
  z-index: 2;

  ${mediaQueries.sm} {
    left: 0;
    background-color: transparent;
    position: relative;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    min-width: 194px;
    max-width: 440px;
    width: 70%;
  }
`;

export const AdditionalColumn = styled(Div)`
  grid-template-rows: repeat(1fr, 11);
  min-width: 92px;
  width: 100%;
`;

export const AdditionalColumns = styled(Flex)`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 70%;
  margin-left: 192px;

  ${mediaQueries.sm} {
    margin-left: 0;
  }
`;

export const Row = styled(Flex)<{ enableSeparator: boolean }>`
  align-items: center;
  min-height: 55px;
  width: 100%;
  border-bottom: ${({ theme, enableSeparator }) =>
    enableSeparator ? `1px solid ${theme.colors.grey.standard}` : 'none'};
`;

export const Head = styled(BodyLarge)<{ enableSeparator: boolean }>`
  padding: 24px 0;
  border-bottom: ${({ theme, enableSeparator }) =>
    enableSeparator ? `1px solid ${theme.colors.grey.standard}` : 'none'};

  ${mediaQueries.sm} {
    padding: 16px 0;
  }
`;
