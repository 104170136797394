import { TFunction } from 'react-i18next';
import { getPluralForm } from '../../helpers';
import { SubscriptionDataType, SubscriptionPlanType } from '../../types';

export const getTariffPlans = (
  subscriptionPlans: SubscriptionDataType[],
  t: TFunction<'translation', undefined>,
  language: string,
) => {
  const enterprise = {
    id: SubscriptionPlanType.ENTERPRISE,
    planType: SubscriptionPlanType.ENTERPRISE,
    limit: null,
    prices: {},
    features: [t('business.tariffPlans.unlimitedPlaces'), t('business.tariffPlans.unlimitedEmployees')],
    additional: [
      t('business.tariffPlans.commissions.forBookings'),
      t('business.tariffPlans.commissions.forPayments'),
      t('business.tariffPlans.commissions.forRepeat'),
    ],
  };
  const basePlans = subscriptionPlans.map(plan => ({
    ...plan,
    features: [
      getPluralForm('business.tariffPlans.addresses', plan.limit, language, t),
      t('business.tariffPlans.freeEmployee'),
    ],
    additional: [
      t('business.tariffPlans.commissions.forBookings'),
      t('business.tariffPlans.commissions.forPayments'),
      t('business.tariffPlans.commissions.forRepeat'),
    ],
  }));

  return [...basePlans, enterprise];
};

export const getType = (index: number, length: number): string => {
  if (index === 0) return 'left';
  if (index === length) return 'right';
  return 'center';
};

export const getTableData = (t: TFunction<'translation', undefined>) => ({
  categories: [
    t('business.tariffPlans.table.features'),
    t('business.tariffPlans.table.numberOfAddresses'),
    t('business.tariffPlans.table.photosNumber'),
    t('business.tariffPlans.table.onlineBooking'),
    t('business.tariffPlans.table.emailNotifications'),
    t('business.tariffPlans.table.clientFeedback'),
    t('business.tariffPlans.table.telegramNotifications'),
    t('business.tariffPlans.table.whatsApp'),
    t('business.tariffPlans.table.videoOfSalon'),
    t('business.tariffPlans.table.advancedReports'),
    t('business.tariffPlans.table.personalManager'),
  ],
  columns: [
    [SubscriptionPlanType.BASIC, '1', '5', true, true, true, false, false, false, false, false],
    [SubscriptionPlanType.PRO, '3', '10', true, true, true, true, false, false, false, false],
    [SubscriptionPlanType.BUSINESS, '5', '15', true, true, true, true, true, false, false, false],
    [
      SubscriptionPlanType.ENTERPRISE,
      t('business.tariffPlans.unlimited'),
      t('business.tariffPlans.unlimited'),
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  ],
});
