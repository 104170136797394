export const breadcrumbs = [
  {
    name: 'Home',
    onItemClick: () => {
      ('');
    },
  },
  {
    name: 'Support center',
    onItemClick: () => {
      ('');
    },
  },
];

export const supportCards = [
  {
    id: '1',
    item: 'order',
    optionsCount: 6,
  },
  {
    id: '2',
    item: 'complaint',
    optionsCount: 5,
  },
  {
    id: '3',
    item: 'technical',
    optionsCount: 8,
  },
  {
    id: '4',
    item: 'payment',
    optionsCount: 4,
  },
  {
    id: '5',
    item: 'review',
    optionsCount: 5,
  },
  {
    id: '6',
    item: 'verification',
    optionsCount: 8,
  },
  {
    id: '7',
    item: 'other',
    optionsCount: 0,
  },
];
