import { AxiosError, AxiosResponse } from 'axios';
import { isRequestCancelled } from '../../api';
import { HTTP_STATUS_PAYMENT_REQUIRED, HTTP_STATUS_NETWORK_AUTHENTICATION_REQUIRED } from '../../constants';

// TODO Review and refactor to remove ts-ignore
export class GeneralError {
  public resolve = async (response: AxiosResponse) => response;

  public reject = async (error: AxiosError) => {
    if (
      error?.response?.status !== null &&
      error?.response?.status !== HTTP_STATUS_NETWORK_AUTHENTICATION_REQUIRED &&
      error?.response?.status !== HTTP_STATUS_PAYMENT_REQUIRED &&
      !isRequestCancelled(error)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const skipError = error?.config?.headers?.skipError;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorType = error.response?.headers;
      if (!skipError || errorType === 'NetworkError') {
        this.triggerGetErrorEvent();
      }
    }
    return Promise.reject(error);
  };

  public triggerGetErrorEvent = () => {
    const event = new CustomEvent('generalError', { detail: true });
    document.dispatchEvent(event);
  };
}
