import { TFunction } from 'react-i18next';
import { isNull, omitBy } from 'lodash';
import { NotifyStatus, NotifyPropsType } from '@beauty/beauty-market-ui';
import { BookingType, CreateAppointmentParamsType, ErrorType } from '../../types';
import { ActionTypes } from '../../types/appointment';

export enum PayBy {
  Cash = 'CASH',
  Card = 'CARD',
}

export const getAppointmentParams = (
  booking: BookingType,
  paymentMethod: string,
  clientId: string,
  clientData: CreateAppointmentParamsType['clientData'],
  isLogin: boolean,
  saveCard: boolean,
): CreateAppointmentParamsType => {
  const isPayByCardId = paymentMethod !== 'CARD' && paymentMethod !== 'CASH';

  const params: CreateAppointmentParamsType = {
    orgSpecId: booking.specialist?.id || null,
    orgServId: booking.service!.id,
    start: booking.timeslot!.id.replace(':', ' '),
    // TODO: uncomment when we use payment by new card or cardId
    // paymentMethod: paymentMethod === 'CARD' ? 'CARD' : undefined,
    paymentMethod: undefined,
    cardId: isPayByCardId ? paymentMethod : undefined,
    saveCard: isPayByCardId ? saveCard : undefined,
    clientId: isLogin ? clientId : undefined,
    clientData: isLogin ? undefined : clientData,
  };

  return omitBy(params, isNull) as CreateAppointmentParamsType;
};

export const getAppointmentsNotifyContent = (status: NotifyStatus, action: ActionTypes, t): NotifyPropsType => {
  switch (action) {
    case ActionTypes.UPDATE:
      return {
        id: ActionTypes.UPDATE,
        status,
        title:
          status === NotifyStatus.SUCCESS
            ? t('alert.updateAppointmentSuccessTitle')
            : t('alert.updateAppointmentErrorTitle'),
        subtitle:
          status === NotifyStatus.SUCCESS
            ? t('alert.updateAppointmentSuccessSubtitle')
            : t('alert.updateAppointmentErrorSubtitle'),
      };
    case ActionTypes.CreateByGuest:
      return {
        id: ActionTypes.CREATE,
        status,
        title:
          status === NotifyStatus.SUCCESS
            ? t('alert.createAppointmentSuccessTitle')
            : t('alert.createAppointmentErrorTitle'),
        subtitle:
          status === NotifyStatus.SUCCESS
            ? t('alert.createAppointmentByGuestSuccessSubtitle')
            : t('alert.createAppointmentErrorSubtitle'),
      };
    default:
      return {
        id: ActionTypes.CREATE,
        status,
        title:
          status === NotifyStatus.SUCCESS
            ? t('alert.createAppointmentSuccessTitle')
            : t('alert.createAppointmentErrorTitle'),
        subtitle:
          status === NotifyStatus.SUCCESS
            ? t('alert.createAppointmentSuccessSubtitle')
            : t('alert.createAppointmentErrorSubtitle'),
      };
  }
};

export const handleError = (
  action: ActionTypes,
  notify: (props: NotifyPropsType) => void,
  t: TFunction<'translation', undefined>,
  error?: ErrorType,
) => {
  switch (error?.message) {
    case "Start can't be in the past":
    case 'Available specialist was not found':
    case 'This organisation does not support online payment': {
      const content = getAppointmentsNotifyContent(NotifyStatus.ERROR, action, t);
      notify(error?.message ? { ...content, subtitle: t(`validation.${error.message}`) } : content);
      break;
    }
    default: {
      notify(getAppointmentsNotifyContent(NotifyStatus.ERROR, action, t));
      break;
    }
  }
};
