import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import {
  CoverStack,
  CoverStackCustomer,
  Div,
  getMinWidthMediaQuery,
  useMediaQuery,
  Separator,
} from '@beauty/beauty-market-ui';
import { formatDate } from '../../../../helpers/utils';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { ProfileType } from '../../../../types/user';
import { PersonalInfoWrapper } from '../style';
import { Info } from './molecules/Info';

type PersonalInfoProps = {
  profile: ProfileType;
  onEditProfileClick: (isOpen: boolean) => void;
};

export const PersonalInfo = ({ profile, onEditProfileClick }: PersonalInfoProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const profileInfo = [
    {
      title: t('profile.phone'),
      type: 'add',
      value: `${profile.code} ${profile.number}`,
    },
    {
      title: t('profile.e-mail'),
      type: 'add',
      value: `${profile.email.substring(0, 19)}${profile.email.length > 19 ? '...' : ''}`,
    },
    {
      title: t('profile.dateOfBirth'),
      type: 'add',
      value: profile.dateOfBirth ? formatDate(profile.dateOfBirth) : '',
    },
    {
      title: t('profile.gender'),
      type: 'choose',
      value: profile.gender ? t(`profile.genders.${profile.gender.toLowerCase()}`) : '',
    },
    {
      title: t('profile.idNumber'),
      type: 'add',
      value: profile.idNumber ?? '',
    },
    {
      title: t('profile.address'),
      type: 'add',
      value: [profile.fullAddress, profile.city, profile.country].filter(item => Boolean(item)).join(', '),
    },
  ];

  const getProfileFields = () => (
    <Div mt="36px">
      {profileInfo.map((info, i) => (
        <React.Fragment key={nanoid()}>
          <Info title={info.title} type={info.type} value={info.value} onClick={() => onEditProfileClick(true)} />
          {!isLarge && i !== profileInfo.length - 1 && <Separator mb="20px" />}
        </React.Fragment>
      ))}
    </Div>
  );

  return (
    <PersonalInfoWrapper>
      <CoverStack
        header={
          <CoverStackCustomer
            width={!isLarge && '327px'}
            buttonDesign="tertiary"
            buttonSize="extraSmall"
            editButtonText={t('profile.editProfile')}
            firstName={profile.name}
            lastName={profile.surname}
            imgUrl={profile.avatarUrl}
            onButtonClick={() => {
              onEditProfileClick(true);
            }}
          />
        }
        content={isLarge && getProfileFields()}
      />
      {!isLarge && getProfileFields()}
    </PersonalInfoWrapper>
  );
};
