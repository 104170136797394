import { useTranslation } from 'react-i18next';
import { Caption, colors } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';
import { LinkStyled } from '../style';

export const Rules = () => {
  const { t } = useTranslation();

  return (
    <Caption p="8px 40px" textAlign="center" color={colors.grey.standard}>
      {t('login.rules.description')}
      <LinkStyled design="blue" size="xs" href={RouterUrl.PrivacyPolicy} target="_blank">
        {t('login.rules.policy')}
      </LinkStyled>
      ,
      <LinkStyled design="blue" size="xs" href={RouterUrl.TermsOfUse} target="_blank">
        {t('login.rules.use')}
      </LinkStyled>
      {t('login.rules.and')}
      <LinkStyled design="blue" size="xs" href={RouterUrl.TermsOfService} target="_blank">
        {t('login.rules.service')}
      </LinkStyled>
    </Caption>
  );
};
