import { useTranslation } from 'react-i18next';
import {
  Button,
  Sidebar,
  Flex,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
  BodyLarge,
} from '@beauty/beauty-market-ui';
import LogoutImage from '../../assets/exit-image.png';

interface LogoutSidebarProps {
  isOpen: boolean;
  onLogout: () => void;
  loginAnotherUser: () => void;
  onLogoutCancel: () => void;
}

const LogoutSidebar = ({ isOpen, onLogout, loginAnotherUser, onLogoutCancel }: LogoutSidebarProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const FooterBody = (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" width="100%" minHeight={200}>
      <Button size="large" width="100%" onClick={onLogout}>
        {t('logout.logoutButton')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={onLogoutCancel}>
        {t('logout.cancelButton')}
      </Button>
      <Button design="tertiary" onClick={loginAnotherUser} size="medium">
        {t('logout.logIn')}
      </Button>
    </Flex>
  );
  const content = <BodyLarge>{t('logout.description')}</BodyLarge>;
  const logoutSomeProps = {
    isOpen,
    label: t('logout.title'),
    onClose: onLogoutCancel,
    FooterBody,
  };

  return isLarge ? (
    <Sidebar {...logoutSomeProps} descriptor={t('logout.description')}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <img src={LogoutImage} alt={t('logout.altImg')} />
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...logoutSomeProps} content={content} detent="content-height" />
  );
};

export default LogoutSidebar;
