import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import jwtDecode from 'jwt-decode';
import { Button, Flex, Div, H2, ListRegionCode, Checkbox, BodySmall } from '@beauty/beauty-market-ui';
import { setAccessToken } from '../../../api/api.helpers';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { FormikPasswordInput } from '../../../components/functional/formik/formik-password-input/FormikPasswordInput';
import { AccType, InvalidVariants } from '../../../constants';
import { registration } from '../../../helpers/registration';
import { signupPhoneVerification } from '../../../helpers/signupPhoneVerification';
import { getInvalidType, getListRegionCode } from '../../../helpers/utils';
import { useToggle } from '../../../hooks/useToggle';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { updateSmsCodeTime, updateUser } from '../../../store/redux-slices/userSlice';
import { GappedFlex } from '../../../style';
import {
  RegistrationForm,
  initialvalues,
  RegistrationFormFields,
  registrationFormValidationSchema,
  DecodedToken,
} from './Registration.definitions';

const Registration = () => {
  const { t } = useTranslation();
  const [currentRegionCode, setCurrentRegionCode] = useState<string>('+972');
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isNotifications, setNotifications] = useToggle(true);
  const [isLoading, setLoading] = useState(false);
  const [phoneCode, setPhoneCode] = useState('+972');
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const email = state?.email;
  useEffect(() => {
    if (state?.code) {
      setCurrentRegionCode(state.code);
    }
  }, [state?.code]);
  const number = state?.number;
  const decodedToken: DecodedToken = jwtDecode(state?.token);
  const { orgId } = decodedToken;
  const { Number } = RegistrationFormFields;

  const onFormSubmit = useCallback(
    async (data: RegistrationForm) => {
      setLoading(true);
      const phoneVer = { code: currentRegionCode, number: data[Number], email: data.email };
      const response = await signupPhoneVerification(phoneVer);
      if (response.statusCode === 200) {
        const { agreement, code, ...params } = data;
        const regResponse = await registration({
          ...params,
          code: currentRegionCode,
          accType: AccType.USER,
          marketingNotes: true,
        });
        if (regResponse.id) {
          const {
            email: userEmail,
            name,
            surname,
            id,
            avatarUrl,
            code: userCode,
            number: userNumber,
            idNumber: userIdNumber,
          } = regResponse;
          setAccessToken(regResponse.accessToken);
          dispatch(
            updateUser({
              isLogin: true,
              user: {
                name,
                surname,
                email: userEmail,
                code: userCode,
                number: userNumber,
                userId: id,
                avatarUrl,
                idNumber: userIdNumber,
              },
            }),
          );
          navigate(`${RouterUrl.Organisation}/${orgId}`);
        } else {
          navigate(RouterUrl.Homepage);
        }
      }
      if (response.status === 'pending') {
        setInvalidType(null);
        dispatch(updateSmsCodeTime(new Date().getTime()));
        navigate(RouterUrl.PhoneVerification, {
          state: { ...data, code: currentRegionCode, agreement: isNotifications, orgId },
        });
      } else setInvalidType(getInvalidType(response.statusCode, 'registration'));
      setLoading(false);
    },
    [navigate, currentRegionCode, isNotifications, invalidType],
  );

  const formikContextValue = {
    initialValues: {
      ...initialvalues,
      [RegistrationFormFields.Email]: email,
      [RegistrationFormFields.Number]: number,
    },
    validationSchema: registrationFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
    enableReinitialize: true,
  };

  return (
    <Flex flexDirection={['column', 'column', 'column']}>
      <H2>{t('registration.title')}</H2>
      <Div width="100%" mt="16px">
        <Formik {...formikContextValue}>
          {({ values, isValid, setFieldValue }) => (
            <Form autoComplete="on">
              <GappedFlex gap="8px" flexDirection={['column', 'column', 'column', 'row']}>
                <FormikInput
                  autoFocus
                  id={RegistrationFormFields.Name}
                  name={RegistrationFormFields.Name}
                  placeholder={t('profile.name')}
                  autoComplete="given-name"
                  mb="8px"
                />
                <FormikInput
                  autoFocus
                  id={RegistrationFormFields.Surname}
                  name={RegistrationFormFields.Surname}
                  autoComplete="family-name"
                  placeholder={t('profile.surname')}
                  mb="16px"
                />
              </GappedFlex>

              <FormikInput
                id={RegistrationFormFields.Email}
                name={RegistrationFormFields.Email}
                defaultValue={email}
                autoComplete="email"
                type="email"
                placeholder="E-mail"
                mb="16px"
                disabled
              />
              <Flex mb="16px">
                <ListRegionCode
                  currentRegionCode={currentRegionCode}
                  handleSelect={code => {
                    setCurrentRegionCode(code);
                    setFieldValue(RegistrationFormFields.Region, code);
                  }}
                  id={RegistrationFormFields.Region}
                  name={RegistrationFormFields.Region}
                  placeholder={t('registration.region')}
                  options={getListRegionCode()}
                />
                <Div width="100%" ml="8px">
                  <FormikInput
                    id={RegistrationFormFields.Number}
                    name={RegistrationFormFields.Number}
                    placeholder={t('registration.phone')}
                    type="tel"
                    autoComplete="tel"
                    invalidType={invalidType}
                    onInput={() => setInvalidType(null)}
                  />
                </Div>
              </Flex>
              <FormikInput
                id={RegistrationFormFields.IdNumber}
                name={RegistrationFormFields.IdNumber}
                placeholder={t('profile.idNumber')}
                mb="16px"
                onInput={code => setFieldValue(RegistrationFormFields.IdNumber, code)}
              />
              <FormikPasswordInput
                id={RegistrationFormFields.Password}
                name={RegistrationFormFields.Password}
                autoComplete="new-password"
                type="password"
                placeholder={t('registration.password')}
                caption={t('registration.passwordHint')}
              />
              <Flex alignItems="center" my="30px">
                <Checkbox shape="round" onClick={setNotifications} checked={isNotifications} />
                <BodySmall low ml="8px">
                  {t('registration.iAgree')}
                </BodySmall>
              </Flex>
              <Button
                disabled={!isValid || invalidType || isLoading}
                design="primary"
                mt="24px"
                width="100%"
                size="large"
                type="submit"
              >
                {t('button.continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
    </Flex>
  );
};

export default Registration;
