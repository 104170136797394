import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors, Div, Logo, Flex } from '@beauty/beauty-market-ui';
import AppStore from '../../assets/app-store.svg';
import Apple from '../../assets/apple.svg';
import Arrow from '../../assets/arrow.svg';
import GooglePlay from '../../assets/google-play.svg';
import { useToggle } from '../../hooks/useToggle';
import { RouterUrl } from '../../routes/routes';
import { ApplicationButton } from '../ApplicationButton/ApplicationButton';
import { CookieSidebar } from '../CookieSidebar/CookieSidebar';
import ColumnLinks from './components/ColumnLinks';
import { StyledFlex, StyledLink } from './components/style';
import { companyColumn, businessColumn, legalsColumn, socialNetworks } from './const';
import { FooterContainer, FooterWrapper, StyledH6, ButtonsWrapper } from './style';

const ExtendedFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCookieSettingOpen, setIsCookieSettingOpen] = useToggle();

  return (
    <FooterWrapper>
      <FooterContainer>
        <Div gridArea="logo">
          <Logo
            labelColor={colors.black.standard}
            logoColor={colors.blue.standard}
            monochrome
            onClick={() => navigate(RouterUrl.Homepage)}
            mt="-3px"
          />
        </Div>

        <ButtonsWrapper gridArea="buttons">
          <StyledFlex>
            <ApplicationButton iconSource={Apple} iconSrc={AppStore} design="tertiary" altText="Apple Button" />
            <ApplicationButton iconSource={Arrow} iconSrc={GooglePlay} design="tertiary" altText="Google Button" />
          </StyledFlex>
        </ButtonsWrapper>

        <Div gridArea="company">
          <StyledH6>{t('extendedFooter.company')}</StyledH6>
          <ColumnLinks columnData={companyColumn} />
        </Div>

        <Div gridArea="business">
          <StyledH6>{t('extendedFooter.forBusiness')}</StyledH6>
          <ColumnLinks columnData={businessColumn} />
        </Div>

        <Div gridArea="legals">
          <StyledH6>{t('extendedFooter.legalInformation')}</StyledH6>
          <ColumnLinks columnData={legalsColumn} />
          <StyledLink
            key="extendedFooter.cookiesSettings"
            size="s"
            design="black"
            onClick={() => setIsCookieSettingOpen()}
          >
            {t(`extendedFooter.cookiesSettings`)}
          </StyledLink>
        </Div>

        <Div gridArea="contact">
          <StyledH6>{t('extendedFooter.contactUs')}</StyledH6>
          <Flex flexDirection="column">
            {socialNetworks.map(item => (
              <StyledLink key={item.text} icon={item.icon} size="s" href={item.link} target="_blank" design="black">
                {item.text}
              </StyledLink>
            ))}
          </Flex>
        </Div>
      </FooterContainer>
      <CookieSidebar isCookieSettingOpen={isCookieSettingOpen} setIsCookieSettingOpen={setIsCookieSettingOpen} />
    </FooterWrapper>
  );
};

export default ExtendedFooter;
