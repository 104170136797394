import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Sidebar,
  ImageCropper,
  BottomSheet,
  useMediaQuery,
  getMinWidthMediaQuery,
  Caption,
  ArrowLeftIcon,
} from '@beauty/beauty-market-ui';
import { BackWrapper } from '../../style';

export type UploadPhotoSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isEdit: boolean;
  setPhoto: (photo: File) => void;
  setEditMode: (isEditMode: boolean) => void;
};

export const UploadPhotoSidebar = ({ isOpen, setIsOpen, isEdit, setPhoto, setEditMode }: UploadPhotoSidebarProps) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const [isCrop, setIsCrop] = useState(false);
  const [isLastStep, setLastStep] = useState(false);

  const handleClose = () => {
    setEditMode(false);
    setLastStep(false);
    setIsOpen(false);
  };

  const footerBody = (
    <>
      <Button
        disabled={!isLastStep}
        design="primary"
        mt="16px"
        width="100%"
        size="large"
        type="submit"
        onClick={() => {
          setIsCrop(true);
          setLastStep(false);
          setTimeout(() => setIsOpen(false), 200);
        }}
      >
        {t('button.save')}
      </Button>
      <Button design="secondary" mt="16px" width="100%" size="large" onClick={handleClose}>
        {t('button.cancel')}
      </Button>
    </>
  );

  const handleBackClick = useCallback(() => {
    if (isLastStep) setLastStep(false);
    else if (isOpen) handleClose();
  }, [isOpen, isLastStep]);

  const sidebarDescriptor = (
    <BackWrapper onClick={handleBackClick}>
      <ArrowLeftIcon width="16px" height="16px" />
      <Caption large>{t('button.back')}</Caption>
    </BackWrapper>
  );

  const generalProps = {
    isOpen,
    label: `${t(`uploadPhoto.new`)} ${t('uploadPhoto.photo')}`,
    descriptor: sidebarDescriptor,
    onClose: handleClose,
    FooterBody: footerBody,
  };

  const content = (
    <ImageCropper
      dragText={t('uploadPhoto.dragText')}
      browseText={t('uploadPhoto.browseText')}
      restrictionsText={t('uploadPhoto.restrictionsText')}
      errorText={t('uploadPhoto.errorText')}
      maxZoom={5}
      isCrop={isCrop}
      setIsCrop={setIsCrop}
      onCrop={setPhoto}
      isLastStep={isLastStep}
      setLastStep={setLastStep}
    />
  );

  return isLarge ? (
    <Sidebar {...generalProps}>{content}</Sidebar>
  ) : (
    <BottomSheet {...generalProps} /* detent="content-height" */ content={content} />
  );
};
