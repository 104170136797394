import { Wrapper } from './style';
import { ContainerProps } from './types';

const Container = ({ wide = false, children, ...other }: ContainerProps) => (
  <Wrapper wide={wide} {...other}>
    {children}
  </Wrapper>
);

export default Container;
