import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const ServiceUnavailableWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 340px;
  height: 300px;
  transform: translate(-50%, -50%);

  ${mediaQueries.md} {
    width: 480px;
    height: 300px;
    transform: translate(-50%, -100%);
  }
`;
