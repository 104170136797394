import { apiRequest } from '../api/api';
import { AuthEndpoints, UserEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';

type SendCodeParams = {
  code: string;
  number: string;
  language?: string;
};

type PhoneVerificationParams = {
  email: string;
} & SendCodeParams;

export const signupPhoneVerification = async (params: PhoneVerificationParams) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.SignupPhoneVerification,
    data: { ...params, email: params.email.toLowerCase(), accType: AccType.USER },
  });

  return response ? { ...response.data, statusCode: response.status } : error?.data;
};

/* export const sendCode = async (params: SendCodeParams) => {
  const [error, response] = await apiRequest.post({
    endpoint: UserEndpoints.Phone,
    data: { ...params, accType: AccType.USER, language: params.language },
  });

  return response ? response.data : error?.data;
}; */
