import styled from 'styled-components';
import { Flex, colors, ProfileIcon } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  box-sizing: border-box;
  background-color: ${colors.black.standard};
  border-radius: 60px;
  padding: 14px 24px;
  width: 216px;
  height: 52px;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    :hover {
      cursor: pointer;
    }
    :active {
      stroke: ${colors.white.standard};
    }
    path {
      stroke: ${colors.grey.dark};
    }
  }
`;

export const StyledProfileIcon = styled(ProfileIcon)`
  path {
    stroke: ${colors.grey.dark};
  }
`;
