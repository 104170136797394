import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DocumentCard, Flex } from '@beauty/beauty-market-ui';
import { buttons, documents } from '../constants';

type DocumentsListProps = {
  activeTag: number;
  onButtonClick: (id: number) => void;
};

const StyledFlex = styled(Flex)`
  gap: 24px;
`;

const getDocsToRender = (tag: number) => {
  switch (tag) {
    case 0:
      return documents;
    case 1:
      return documents.filter(doc => !doc.isFilled);
    default:
      return documents.filter(doc => doc.isFilled);
  }
};

const DocumentsList = ({ activeTag, onButtonClick }: DocumentsListProps) => {
  const { t } = useTranslation();
  const docsToRender = getDocsToRender(activeTag);

  const docsCards = docsToRender.map(doc => (
    <DocumentCard
      key={doc.id}
      isFilled={doc.isFilled}
      firstButton={doc.isFilled ? t(`docs.${buttons.filled.first}`) : t(`docs.${buttons.nonFilled.first}`)}
      secondButton={doc.isFilled && t(`docs.${buttons.filled.second}`)}
      info={doc.info}
      onFirstClick={() => {
        console.log(doc.isFilled ? 'start editing' : 'start filling');
        onButtonClick(doc.id);
      }}
      onSecondClick={
        doc.isFilled &&
        (() => {
          console.log('downloading');
          onButtonClick(doc.id);
        })
      }
      onIconClick={() => console.log('onIconClick')}
      service={doc.service}
      title={doc.title}
    />
  ));

  return <StyledFlex flexWrap="wrap">{docsCards}</StyledFlex>;
};

export { DocumentsList };
