import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  H3,
  H5,
  ReviewsRait,
  useMediaQuery,
  getMinWidthMediaQuery,
  FilterByRating,
  Flex,
  Slider,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { getTranslation } from '../../../../helpers/utils';
import { OrganisationRating } from '../../../../types/organisation';
import { BadgesWrapper, RatingWrapper, SliderWrapper } from '../../style';
import { BadgeItem } from './BadgeItem';

interface RatingProps {
  rating: OrganisationRating;
}

const Rating = ({ rating }: RatingProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const { percentages, badges } = rating;
  const nonEmptyMark = Object.entries(percentages)
    .sort((firstMark, secondMark) => +secondMark[0] - +firstMark[0])
    .find(percentage => percentage[1]);
  const [active, setActive] = useState(nonEmptyMark ? nonEmptyMark[0] : '0');

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const ratingDescriptions = {
    descriptions: {
      reviews: t('organisation.rating.descriptions.reviews'),
      photo: t('organisation.rating.descriptions.photo'),
    },
  };
  const ratingProps = { ...rating, total: rating.rating, ...ratingDescriptions };

  const badgesBody = badges[active]?.map(badge => (
    <BadgeItem
      key={badge.title?.text}
      avatar={badge.activeIconUrl}
      title={getTranslation(badge.title, language)}
      subtitle={t(`organisation.rating.ratings`, { count: badge.count })}
    />
  ));

  return (
    <RatingWrapper>
      <H3>{t('organisation.rating.rating')}</H3>
      <ReviewsRait {...ratingProps} containerStyles={isMobile && { 'column-gap': '60px', width: '290px' }} />
      <Flex flexDirection="column">
        <H5 mb="24px">{t('organisation.rating.rating')}</H5>
        <FilterByRating rating={percentages} active={active} onClick={setActive} />
      </Flex>
      <Flex flexDirection="column">
        <H5 mb="24px">{t('organisation.rating.badges')}</H5>
        <BadgesWrapper>
          {isMobile
            ? !!badgesBody?.length && (
                <SliderWrapper>
                  <Slider inlineMode slides={badgesBody} />
                </SliderWrapper>
              )
            : badgesBody}
        </BadgesWrapper>
      </Flex>
    </RatingWrapper>
  );
};

export default Rating;
