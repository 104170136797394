import { TFunction, useTranslation } from 'react-i18next';
import { Flex } from '@beauty/beauty-market-ui';
import Employees1x from '../../assets/employees@1x.png';
import Employees2x from '../../assets/employees@2x.png';
import Reports1x from '../../assets/reports@1x.png';
import Reports2x from '../../assets/reports@2x.png';
import Schedule1x from '../../assets/schedule@1x.png';
import Schedule2x from '../../assets/schedule@2x.png';

import { setImgUrl } from '../../helpers/utils';
import { Benefit } from './components/Benefit';
import { AdaptiveFlex, ColoredDisplay } from './style';

const benefits = (t: TFunction<'translation', undefined>) => [
  {
    images: [Schedule1x, Schedule2x],
    number: '01',
    title: t(`business.benefits.scheduleTitle`),
    info: t(`business.benefits.scheduleInfo`),
    isImageLeft: true,
  },
  {
    images: [Employees1x, Employees2x],
    number: '02',
    title: t(`business.benefits.employeesTitle`),
    info: t(`business.benefits.employeesInfo`),
    isImageLeft: false,
  },
  {
    images: [Reports1x, Reports2x],
    number: '03',
    title: t(`business.benefits.reportsTitle`),
    info: t(`business.benefits.reportsInfo`),
    isImageLeft: true,
  },
];

export const OurBenefits = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" mb={['80px', '80px', '80px', '200px']}>
      <ColoredDisplay large>{t('business.why')}</ColoredDisplay>
      <AdaptiveFlex flexDirection="column" gap={['80px', '200px']}>
        {benefits(t).map(benefit => (
          <Benefit key={benefit.title} {...benefit} imageUrl={setImgUrl(benefit.images)} />
        ))}
      </AdaptiveFlex>
    </Flex>
  );
};
