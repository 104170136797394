import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import jwtDecode from 'jwt-decode';
import { Button, H2, BodySmall, Div } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { AccType, InvalidVariants, getSelectedLanguage } from '../../../constants';
import { sendEmailLink } from '../../../helpers/sendEmailLink';
import { getInvalidType } from '../../../helpers/utils';
import { useGetSearchParams } from '../../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../../routes/routes';
import { Rules } from '../componets/Rules';
import { EmailField, checkEmailSchema, EmailForm } from '../Login.definitions';

const SignUp = () => {
  const { t } = useTranslation();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [decodedToken, setDecodedToken] = useState({ id: '', orgId: '', email: '' });
  const { state } = useLocation();
  const isWrongLink = state?.isWrongLink;
  const currentLanguage = getSelectedLanguage();
  const [searchParamsAsObject] = useGetSearchParams();
  const navigate = useNavigate();

  const referral = useMemo(() => searchParamsAsObject?.referral || '', [searchParamsAsObject]);

  useEffect(() => {
    if (searchParamsAsObject?.client) {
      setDecodedToken(jwtDecode(searchParamsAsObject.client));
    }
  }, [searchParamsAsObject]);

  const onFormSubmit = useCallback(
    async (data: EmailForm) => {
      setEmail(data.email);
      const response = await sendEmailLink(
        data,
        AccType.USER,
        currentLanguage,
        decodedToken.id,
        decodedToken.orgId,
        referral,
      );
      if (response.success) {
        if (response.statusCode === 200) {
          navigate(`${RouterUrl.Login}/email-verification?token=${response.data.emailVerificationToken}`);
        }
        setIsLinkSent(true);
        setInvalidType(null);
      } else {
        setInvalidType(getInvalidType(response.statusCode, 'signUp'));
        setErrorMessage(response.message);
      }
    },
    [decodedToken, referral],
  );

  const formikContextValue = {
    initialValues: { email: decodedToken.email },
    validationSchema: checkEmailSchema(errorMessage, t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
    enableReinitialize: true,
  };

  return isLinkSent && !isWrongLink ? (
    <Div textAlign="center">
      <H2 mb="16px">{t('signUp.checkInbox')}</H2>
      <BodySmall large>{t('signUp.linkToVerify') + email}</BodySmall>
    </Div>
  ) : (
    <>
      <H2 mb="16px">{isWrongLink ? t('signUp.wrongLink') : t('signUp.title')}</H2>
      <BodySmall mb="32px">{t('signUp.enterEmail')}</BodySmall>
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <FormikInput
              autoFocus
              id={EmailField.Email}
              name={EmailField.Email}
              placeholder="E-mail"
              invalidType={invalidType}
              onInput={() => setInvalidType(null)}
            />
            <Button
              type="submit"
              disabled={!isValid || invalidType}
              design="primary"
              mt="14px"
              width="100%"
              size="large"
            >
              {t('button.continue')}
            </Button>
          </Form>
        )}
      </Formik>
      <Rules />
    </>
  );
};

export default SignUp;
