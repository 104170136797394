import React from 'react';
import { Wrapper, Header, Footer } from './style';

interface CustomBottomSheetProps {
  header?: JSX.Element | null;
  content?: JSX.Element | null;
  footer?: JSX.Element | null;
}

const CustomBottomSheet = ({ header, content, footer }: CustomBottomSheetProps) => (
  <Wrapper>
    {header && <Header>{header}</Header>}
    {content}
    {footer && <Footer>{footer}</Footer>}
  </Wrapper>
);

export default CustomBottomSheet;
