import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Popup,
  Button,
  useMediaQuery,
  BottomSheet,
  getMinWidthMediaQuery,
  ShowMore,
  Div,
} from '@beauty/beauty-market-ui';
import { setCookiesAccepted } from '../../api/api.helpers';
import { useToggle } from '../../hooks/useToggle';
import { CookieSidebar } from '../CookieSidebar/CookieSidebar';
import { CookieModalContent } from './CookieModalContent';
import { StyledButton } from './style';

export const CookieModal = () => {
  const [isCookieOpen, setCookieOpen] = useToggle(true);
  const [isCookieSettingOpen, setCookieSettingOpen] = useToggle(false);

  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setCookieOpen();
    setCookiesAccepted('false');
  };

  const FooterBody = (
    <StyledButton>
      <Button
        size={isLarge ? 'large' : 'small'}
        width={['100%', '208px']}
        onClick={() => {
          setCookieSettingOpen();
          setCookieOpen();
        }}
        design="secondary"
      >
        {t('cookies.setting')}
      </Button>
      <Button
        size={isLarge ? 'large' : 'small'}
        width={['100%', '208px']}
        onClick={() => {
          setCookieOpen();
          setCookiesAccepted('true');
        }}
      >
        {t('cookies.accept')}
      </Button>
    </StyledButton>
  );

  const content = (
    <>
      <Div mb="25px">
        <ShowMore
          showLess={t('cookies.less')}
          showMore={t('cookies.more')}
          text={<CookieModalContent />}
          visibleLines={3}
        />
      </Div>
      {FooterBody}
    </>
  );

  return (
    <>
      {isLarge ? (
        <Popup
          title={t('cookies.title')}
          open={isCookieOpen}
          handleClose={handleClose}
          FooterBody={FooterBody}
          width={1060}
          alignItems="end"
          marginBottom="8px"
        >
          <CookieModalContent />
        </Popup>
      ) : (
        <BottomSheet
          isOpen={isCookieOpen}
          onClose={handleClose}
          content={content}
          label={t('cookies.title')}
          detent="content-height"
        />
      )}
      {isCookieSettingOpen && (
        <CookieSidebar setIsCookieSettingOpen={setCookieSettingOpen} isCookieSettingOpen={isCookieSettingOpen} />
      )}
    </>
  );
};
