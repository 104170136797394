import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getMinWidthMediaQuery, useMediaQuery, colors, H4, BodySmall } from '@beauty/beauty-market-ui';
import InfoImage from '../../assets/info-image.png';
import { RouterUrl } from '../../routes/routes';
import { NoDataWrapper, SearchWrapper, StyledSearch } from './style';

export const MobileSearch = () => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);
  const location = useLocation();

  const isShowSearch = useMemo(() => location.pathname === RouterUrl.Search && isMobile, [location, isMobile]);

  return isShowSearch ? (
    <>
      <SearchWrapper>
        <StyledSearch />
      </SearchWrapper>
      {false && (
        <NoDataWrapper px="25px">
          <img src={InfoImage} alt="noData" />
          <H4 mt="24px" mb="0">
            {t('search.empty.title')}
          </H4>
          <BodySmall mt="8px" color={colors.grey.dark}>
            {t('search.empty.body')}
          </BodySmall>
        </NoDataWrapper>
      )}
    </>
  ) : null;
};
