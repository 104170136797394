import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';

type LoginByPhoneType = {
  code: string;
  number: string;
  otp: string;
  accType: AccType;
};

export const loginByPhone = async (data: LoginByPhoneType) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.LoginByPhone,
    data: { ...data },
  });
  return response ? response.data : error?.data;
};
