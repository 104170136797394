import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Popup, Button, getMinWidthMediaQuery, useMediaQuery, BottomSheet } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../routes/routes';

type ChangeEmailPopupProps = {
  email: string;
  handlePopupClose: () => void;
};

export const ChangeEmailPopup = ({ email, handlePopupClose }: ChangeEmailPopupProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  const navigate = useNavigate();

  const PopupFooterBody = (
    <Button
      size={isLarge ? 'large' : 'medium'}
      width="100%"
      onClick={() => {
        handlePopupClose();
        navigate(RouterUrl.Homepage);
      }}
      mb={!isLarge ? '8px' : ''}
    >
      {t('button.done')}
    </Button>
  );

  return isLarge ? (
    <Popup
      title={t('profile.checkYourBox')}
      info={t('profile.weSend') + email}
      open
      handleClose={handlePopupClose}
      FooterBody={PopupFooterBody}
    />
  ) : (
    <BottomSheet
      isOpen
      onClose={handlePopupClose}
      label={t('profile.checkYourBox')}
      descriptor={t('profile.weSend') + email}
      content={PopupFooterBody}
      detent="content-height"
    />
  );
};
