import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodyLarge, H3, ShowMore } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers/utils';
import { CategoryType, ReviewType, SpecialRequirementType, TeamMateType, TextWithTranslations } from '../../../types';
import { AboutAdvantages, AboutAdvantagesItem, AboutWrapper, Description } from '../style';
import Offerings from './Offerings';
import PhotoGallery from './PhotoGallery';
import Team from './Team';

interface AboutProps {
  description: string;
  organisationName: string;
  photo: string[];
  checkedIndex: number;
  setCheckedIndex: (index: number) => void;
  offers: {
    categories: CategoryType[];
    specialRequirement?: SpecialRequirementType;
  };
  reviews?: ReviewType[];
  orgId: string;
  team?: TeamMateType[];
  orgDescr?: TextWithTranslations;
  property: Array<{
    id: string;
    iconUrl: string;
    title: string;
  }>;
}

const About = (props: AboutProps) => {
  const { t } = useTranslation();
  const { property, description, orgDescr, team, offers, reviews, organisationName, orgId, photo, setCheckedIndex } =
    props;
  const language = getSelectedLanguage();

  const isEmptyProperty = isEmpty(property);
  const isEmptyPhoto = isEmpty(photo);
  const isEmptyDescription = isEmpty(orgDescr) && !description;
  const isAboutEmpty = isEmptyProperty && isEmptyDescription && isEmptyPhoto;

  return (
    <AboutWrapper>
      {!isAboutEmpty && (
        <>
          <H3 mt="85px">{t('organisation.about.about')}</H3>
          {!isEmptyProperty && (
            <AboutAdvantages>
              {property.map(prop => (
                <AboutAdvantagesItem key={prop.id}>
                  <img src={prop.iconUrl} alt={prop.title} />
                  <BodyLarge large>{prop.title}</BodyLarge>
                </AboutAdvantagesItem>
              ))}
            </AboutAdvantages>
          )}
          {!isEmptyDescription && (
            <Description rtl={language === 'HE'}>
              <ShowMore
                text={orgDescr ? getTranslation(orgDescr, language) : description}
                visibleLines={3}
                showMore={t('organisation.about.showMore')}
                showLess={t('organisation.about.showLess')}
              />
            </Description>
          )}
          {!isEmptyPhoto && <PhotoGallery photos={photo} setCheckedIndex={setCheckedIndex} />}
        </>
      )}
      <Offerings {...offers} organisationName={organisationName} />
      {!!team?.length && <Team team={team} reviews={reviews} orgId={orgId} />}
    </AboutWrapper>
  );
};

export default About;
