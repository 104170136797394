import { useState, useEffect } from 'react';
import { getSecondsLeft } from '../helpers/registration';
import { useAppSelector } from '../store/hooks';
import { selectUser } from '../store/redux-slices/userSlice';

export const useGetSecondsLeft = (): number => {
  const { smsCodeTime } = useAppSelector(selectUser);
  const [secondsLeft, setSecondsLeft] = useState(getSecondsLeft(smsCodeTime));

  useEffect(() => {
    secondsLeft > 0 && setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
  }, [secondsLeft]);

  useEffect(() => {
    setSecondsLeft(getSecondsLeft(smsCodeTime));
  }, [smsCodeTime]);

  return secondsLeft;
};
