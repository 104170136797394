import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { Flex, getMinWidthMediaQuery, H3, useMediaQuery } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../../constants';
import { getTranslation } from '../../../../../helpers/utils';
import { useGetNotificationTypes } from '../../../../../hooks/useGetNotificationTypes';
import { useGetProfile } from '../../../../../hooks/useGetProfile';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser, ThunkUser, updateTelegram } from '../../../../../store/redux-slices/userSlice';
import { ClientNotificationType } from '../../../../../types';
import { isTelegramNotification } from '../../helpers';
import { NotificationItem } from './NotificationItem';
import { TelegramPopup } from './TelegramPopup';

export const Notifications = () => {
  const { t } = useTranslation();
  const { user, profile } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const [isPopup, setPopup] = useState(false);
  const [params, setParams] = useState<Nullable<{ id: string; type: string; enabled: boolean }>>(null);
  const [telegramType, setTelegramType] = useState<'appointment' | 'marketing' | null>(null);

  const { data, types } = useGetNotificationTypes();
  // need for pooling telegram
  const { isPolling, setPolling } = useGetProfile();

  const isTelegram = !!profile.telegram;
  const notifications = profile.accountNotification;

  const handleTelegramConnect = () => {
    window.open(`${process.env.REACT_APP_TELEGRAM_URL}?start=${user.userId}_${telegramType}`, '_blank');
    setPopup(false);
    setPolling(true);
  };

  const handleTelegramDisconnect = () => {
    if (params) {
      dispatch(ThunkUser.updateNotification(params))
        .unwrap()
        .then(() => {
          dispatch(updateTelegram(null));
          setPopup(false);
          setParams(null);
        });
    }
  };

  const handleChangeTelegram = (id: string, type: string, checked: boolean) => {
    const telegramOptions = notifications.filter(isTelegramNotification);
    if (telegramOptions.length === 2 || (telegramOptions.length !== 0 && checked)) {
      dispatch(ThunkUser.updateNotification({ id, type, enabled: checked }));
    } else {
      setParams({ id, type, enabled: checked });
      setTelegramType(type === ClientNotificationType.TelegramAppointment ? 'appointment' : 'marketing');
      setPopup(true);
    }
  };

  const handleChange = async (id: string, type: string, checked: boolean) => {
    if (isTelegramNotification(type)) {
      handleChangeTelegram(id, type, checked);
    } else {
      dispatch(ThunkUser.updateNotification({ id, type, enabled: checked }));
    }
  };

  return (
    <>
      <Flex flexDirection="column" mb="60px">
        <H3 mb="60px">{t('profile.notifications')}</H3>
        {types.map(type => (
          <NotificationItem
            key={type}
            type={type}
            id={data[type].id}
            title={getTranslation(data[type].title, language)}
            info={getTranslation(data[type].descr, language)}
            disabled={isPolling && isTelegramNotification(type)}
            checked={notifications.includes(type)}
            onChange={handleChange}
            isLarge={isLarge}
          />
        ))}
      </Flex>
      {isPopup && (
        <TelegramPopup
          isTelegram={isTelegram}
          setPopup={setPopup}
          connect={handleTelegramConnect}
          disconnect={handleTelegramDisconnect}
        />
      )}
    </>
  );
};
