import { useTranslation } from 'react-i18next';
import { Flex, getMinWidthMediaQuery, SegmentTab, useMediaQuery } from '@beauty/beauty-market-ui';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { RouterUrl } from '../../../routes/routes';
import { StyledButton, TariffSubtitle, TariffTitle } from '../style';

export const TariffPlansTitle = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { business } = useGetUrl(RouterUrl.AccountSetting);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  return (
    <Flex flexDirection="column" gap={isLarge ? '40px' : '30px'} alignItems="center">
      <TariffTitle type="large">{t('business.tariffPlans.title')}</TariffTitle>
      <TariffSubtitle large>{t('business.tariffPlans.subtitle')}</TariffSubtitle>
      <SegmentTab
        tabs={[t('business.tariffPlans.filter.monthly'), t('business.tariffPlans.filter.annually')]}
        activeTab={value}
        onToggle={onChange}
      />
      <StyledButton design="primary" onClick={() => window.open(business)}>
        {t('business.tariffPlans.button')}
      </StyledButton>
    </Flex>
  );
};
