import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { Nullable } from 'tsdef';
import {
  Button,
  Flex,
  BodySmall,
  BottomSheet,
  BodyLarge,
  H6,
  getRatingStars,
  colors,
  Avatar,
} from '@beauty/beauty-market-ui';
import { Anonymous } from '../../../assets';
import { MAX_STARS_COUNT } from '../../../constants';
import { TeamMateType } from '../../../types';
import { SpecOptionWrapper, TextWrapper } from '../style';
import { FilterMobileItem } from './FilterMobileitem';

type FilterBottomSheetProps = {
  isOpen: boolean;
  isReset: boolean;
  setOpen: (isOpen: boolean) => void;
  setRating: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  setSorting: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  setSpecialist: React.Dispatch<React.SetStateAction<Nullable<string>>>;
  specialists: TeamMateType[];
};

export const FilterBottomSheet = ({
  isOpen,
  isReset,
  setOpen,
  setRating,
  setSorting,
  setSpecialist,
  specialists,
}: FilterBottomSheetProps) => {
  const { t } = useTranslation();
  const [ratingValue, setRatingValue] = useState<Nullable<number>>(null);
  const [sortingValue, setSortingValue] = useState<Nullable<number>>(null);
  const [specialistValue, setSpecialistValue] = useState<number>(0);

  const specialistOptions = specialists
    ? [null, ...specialists.map(spec => spec.id)].map(specId => {
        const specialist = specialists.find(spec => spec.id === specId);
        return {
          id: specId,
          title: specId ? specialist!.name : t('organisation.rating.allSpecialists'),
          icon: specId && specialist!.avatarUrl ? specialist?.avatarUrl : <Anonymous />,
        };
      })
    : [];

  const handleApply = () => {
    setRating(ratingValue);
    setSorting(sortingValue);
    setSpecialist(specialistOptions[specialistValue].id);
    setOpen(false);
  };

  const content = (
    <Flex pt="24px" flexDirection="column" gap="24px">
      <Flex flexDirection="column" gap="16px" width="100%">
        <H6>{t('organisation.rating.date')}</H6>
        <Flex flexDirection="column" gap="8px">
          {['newest', 'oldest'].map((item, index) => (
            <FilterMobileItem
              key={item}
              id={index}
              body={<BodySmall large>{t(`organisation.rating.${item}`)}</BodySmall>}
              setValue={setSortingValue}
              isChecked={sortingValue === index}
            />
          ))}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="16px" width="100%">
        <H6>{t('organisation.rating.rating')}</H6>
        <Flex flexDirection="column" gap="8px">
          {range(MAX_STARS_COUNT, 0, -1).map(item => (
            <FilterMobileItem
              key={item}
              id={item}
              body={
                <Flex alignItems="center">
                  <BodyLarge large lowline mr="8px">
                    {item}
                  </BodyLarge>
                  <Flex gap="6px">{getRatingStars(item, '24', colors.grey.light, colors.orange.standard)}</Flex>
                </Flex>
              }
              setValue={setRatingValue}
              isChecked={item === ratingValue}
            />
          ))}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="16px" width="100%">
        <H6>{t('organisation.rating.specialist')}</H6>
        <Flex flexDirection="column" gap="8px">
          {specialistOptions?.map((item, index) => (
            <FilterMobileItem
              key={item.id}
              id={index}
              body={
                <SpecOptionWrapper>
                  <Avatar size="xxs" url={item.icon} />
                  <TextWrapper>
                    <BodySmall>{item.title}</BodySmall>
                  </TextWrapper>
                </SpecOptionWrapper>
              }
              setValue={setSpecialistValue}
              isChecked={index === specialistValue}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );

  const FooterBody = (
    <Button size="large" width="100%" onClick={handleApply}>
      {t('button.apply')}
    </Button>
  );

  const someProps = {
    isOpen,
    label: t('organisation.rating.filter'),
    descriptor: t('organisation.rating.filterInfo'),
    handleClose: () => setOpen(false),
    FooterBody,
  };

  useEffect(() => {
    if (isReset) {
      setRatingValue(null);
      setSortingValue(null);
      setSpecialistValue(0);
    }
  }, [isReset]);

  return <BottomSheet {...someProps} content={content} detent="content-height" />;
};
