import { RouterUrl } from '../routes/routes';

export const crumbsPages = [
  `${RouterUrl.Organisation}/`,
  `${RouterUrl.Booking}/`,
  RouterUrl.AllOffers,
  `${RouterUrl.ClientAppointments}/`,
  `${RouterUrl.ClientProfile}/`,
  `${RouterUrl.ClientFavourites}/`,
];
