import { Dispatch, forwardRef, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { UploadPhotos, Alert, AlertTypes, Caption, colors } from '@beauty/beauty-market-ui';
import { MAX_REVIEW_PHOTO_COUNT } from '../../constants';
import { sendAppointmentReview } from '../../helpers/review';
import { checkPhotosValid } from '../../helpers/utils';
import { AppointmentReviewPayload, BadgeType } from '../../types/review';
import { FormikTextfield } from '../functional/formik/formik-textfield/FormikTextfield';
import { FormikWrapper } from './style';

type FeedbackFormProps = {
  appointmentId: string;
  orgScore: number;
  specScore: number;
  salonCards: BadgeType[];
  specialistCards: BadgeType[];
  salonBadges: any;
  specBadges: any;
  setLoading: (isLoading: boolean) => void;
  setSecondsLeft: Dispatch<SetStateAction<number>>;
  setResponseStatus: Dispatch<SetStateAction<string | null>>;
  setValid: (isValid: boolean) => void;
};

export const FeedbackForm = memo(
  forwardRef((props: FeedbackFormProps, ref) => {
    const {
      appointmentId,
      orgScore,
      specScore,
      salonCards,
      specialistCards,
      salonBadges,
      specBadges,
      setLoading,
      setSecondsLeft,
      setResponseStatus,
      setValid,
    } = props;
    const { t } = useTranslation();
    const [images, setImages] = useState<(File | string)[]>([]);
    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState<null | string>('');

    const handleUpload = useCallback(
      (photos: File[]) => {
        const errorMessage = checkPhotosValid([...images, ...photos], t, MAX_REVIEW_PHOTO_COUNT);
        setError(errorMessage);
        !errorMessage ? setImages([...images, ...photos]) : setTimeout(() => setError(''), 4000);
      },
      [images],
    );

    const handleDelete = useCallback(
      (photo: File | string) => {
        setImages(images.filter(image => image !== photo));
      },
      [images],
    );

    const handleReviewSubmit = async () => {
      setLoading(true);
      const payload: AppointmentReviewPayload = {
        appointmentId,
        orgScore,
        specScore: specScore || undefined,
        orgBadges: salonCards
          .filter((_card, index) => salonBadges[index])
          .map(card => card.id)
          .join(','),
        specBadges: specScore
          ? specialistCards
              .filter((_card, index) => specBadges[index])
              .map(card => card.id)
              .join(',')
          : undefined,
        photos: images as File[],
        summary: feedback || undefined,
      };
      const response = await sendAppointmentReview(payload);
      setResponseStatus(response.statusCode ? 'error' : 'success');
      setInterval(() => setSecondsLeft(seconds => seconds - 1), 1000);
      response && setLoading(false);
    };

    return (
      <FormikWrapper>
        <Formik
          innerRef={ref as (instance: FormikProps<{ feedback: string }> | null) => void}
          initialValues={{ feedback: '' }}
          validationSchema={Yup.object({
            feedback:
              orgScore < 3 || (specScore > 0 && specScore < 3)
                ? Yup.string()
                    .min(10, t('validation.minLength10'))
                    .max(1000, t('validation.maxLength1000'))
                    .required(t('validation.notEmpty'))
                : Yup.string().min(10, t('validation.minLength10')).max(1000, t('validation.maxLength1000')),
          })}
          onSubmit={handleReviewSubmit}
          validateOnMount
        >
          {({ values, isValid }) => {
            useEffect(() => setFeedback(values.feedback), [values]);
            useEffect(() => setValid(isValid), [isValid]);
            return (
              <Form>
                <FormikTextfield
                  id="feedback"
                  name="feedback"
                  design="white"
                  placeholder={t('rating.typeHere')}
                  rows={4}
                  infoCaption={`${feedback.length}/1000`}
                />
              </Form>
            );
          }}
        </Formik>
        <UploadPhotos max={MAX_REVIEW_PHOTO_COUNT} images={images} onUpload={handleUpload} onDelete={handleDelete} />
        {error ? (
          <>
            <Caption color={colors.red.standard}>{error}</Caption>
            <Alert isAlertVisible={!!error} type={AlertTypes.ERROR} title={t('alert.error')} subtitle={error} />
          </>
        ) : (
          <Caption color={colors.grey.dark}>{t('uploadPhoto.restrictionsText')}</Caption>
        )}
      </FormikWrapper>
    );
  }),
);
