import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';

type EmailVerificationParams = {
  token?: string;
};

export const emailVerification = async (params: EmailVerificationParams) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.EmailVerification,
    data: { ...params },
  });

  return response ? response.data : error?.data;
};
