import { Flex, BodySmall, H6 } from '@beauty/beauty-market-ui';
import { GreyBodySmall, OldPrice } from '../style';

interface SelectedServiceProps {
  title: string;
  description: string;
  price: string;
  oldPrice?: string | null;
}

const SelectedService = ({ title, description, oldPrice, price }: SelectedServiceProps) => (
  <Flex flexDirection="column" justifyContent="center" width="inherit">
    <Flex justifyContent="space-between">
      <BodySmall margin={0}>{title}</BodySmall>
      <H6 margin={0}>{price}</H6>
    </Flex>
    <Flex justifyContent="space-between">
      <GreyBodySmall margin={0} lowline>
        {description}
      </GreyBodySmall>
      {oldPrice && <OldPrice margin={0}>{oldPrice}</OldPrice>}
    </Flex>
  </Flex>
);

export default SelectedService;
