import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { H2, Button, ArrowLeftIcon, useMediaQuery, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';
import { FAQHeaderWrapper } from '../style';

const FAQHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = !useMediaQuery(getMinWidthMediaQuery('md'));

  return (
    <FAQHeaderWrapper>
      <Button
        design="secondary"
        onClick={() => navigate(RouterUrl.FAQ)}
        prefix={<ArrowLeftIcon height="15" width="15" />}
        size={isMobile ? 'extraSmall' : 'medium'}
      />

      <H2 ml="24px">{t('faq.faq')}</H2>
    </FAQHeaderWrapper>
  );
};

export default FAQHeader;
