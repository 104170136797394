import { Caption, Slider, colors } from '@beauty/beauty-market-ui';
import { FilterType } from '../../../constants';
import { useGetTopCategories } from '../../../hooks/useGetTopCategories';
import { useAppDispatch } from '../../../store/hooks';
import { setFilterType, updateServiceValue } from '../../../store/redux-slices/searchSlice';
import { IconFlex, ServiceBlock, Wrapper } from './style';

export type IconsBlockProps = {
  onClick: (id: string) => void;
  selected?: string;
  fromTop?: boolean;
  isBackground?: boolean;
};

export const ServiceIconsBlock = ({ selected, fromTop, onClick, isBackground = false }: IconsBlockProps) => {
  const { topCategoriesWithIcons } = useGetTopCategories();
  const dispatch = useAppDispatch();

  return (
    <Wrapper isBackground={isBackground}>
      <Slider
        position="relative"
        top="40px"
        mt={['16px', '16px', '16px', `${fromTop ? '72px' : 0}`]}
        mb={fromTop ? ['16px', '16px', '16px', 0] : 0}
        width="100%"
        inlineSlider
        slides={topCategoriesWithIcons.map(icon => {
          const isSelected = selected === icon.id;
          return (
            <ServiceBlock
              key={icon.id}
              isSelected={isSelected}
              onClick={() => {
                dispatch(setFilterType(FilterType.CATEGORY));
                dispatch(updateServiceValue(''));
                icon.id && onClick(icon.id);
              }}
            >
              <IconFlex isSelected={isSelected}>{icon.icon}</IconFlex>
              <Caption large lowline color={isSelected ? colors.blue.standard : colors.black.standard}>
                {icon.name}
              </Caption>
            </ServiceBlock>
          );
        })}
      />
    </Wrapper>
  );
};
