import { apiRequest } from '../api/api';
import { UserEndpoints } from '../api/endpoints/endpoints';

type SendJoinUsRequestParams = {
  email: string;
  fullName: string;
  client: string;
  language: string;
};

export const sendJoinUsRequest = async (params: SendJoinUsRequestParams) => {
  const [, response] = await apiRequest.post({
    endpoint: UserEndpoints.JoinUs,
    data: { ...params, email: params.email.toLowerCase() },
  });
  return response?.data;
};
