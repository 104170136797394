import { Fragment, useMemo } from 'react';
import { Accordion, Separator } from '@beauty/beauty-market-ui';
import { useGetFAQCategories } from '../../../hooks/useGetFAQCategories';
import { FAQQuestionsWrapper } from '../style';
import { FAQQuestion, FAQQuestionsVariants } from '../types';

type DisplayedQuestionsProps = {
  categoriesType: FAQQuestionsVariants;
  activeCategoryId: string;
};
const isNotLast = (arrayLength: number, checkedIndex: number) => arrayLength !== checkedIndex + 1;

export const DisplayedQuestions = ({ categoriesType, activeCategoryId }: DisplayedQuestionsProps) => {
  const { categories } = useGetFAQCategories(categoriesType);

  const displayedQuestions: FAQQuestion[] = useMemo(() => {
    if (!categories) return [];
    const allQuestions = categories.reduce((acc: FAQQuestion[], rec) => [...acc, ...rec.questions], []);
    if (activeCategoryId === '0') return allQuestions;

    return categories.find(category => category.id === activeCategoryId)?.questions ?? [];
  }, [categories, activeCategoryId]);

  return (
    <FAQQuestionsWrapper>
      {displayedQuestions?.map((question: FAQQuestion, index) => (
        <Fragment key={question.id}>
          <Accordion text={question.title}>{question.answer}</Accordion>
          {isNotLast(displayedQuestions.length, index) && <Separator />}
        </Fragment>
      ))}
    </FAQQuestionsWrapper>
  );
};
