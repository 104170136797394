import { useTranslation } from 'react-i18next';
import { Flex, H4 } from '@beauty/beauty-market-ui';
import { CurrencySection } from './CurrencySection';
import { LanguageSection } from './LanguageSection';

const RegionSettings = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" mb={['56px', '56px', '56px', '80px']}>
      <H4 mb="16px">{t('profile.regionSettings')}</H4>
      <CurrencySection />
      <LanguageSection />
    </Flex>
  );
};

export { RegionSettings };
