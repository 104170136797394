import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
import internationalization from './i18n';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store/store';

const root = createRoot(document.getElementById('root') as HTMLElement);

// TODO PersistGate and withTranslation doesn't work correctly together. This is workaround. Need to find correct way to solve the issue
internationalization.then(() =>
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  ),
);

reportWebVitals();
