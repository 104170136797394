export const privacyTitles = [
  'introduction',
  'weCollect',
  'use',
  'sharing',
  'securityMeasures',
  'howLong',
  'userRights',
  'cookieNotice',
  'googleData',
  'updates',
  'contactUs',
];

export const privacySectionsCount = [2, 5, 1, 1, 1, 2, 1, 4, 6, 1, 1];
