import { MapPointLocationWrapper, MapPointLocationCenter } from './style';

interface ClusterMarkerProps {
  lat?: number;
  lng?: number;
  zIndex?: number;
  onClick?: () => void;
}

const MapPointLocation = ({ onClick }: ClusterMarkerProps) => (
  <MapPointLocationWrapper onClick={onClick}>
    <MapPointLocationCenter />
  </MapPointLocationWrapper>
);

export default MapPointLocation;
