import { H3, BodySmall, ArrowRightIcon } from '@beauty/beauty-market-ui';
import { ButtonWrapper, StyledButton } from '../style';

type LoginButtonProps = {
  title: string;
  info: string;
  onClick: () => void;
  isClient?: boolean;
};

export const LoginButton = ({ isClient = false, title, info, onClick }: LoginButtonProps) => (
  <StyledButton
    design={isClient ? 'primary' : 'secondary'}
    suffix={<ArrowRightIcon width="20" height="20" />}
    onClick={onClick}
  >
    <ButtonWrapper isClient={isClient}>
      <H3>{title}</H3>
      <BodySmall textAlign="start">{info}</BodySmall>
    </ButtonWrapper>
  </StyledButton>
);
