import styled from 'styled-components';
import { mediaQueries, Button, Link, Flex, theme } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: 40px;
  width: 100%;
  ${mediaQueries.md} {
    padding: 40px 220px;
  }

  box-sizing: border-box;
`;

export const TitleWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: end;
  box-sizing: border-box;
`;

export const CardsWrapper = styled(Flex)`
  gap: 4px;
  flex-direction: column;
  & > div {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  padding: 4px 2px !important;
`;

export const MenuWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  gap: 4px !important;
`;

export const CardWrapper = styled(Flex)<{ isRead: boolean }>`
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  border-radius: 12px;
  padding: 16px 0;

  ${mediaQueries.md} {
    padding: 16px 20px;
  }

  ${({ isRead }) => `background-color: ${isRead ? theme.colors.white.standard : theme.colors.grey.light}`};

  :hover {
    cursor: pointer;
    background-color: ${theme.colors.white.standard};
    ${theme.mediaQueries.md} {
      background-color: ${theme.colors.grey.light};
    }
  }

  & > div:first-child {
    min-width: 40px;
  }
`;

export const BlockWrapper = styled(Flex)`
  gap: 10px;
`;
