import { forwardRef, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Caption, Flex, NotifyStatus, useNotify, colors } from '@beauty/beauty-market-ui';
import { FormikPasswordInput } from '../../../../../components/functional/formik/formik-password-input/FormikPasswordInput';
import { getNotifyContent } from '../../../../../helpers';
import { changePassword } from '../../../../../helpers/profile';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUserId } from '../../../../../store/redux-slices/userSlice';
import { UserActions } from '../../../../../types/user';
import {
  ChangePasswordFormFields,
  ChangePasswordFormType,
  initialValues,
  validationSchema,
} from '../Sidebars/ChangePasswordSidebar/ChangePassword.definitions';
import { StyledCaption } from '../Sidebars/ChangePasswordSidebar/style';

type Props = {
  onClose: () => void;
  onDisable: (value: boolean) => void;
};

export const ChangePasswordForm = memo(
  forwardRef(({ onClose, onDisable }: Props, ref) => {
    const { t } = useTranslation();
    const id = useAppSelector(selectUserId);
    const notify = useNotify();
    const content = [t('profile.password.letters'), t('profile.password.numbers')];

    const onSubmit = async (data: ChangePasswordFormType, { setFieldError, setSubmitting }) => {
      const { oldPassword, newPassword } = data;
      const { error } = await changePassword({ id, data: { oldPassword, newPassword } });

      if (error) {
        if (error === 401) {
          setFieldError(ChangePasswordFormFields.Old, t('validation.wrongPassword'));
          notify(getNotifyContent(NotifyStatus.ERROR, UserActions.ChangePassword, t, t('validation.wrongPassword')));
        } else {
          notify(getNotifyContent(NotifyStatus.ERROR, UserActions.ChangePassword, t));
        }
      } else {
        notify(getNotifyContent(NotifyStatus.SUCCESS, UserActions.ChangePassword, t));
        onClose();
      }
      setSubmitting(false);
    };

    const formikContextValue = {
      initialValues,
      validateOnMount: false,
      onSubmit,
      validationSchema: validationSchema(t),
    };

    return (
      <Formik innerRef={ref as (instance: FormikProps<ChangePasswordFormType> | null) => void} {...formikContextValue}>
        {({ dirty, isValid, isSubmitting }) => {
          useEffect(() => {
            onDisable(!dirty || !isValid || isSubmitting);
          }, [dirty, isValid, isSubmitting]);

          return (
            <Flex gap="16px" flexDirection="column">
              <FormikPasswordInput
                id={ChangePasswordFormFields.Old}
                name={ChangePasswordFormFields.Old}
                autoComplete="off"
                type="password"
                placeholder={t('profile.password.oldPassword')}
              />
              <Flex gap="4px" flexDirection="column">
                <FormikPasswordInput
                  id={ChangePasswordFormFields.New}
                  name={ChangePasswordFormFields.New}
                  autoComplete={ChangePasswordFormFields.New}
                  type="password"
                  placeholder={t('profile.password.newPassword')}
                />
                <Flex gap="4px" flexDirection="column" padding="0 16px">
                  <Caption lowline color={colors.grey.dark}>
                    {t('profile.password.passwordShouldContain')}
                  </Caption>
                  {content.map(item => (
                    <StyledCaption key={item}>{item}</StyledCaption>
                  ))}
                </Flex>
              </Flex>
              <FormikPasswordInput
                id={ChangePasswordFormFields.Repeat}
                name={ChangePasswordFormFields.Repeat}
                autoComplete={ChangePasswordFormFields.Repeat}
                type="password"
                placeholder={t('profile.password.repeatPassword')}
              />
            </Flex>
          );
        }}
      </Formik>
    );
  }),
);
