import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import {
  Button,
  Flex,
  Popup,
  Separator,
  BottomSheet,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../../routes/routes';
import { GappedFlex } from '../../../../style';
import {
  BodyButton,
  bottomSheetDescriptor,
  bottomSheetLabel,
  EventStatus,
  popupBody,
  popupInfo,
  popupTitle,
  SendEmailInput,
} from '../constants';
import { StyledBottomSheet } from '../style';
import { FullStatus, PopupBlockProps } from '../types';
import { sendEmailValidationSchema, initialSendEmailValues } from './Appointments.definitions';

const PopupBlock = ({
  event,
  whatToDo,
  isSendPopup,
  setIsPopup,
  setIsSendPopup,
  handleCancelAppointment,
  handleRebookAppointment,
  handleRescheduleAppointment,
  handleAddToCalendar,
  handleWriteReview,
  handleSendReceipt,
}: PopupBlockProps) => {
  const { t } = useTranslation();
  const [isSubmitButtonActive, setSubmitButtonActive] = useState(false);
  const navigate = useNavigate();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const popupCancelFooter = (
    <Flex flexDirection="column" width="100%">
      <Separator mb="16px" />
      <GappedFlex gap="16px">
        <Button size="large" width="100%" design="secondary" onClick={() => setIsPopup(false)}>
          {t(`appointments.${popupBody.cancel.leftButton}`)}
        </Button>
        <Button
          size="large"
          width="100%"
          onClick={() => {
            setIsPopup(false);
            whatToDo === 'send' && handleSendReceipt();
            whatToDo === 'cancel' && handleCancelAppointment();
          }}
        >
          {t(`appointments.${popupBody.cancel.rightButton}`)}
        </Button>
      </GappedFlex>
    </Flex>
  );

  const popupManageFooter = useMemo(
    () =>
      (() => {
        switch (event.status) {
          case EventStatus.PAST:
            return (
              <Flex flexDirection="column" alignItems="center" width="100%">
                <BodyButton
                  onClick={() => {
                    setIsPopup(false);
                    handleRebookAppointment();
                  }}
                  label={t(`appointments.${popupBody.manage.past.topButton}`)}
                />

                {!event.isReviewed && (
                  <BodyButton
                    disabled={false}
                    onClick={() => {
                      setIsPopup(false);
                      handleWriteReview();
                    }}
                    label={t(`appointments.${popupBody.manage.past.middleButton}`)}
                  />
                )}

                {/* <BodyButton
                onClick={() => {
                  setIsPopup(false);
                  setIsSendPopup(true);
                }}
                label={t(`appointments.${popupBody.manage.past.bottomButton}`)}
              /> */}
              </Flex>
            );
          case EventStatus.CONFIRMED:
          case EventStatus.PENDING:
            return (
              <Flex flexDirection="column" alignItems="center" width="100%">
                <BodyButton
                  onClick={() => {
                    setIsPopup(false);
                    handleRescheduleAppointment();
                  }}
                  label={t(`appointments.${popupBody.manage.confirmed.topButton}`)}
                />

                <BodyButton
                  onClick={() => {
                    setIsPopup(false);
                    handleAddToCalendar();
                  }}
                  label={t(`appointments.${popupBody.manage.confirmed.bottomButton}`)}
                />
              </Flex>
            );
          default:
            return (
              <Flex flexDirection="column" alignItems="center" width="100%">
                <BodyButton
                  onClick={() => {
                    setIsPopup(false);
                  }}
                  label={t(`appointments.${popupBody.manage.cancelled.topButton}`)}
                />
              </Flex>
            );
        }
      })(),
    [event.status, event.orgService.organization.id],
  ); // TODO replace 2nd in array with reviewId

  const SendPopupFooterBody = (
    <GappedFlex gap="16px" width="100%">
      <Button size="large" width="50%" design="secondary" px="18px" onClick={() => setIsSendPopup(false)}>
        {t(`appointments.downloadReceipt`)}
      </Button>
      <Button
        size="large"
        width="50%"
        px="18px"
        type="submit"
        disabled={!isSubmitButtonActive}
        onClick={() => {
          setIsSendPopup(false);
          handleSendReceipt();
        }}
      >
        {t(`appointments.sendViaEmail`)}
      </Button>
    </GappedFlex>
  );

  const footerBody = useMemo(
    () => (whatToDo === 'cancel' || whatToDo === 'send' ? popupCancelFooter : popupManageFooter),
    [whatToDo, event],
  );

  const formikContextValue = {
    initialValues: initialSendEmailValues,
    validationSchema: sendEmailValidationSchema,
    onSubmit: () => {
      alert('submit');
      navigate(RouterUrl.AllOffers);
    },
    validateOnMount: true,
  };

  return (
    <>
      {isLarge ? (
        <Popup
          open
          title={popupTitle(whatToDo)}
          info={popupInfo(whatToDo)}
          FooterBody={footerBody}
          handleClose={() => setIsPopup(false)}
        />
      ) : (
        <StyledBottomSheet
          label={bottomSheetLabel(whatToDo)}
          descriptor={bottomSheetDescriptor(whatToDo)}
          isOpen
          isCancel={whatToDo !== 'manage'}
          handleClose={() => setIsPopup(false)}
          onClose={() => setIsPopup(false)}
          detent="content-height"
          content={whatToDo === 'manage' ? popupManageFooter : popupCancelFooter}
        />
      )}
      {isSendPopup && (
        <Formik {...formikContextValue}>
          {({ isValid, initialValues, values }) => {
            isValid && setSubmitButtonActive(isValid && !_.isEqual(initialValues, values));
            return (
              <Form>
                {isLarge ? (
                  <Popup
                    open
                    title={t('appointments.sendReceipt')}
                    info=""
                    FooterBody={SendPopupFooterBody}
                    handleClose={() => setIsSendPopup(false)}
                  >
                    {SendEmailInput()}
                  </Popup>
                ) : (
                  <BottomSheet
                    label={t('appointments.sendReceipt')}
                    isOpen
                    handleClose={() => setIsSendPopup(false)}
                    onClose={() => setIsSendPopup(false)}
                    detent="content-height"
                    content={SendEmailInput()}
                    FooterBody={SendPopupFooterBody}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export { PopupBlock };
