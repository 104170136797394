import styled, { css } from 'styled-components';
import { Div, Flex, mediaQueries, Link, Separator, Caption, colors } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const FooterWrapper = styled(Div)<{ isHigher: boolean }>`
  position: relative;
  grid-area: footer;
  ${({ isHigher }) =>
    isHigher
      ? css`
          padding-bottom: 84px;
        `
      : css`
          padding-bottom: 0;
        `}
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.white.standard};

  ${mediaQueries.md} {
    max-width: 1060px;
    margin: auto;
    padding-bottom: 84px;
    background-color: transparent;
  }
`;

export const FooterContainer = styled(Flex)`
  position: relative;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: ${zIndex.footerContainer};
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  ${mediaQueries.md} {
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: center;
    backdrop-filter: blur(12px);
  }

  @media screen and (max-width: 1060px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export const BottomFooterWrapper = styled(Flex)`
  width: 100%;

  box-sizing: border-box;

  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (max-width: 375px) {
    align-items: start;
  }

  ${mediaQueries.md} {
    max-width: 1060px;
  }
`;

export const MixFlex = styled(Flex)`
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;

export const StyledLink = styled(Link)`
  @media (max-width: 375px) {
    margin-bottom: 16px !important;
  }
`;

export const StyledSeparator = styled(Separator)`
  ${mediaQueries.md} {
    width: 100%;
    margin-top: -80px !important;
  }
`;

export const StyledCaption = styled(Caption)`
  padding-right: 20px;
  @media (max-width: 379px) {
    padding-right: 15px;
  }
`;
