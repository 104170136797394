import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodyLarge,
  BodySmall,
  Flex,
  Icon,
  NotifyStatus,
  RightIcon,
  Separator,
  useNotify,
} from '@beauty/beauty-market-ui';
import { getLanguageOptions, Language } from '../../../../../constants';
import { getNotifyContent } from '../../../../../helpers';
import { getProfileFromApi, updateAccount } from '../../../../../helpers/profile';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser, updateProfile } from '../../../../../store/redux-slices/userSlice';
import { PatchProfileType, UserActions } from '../../../../../types/user';
import { EditLanguageSidebar } from '../Sidebars/EditLanguageSidebar';

export const LanguageSection = () => {
  const { t } = useTranslation();
  const { user, profile } = useAppSelector(selectUser);

  const notify = useNotify();
  const dispatch = useAppDispatch();

  const language = profile.language || Language.EN;
  const languageOptions = getLanguageOptions(t);
  const languageItem = languageOptions?.data[language] || languageOptions.data.EN;

  const [isOpen, setOpen] = useState(false);

  const handleSubmit = async (value: Language) => {
    const data: PatchProfileType = { accountData: { language: value } };
    const newProfile = { ...profile, language: value };
    const status = await updateAccount(user.userId, data);
    if (status === 200) {
      dispatch(updateProfile(newProfile));
      setOpen(false);
      notify(getNotifyContent(NotifyStatus.SUCCESS, UserActions.ChangeNotificationLanguage, t));
    } else {
      notify(getNotifyContent(NotifyStatus.ERROR, UserActions.ChangeNotificationLanguage, t));
    }
  };

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Flex gap="8px" alignItems="center" cursor="pointer" onClick={() => setOpen(true)} p="12px 0px 13px">
        <Icon p="10px" minWidth="20px" height="20px">
          {languageItem.icon}
        </Icon>
        <Flex flexDirection="column">
          <BodySmall>{t('profile.languageNotifications')}</BodySmall>
          <BodyLarge large>{languageItem.value}</BodyLarge>
        </Flex>
        <Icon width="20px" height="20px" ml="auto">
          <RightIcon />
        </Icon>
      </Flex>
      <Separator />
      {isOpen && <EditLanguageSidebar language={language} onSubmit={handleSubmit} onClose={handleClose} />}
    </>
  );
};
