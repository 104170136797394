import React, { memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { TERMINATION_ARTICLES_COUNT } from '../constants';

// TODO Refactor to enable eslint checks
export const Termination = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.termination.title')}</PrivacyItemTitleWrapper>
        {Array(TERMINATION_ARTICLES_COUNT)
          .fill(0)
          .map((__, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <BodySmall key={i + 1} mb={i === TERMINATION_ARTICLES_COUNT - 1 ? '24px' : '16px'}>
              {t(`termsOfService.termination.article${i + 1}`)}
            </BodySmall>
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
