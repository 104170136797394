import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { Anonymous } from '../../../../assets';
import { TeamMateType } from '../../../../types';
import { SpecialistsRoles } from '../../../../types/specialist';

type SpecialistFilterProps = {
  selectedSpecId: Nullable<string>;
  onSelect: (selectedOptionId: Nullable<string>) => void;
  team: TeamMateType[];
};

export const SpecialistFilter = ({ selectedSpecId, onSelect, team }: SpecialistFilterProps) => {
  const { t } = useTranslation();

  const specialists = team.filter(member => member.role === SpecialistsRoles.SPECIALIST);

  const specialistOptions = [null, ...specialists.map(spec => spec.id)].map(specId => {
    const specialist = specialists.find(spec => spec.id === specId);
    return {
      id: specId,
      title: specId ? specialist!.name : t('organisation.rating.allSpecialists'),
      description: specId ? specialist?.description : '',
      icon: specId && specialist!.avatarUrl ? specialist?.avatarUrl : <Anonymous />,
    };
  });

  return (
    <FilterDropdown
      options={specialistOptions}
      selectedOptionId={selectedSpecId}
      onSelect={onSelect}
      onCross={selectedSpecId ? () => onSelect(null) : undefined}
      isForBM
    />
  );
};
