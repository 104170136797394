import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import Image from '../../assets/contactUs.png';
import { Container } from '../../components';

export const StyledFlex = styled(Flex)`
  width: 100%;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 46px;
  ${mediaQueries.md} {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
`;

export const StyledContainer = styled(Container)`
  margin-top: 72px;
  flex-direction: column;
  height: 1000px;
  ${mediaQueries.md} {
    background-image: url(${Image});
    background-size: 33%;
    background-repeat: no-repeat;
    background-position: left 60px top 100px;
  }
`;
