import styled from 'styled-components';
import { Div, Flex, Link, colors } from '@beauty/beauty-market-ui';

export const ButtonWrapper = styled(Div)`
  color: ${colors.white.standard};
  background: ${colors.black.standard};
  width: 160px;
  height: 48px;
  border-radius: 10px;
  margin-bottom: 8px;

  @media screen and (min-width: 992px) {
    width: 180px;
  }
`;

export const StyledLink = styled(Link)`
  padding-bottom: 10px;
  color: red;
  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    padding-bottom: 14px;
  }
`;

export const StyledFlex = styled(Flex)`
  gap: 8px;
  box-sizing: border-box;
  flex-direction: column;
  width: 160px;
  @media screen and (min-width: 375px) and (max-width: 992px) {
    flex-direction: row;
    width: 100%;
  }
`;
