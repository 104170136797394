import React, { useEffect } from 'react';
// TODO: Fix eslint issue
// eslint-disable-next-line
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { ThemeProvider } from 'styled-components';
import { BMTheme, theme } from '@beauty/beauty-market-ui';
import Router from './routes/Router';
import { AppWrapper } from './style';

ReactGA.initialize('G-BQ4GX4GDZD');
declare global {
  interface Window {
    Telegram: any;
  }
}
const App = () => {
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      window.Telegram.WebApp.disableVerticalSwipes();
    }

    document.body.style.height = '100vh';
    document.documentElement.style.height = '100vh';
  }, []);

  if (window.Telegram?.WebApp) {
    window.Telegram.WebApp.expand();
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://tma.beautymarket.co.il/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.LIGHT }}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: 'tonwallet',
            name: 'TON Wallet',
            imageUrl: 'https://wallet.ton.org/assets/ui/qr-logo.png',
            aboutUrl: 'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
            universalLink: 'https://wallet.ton.org/ton-connect',
            jsBridgeKey: 'tonwallet',
            bridgeUrl: 'https://bridge.tonapi.io/bridge',
            platforms: ['chrome', 'android'],
          },
        ],
      }}
      actionsConfiguration={{
        twaReturnUrl: 'https://tma.beautymarket.co.il/ ',
      }}
    >
      <BMTheme>
        <ThemeProvider {...{ theme }}>
          <AppWrapper>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </AppWrapper>
        </ThemeProvider>
      </BMTheme>
    </TonConnectUIProvider>
  );
};

export default withTranslation()(App);
