import { NavigateFunction } from 'react-router-dom';
import _ from 'lodash';
import { RouterUrl } from '../routes/routes';

type RedirectState = {
  redirectedFrom: string;
  searchParams: string;
  shouldOpenSidebar: boolean;
  organisation: any; // TODO Type properly after orginisation setup
  rest: any;
};

const useRedirectAfterLogin = (navigate: NavigateFunction, state: RedirectState) => {
  const isRedirectedFromBooking = _.has(state, 'redirectedFrom') && state.redirectedFrom === 'booking';

  const url = isRedirectedFromBooking
    ? `${RouterUrl.Booking}/${state.organisation.id}/${state.searchParams}`
    : RouterUrl.Homepage;
  const redirectData = {
    organisation: _.has(state, 'organisation') ? state.organisation : {},
    shouldOpenSidebar: true,
  };

  navigate(url, { state: isRedirectedFromBooking ? redirectData : {} });
};

export default useRedirectAfterLogin;
