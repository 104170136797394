import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Nullable } from 'tsdef';
import {
  H6,
  GearIcon,
  CheckCircleIcon,
  Link,
  Flex,
  Button,
  getMinWidthMediaQuery,
  useMediaQuery,
  Separator,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../constants';
import { getNotificationCard, markAllAsRead } from '../../helpers/notifications';
import { useGetNotifications } from '../../hooks/useGetNotifications';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, setNotifications, setActiveNotificationId } from '../../store/redux-slices/userSlice';
import { Notification } from '../../types/notifications';
import { NoData } from '../NoData';
import { CardsWrapper, FooterWrapper, StyledLink, TitleWrapper, Wrapper } from './style';

type NotificationsPopupProps = {
  title: string;
  items: Notification[];
  setRead: (index: number) => void;
  setNotificationsPopup: (isPopup: boolean) => void;
  isOpen: boolean;
};

export const NotificationsPopup = forwardRef((props: NotificationsPopupProps, ref) => {
  const { title = 'Notifications', items, setRead, setNotificationsPopup, isOpen } = props;
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUser);
  const [isFetch, setFetch] = useState(false);
  const { nots, isLoading } = useGetNotifications(user.userId, isFetch);
  const dispatch = useAppDispatch();

  const handleMarkAll = async () => {
    const response = await markAllAsRead(user.userId);
    response && setFetch(true);
  };

  useEffect(() => {
    if (!isLoading) {
      nots && dispatch(setNotifications({ new: nots.new, archive: nots.archive }));
      setFetch(false);
    }
  }, [isLoading]);

  const setRatingOpen = (id: Nullable<string>) => {
    dispatch(setActiveNotificationId(id));
  };

  return isOpen ? (
    <Wrapper ref={ref}>
      <TitleWrapper>
        <H6>{title}</H6>
        <Link design="gray" href={`${RouterUrl.ClientProfile}/${user.userId}`}>
          <GearIcon width="20px" height="20px" />
        </Link>
      </TitleWrapper>
      <CardsWrapper>
        {items?.length ? (
          <Flex flexDirection="column" gap="4px" px={[0, 0, 0, '4px']}>
            {items.map((item, index) =>
              getNotificationCard(
                t,
                navigate,
                user.userId,
                item,
                index,
                setRead,
                setRatingOpen,
                language,
                dispatch,
                setNotificationsPopup,
              ),
            )}
          </Flex>
        ) : (
          <NoData title={t('notifications.noDataTitle')} info={t('notifications.noDataInfo')} />
        )}
      </CardsWrapper>
      {!isMobile && <Separator />}
      {!!items?.length && (
        <FooterWrapper>
          <StyledLink
            design={isMobile ? 'blue' : 'gray'}
            size={isMobile ? 's' : 'xs'}
            icon={<CheckCircleIcon width="16px" height="16px" />}
            onClick={handleMarkAll}
          >
            {t('notifications.markAll')}
          </StyledLink>
          <Button
            size={isMobile ? 'small' : 'extraSmall'}
            onClick={() => {
              setNotificationsPopup(false);
              navigate(RouterUrl.Notifications);
            }}
          >
            {t('notifications.viewAll')}
          </Button>
        </FooterWrapper>
      )}
    </Wrapper>
  ) : null;
});

NotificationsPopup.displayName = 'NotificationsPopup';
