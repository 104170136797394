import styled from 'styled-components';
import { Flex, mediaQueries, Div } from '@beauty/beauty-market-ui';

export const StyledContainer = styled(Flex)`
  gap: 16px;
  ${mediaQueries.sm} {
    gap: 8px;
  }
`;

export const WrapperInput = styled(Div)`
  width: 100%;
  margin-left: 8px;
  @media (max-width: 395px) {
    width: 70%;
  }
`;

export const StyledFlex = styled(Flex)`
  width: 100%;

  form {
    width: 100%;
  }
`;
