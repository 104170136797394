import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints } from '../api/endpoints/endpoints';
import { FoundResponse } from '../types/general';

export const useGetOrganisationOfHead = (headOrgId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<FoundResponse[]>([]);
  useEffect(() => {
    let isOrganisationsLoading = true;

    const fetchOrganisations = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: HeadOrganisationEndpoints.PublishedOrganisations,
        endpointParams: headOrgId,
      });

      if (isOrganisationsLoading) {
        setOrganisations(response?.data);
        setIsLoading(false);
      }
    };

    headOrgId && fetchOrganisations();

    return () => {
      isOrganisationsLoading = false;
    };
  }, [headOrgId]);

  return {
    isLoading,
    organisations,
  };
};
