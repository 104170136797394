import { Caption } from '@beauty/beauty-market-ui';
import { BadgeWrapper } from './style';

type ReviewBadgeProps = {
  url: string;
  title: string;
  onClick: () => void;
  isActive: boolean;
};

export const ReviewBadge = ({ url, title, onClick, isActive }: ReviewBadgeProps) => (
  <BadgeWrapper onClick={onClick} isActive={isActive}>
    <img width="68px" height="68px" src={url} alt={title} />
    <Caption textAlign="center">{title}</Caption>
  </BadgeWrapper>
);
