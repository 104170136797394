import { TFunction, useTranslation } from 'react-i18next';
import { BodyLarge } from '@beauty/beauty-market-ui';
import { InfoCard } from './components/InfoCard';
import { CardsWrapper, HelpTitle, HelpWrapper } from './style';

const infoCards = (t: TFunction<'translation', undefined>) => [
  {
    digits: '15',
    unit: '%',
    title: t(`business.cards.clientTitle`),
    info: t(`business.cards.clientInfo`),
    maxWidth: '534px',
  },
  {
    digits: '65',
    unit: '%',
    title: t(`business.cards.appointmentsTitle`),
    info: t(`business.cards.appointmentsInfo`),
    maxWidth: '290px',
  },
  {
    digits: '2',
    unit: t(`business.cards.hrs`),
    title: t(`business.cards.timeTitle`),
    info: t(`business.cards.timeInfo`),
    maxWidth: '313px',
  },
  {
    digits: '150',
    unit: '%',
    title: t(`business.cards.incomeTitle`),
    info: t(`business.cards.incomeInfo`),
    maxWidth: '510px',
  },
];

export const HowWeHelp = () => {
  const { t } = useTranslation();
  return (
    <HelpWrapper textAlign="center">
      <HelpTitle>{t('business.howWeHelp')}</HelpTitle>
      <BodyLarge large lowline mb={['32px', '32px', '32px', '80px']} width="100%" maxWidth="520px">
        {t('business.helpHint')}
      </BodyLarge>
      <CardsWrapper>
        {infoCards(t).map(infoCard => (
          <InfoCard key={infoCard.title} {...infoCard} />
        ))}
      </CardsWrapper>
    </HelpWrapper>
  );
};
