import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Popup,
  Button,
  Flex,
  FacebookMonoIcon,
  InstagramMonoIcon,
  MailFillIcon,
  ShareIcon,
  useMediaQuery,
  BottomSheet,
  getMinWidthMediaQuery,
} from '@beauty/beauty-market-ui';
import { shareOrganisation } from '../../../helpers/utils';

interface ShareOrganisationProps {
  handelModalClose: () => void;
  isShareModalOpen: boolean;
  organisationId: string;
}

export const ShareOrganisationModal = ({
  handelModalClose,
  isShareModalOpen,
  organisationId,
}: ShareOrganisationProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const shareClick = (sharePlace: string) => {
    handelModalClose();
    shareOrganisation(organisationId, sharePlace);
  };

  const FooterBody = (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent={isLarge ? 'space-between' : ''} flexDirection={['column', 'row']} mb={['0', '16px']}>
        <Button
          size="small"
          design="secondary"
          width="100%"
          onClick={() => shareClick('facebook')}
          mr={['0', '16px']}
          mb={['8px', '0']}
        >
          <FacebookMonoIcon />
          Facebook
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('instagram')} mb={['8px', '0']}>
          <InstagramMonoIcon />
          Instagram
        </Button>
      </Flex>

      <Flex justifyContent={isLarge ? 'space-between' : ''} flexDirection={['column', 'row']}>
        <Button
          size="small"
          design="secondary"
          width="100%"
          onClick={() => shareClick('email')}
          mr={['0', '16px']}
          mb={['8px', '0']}
        >
          <MailFillIcon />
          {t('share.email')}
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('copy')}>
          <ShareIcon />
          {t('share.link')}
        </Button>
      </Flex>
    </Flex>
  );

  return createPortal(
    isLarge ? (
      <Popup
        title={t('share.title')}
        info={t('share.subtitle')}
        open={isShareModalOpen}
        handleClose={handelModalClose}
        FooterBody={FooterBody}
      />
    ) : (
      <BottomSheet
        isOpen={isShareModalOpen}
        onClose={handelModalClose}
        label={t('share.title')}
        descriptor={t('share.subtitle')}
        content={FooterBody}
        detent="content-height"
      />
    ),
    document.getElementById('root') as HTMLElement,
  );
};
