import { H2, H6, Flex, BodySmall, colors, Display } from '@beauty/beauty-market-ui';
import { StyledFlex } from '../style';

type InfoCardProps = {
  digits: string;
  unit: string;
  title: string;
  info: string;
  maxWidth?: string;
};

export const InfoCard = ({ digits, unit, title, info, maxWidth }: InfoCardProps) => (
  <StyledFlex width="100%" maxWidth={['100%', maxWidth]} backgroundColor={colors.white.standard}>
    <Flex alignItems="end">
      <Display mr="4px">{digits}</Display>
      <H2 mb="4px">{unit}</H2>
    </Flex>
    <H6>{title}</H6>
    <BodySmall>{info}</BodySmall>
  </StyledFlex>
);
