import { useTranslation } from 'react-i18next';
import { BodyLarge, Flex } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../../components/functional/formik/formik-input/FormikInput';
import { ProfileFormFields } from '../../../Profile.definitions';

const OtherInformationForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <BodyLarge large mb="16px">
        {t('profile.otherInformation')}
      </BodyLarge>
      <FormikInput
        design="white"
        id={ProfileFormFields.IdNumber}
        name={ProfileFormFields.IdNumber}
        placeholder={t('profile.idNumber')}
        mb="16px"
      />
      <Flex flexDirection="column">
        <FormikInput
          design="white"
          id={ProfileFormFields.Country}
          name={ProfileFormFields.Country}
          placeholder={t('profile.country')}
          mb="16px"
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.City}
          name={ProfileFormFields.City}
          placeholder={t('profile.city')}
          mb="16px"
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.FullAddress}
          name={ProfileFormFields.FullAddress}
          placeholder={t('profile.address')}
          mb="16px"
        />
      </Flex>
    </>
  );
};

export { OtherInformationForm };
