import styled from 'styled-components';
import { Flex, mediaQueries, colors } from '@beauty/beauty-market-ui';

export const MenuWrapper = styled(Flex)`
  gap: 16px;
  position: sticky;
  align-self: flex-start;
  flex-shrink: 0;
  background-color: ${colors.white.standard};
  ${mediaQueries.md} {
    margin-top: 6px;
    width: 181px;
    top: 120px;
    flex-direction: column;
    gap: 16px;
  }
`;
