import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';
import { Flex, getMinWidthMediaQuery, TarifCard, useMediaQuery } from '@beauty/beauty-market-ui';
import { Currency, getSelectedLanguage } from '../../../constants';
import { useGetSubscriptions } from '../../../hooks';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { RouterUrl } from '../../../routes/routes';
import { SubscriptionFilter, SubscriptionPlanType } from '../../../types';
import { getFormattedCurrency } from '../../Organisation/helpers';
import { getTariffPlans, getType } from '../helpers';

export const TariffPlansList = ({ value }) => {
  const { t } = useTranslation();
  const { subscriptionPlans } = useGetSubscriptions();
  const { business } = useGetUrl(RouterUrl.ChangePlanPage);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  const language = getSelectedLanguage();

  const tariffPlans = isEmpty(subscriptionPlans) ? [] : getTariffPlans(subscriptionPlans, t, language);
  const bestPlan = SubscriptionPlanType.PRO;

  return (
    <Flex flexDirection={isLarge ? 'row' : 'column'} gap={isLarge ? '0px' : '16px'}>
      {tariffPlans.map((plan, i, plans) => {
        const isAnnually = value === 1;
        const isBestPlan = plan.planType === bestPlan;
        const isEnterprise = plan.planType === SubscriptionPlanType.ENTERPRISE;

        const mainAmount =
          plan.prices[Currency.ILS]?.MAIN[isAnnually ? SubscriptionFilter.Annually : SubscriptionFilter.Monthly];
        const employeeAmount =
          plan.prices[Currency.ILS]?.EMPLOYEE[isAnnually ? SubscriptionFilter.Annually : SubscriptionFilter.Monthly];

        const mainPrice = getFormattedCurrency(isAnnually ? mainAmount / 12 : mainAmount, Currency.ILS, language);
        const employeePrice = getFormattedCurrency(
          isAnnually ? employeeAmount / 12 : employeeAmount,
          Currency.ILS,
          language,
        );

        return (
          <TarifCard
            key={plan.id}
            title={capitalize(plan.planType)}
            cost={isEnterprise ? t('business.tariffPlans.individual') : mainPrice}
            extraCost={isEnterprise ? '' : t('business.tariffPlans.perMonth')}
            subtitle={isEnterprise ? ' ' : t('business.tariffPlans.perEmployee', { price: employeePrice })}
            readMore=""
            buttonLabel={t('business.select')}
            label={isBestPlan ? t('business.bestValue') : null}
            isBest={isBestPlan}
            isFull
            linkRef=""
            type={isBestPlan || !isLarge ? 'full' : getType(i, plans.length - 1)}
            features={plan.features}
            additional={plan.additional}
            onButtonClick={() => window.open(business)}
          />
        );
      })}
    </Flex>
  );
};
