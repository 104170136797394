import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { ReviewEndpoints } from '../api/endpoints/endpoints';
import { useAppDispatch } from '../store/hooks';
import { setForceFetch } from '../store/redux-slices/userSlice';
import { OrganisationRating } from '../types/organisation';

export const useGetOrganisationRating = (orgId: string, isFetch?: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rating, setRating] = useState<OrganisationRating | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isRatingLoading = true;

    const fetchOrganisationRating = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: ReviewEndpoints.OrganisationRating,
        endpointParams: orgId,
      });

      if (isRatingLoading) {
        setRating(response?.data);
        setIsLoading(false);
      }
    };

    fetchOrganisationRating();
    dispatch(setForceFetch(false));

    return () => {
      isRatingLoading = false;
    };
  }, [orgId, isFetch]);

  return {
    isLoading,
    rating,
  };
};
