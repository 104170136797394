import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, H2, Caption } from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import { AnchorMenu } from '../../components/AnchorMenu/AnchorMenu';
import { PrivacyWrapper } from '../../style';
import { useScrollY } from '../HomePage/hooks/useScrollPosition';
import {
  Introduction,
  Feedback,
  Privacy,
  SiteAccess,
  AgeRequirements,
  AllowedActivities,
  YourCommitments,
  NotAllowed,
  UserContent,
  IntellectualProperty,
  Content,
  ThirdParty,
  Disclaimer,
  Jurisdiction,
  GeographicLimitations,
} from './components';
import { termsOfUseTitles } from './constants';

const sections = [
  Introduction,
  Feedback,
  Privacy,
  SiteAccess,
  AgeRequirements,
  AllowedActivities,
  YourCommitments,
  NotAllowed,
  UserContent,
  IntellectualProperty,
  Content,
  ThirdParty,
  Disclaimer,
  Jurisdiction,
  GeographicLimitations,
];

// TODO Refactor to enable eslint checks
export const TermsOfUse = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);

  const titleRefs = useRef<Flex[]>(new Array(termsOfUseTitles.length));

  const posY = useScrollY();

  useEffect(() => {
    const currentSection =
      termsOfUseTitles.length - titleRefs.current.reverse().findIndex(ref => ref.getBoundingClientRect().y < 0) - 1;
    titleRefs.current.reverse();

    if (!isClicked) {
      setActiveSection(currentSection);
    }
  }, [posY, termsOfUseTitles]);

  return (
    <Container>
      <AnchorMenu
        menuItems={termsOfUseTitles.map(title => t(`termsOfUse.${title}.title`))}
        hrefs={titleRefs}
        activeSection={activeSection}
        onClick={setIsClicked}
      />
      <PrivacyWrapper>
        <Flex width="100%" flexDirection="column" ml={['0', '0', '0', '38px']}>
          <H2 mb="8px">{t('termsOfUse.title')}</H2>
          <Caption mb="-40px">{t('termsOfUse.lastUpdated')}</Caption>
          <Flex flexDirection="column" maxWidth="620px">
            {sections.map((Section, index) => (
              <Section
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                ref={el => {
                  titleRefs.current[index] = el;
                }}
              />
            ))}
          </Flex>
        </Flex>
      </PrivacyWrapper>
    </Container>
  );
};
