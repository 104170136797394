import { useTranslation } from 'react-i18next';
import { H3, getMinWidthMediaQuery, useMediaQuery, Alert, AlertTypes } from '@beauty/beauty-market-ui';
import { Services, Timeslots } from '../../../components';
import { CategoryType, SpecialRequirementType } from '../../../types';
import { OrganisationWorkTime } from '../../Organisation/types';
import { SelectPanelWrapper } from '../style';

interface SelectPanelProps {
  showTimeslots: boolean;
  offers: {
    categories: CategoryType[];
    specialRequirement?: SpecialRequirementType;
  };
  orgTimezone: string;
  organisationName: string;
  isAlertVisible: boolean;
  workSchedule: OrganisationWorkTime[];
  selectedId?: string;
}

const SelectPanel = ({
  showTimeslots,
  offers,
  orgTimezone,
  organisationName,
  selectedId,
  isAlertVisible,
  workSchedule,
}: SelectPanelProps) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const servicesProps = {
    offersCategories: offers.categories,
    organisationName,
  };

  const timeslotsProps = {
    orgTimezone,
    selectedDay: selectedId?.substring(0, 10),
    isAlertVisible,
    workSchedule,
  };

  return (
    <SelectPanelWrapper>
      <Alert
        isAlertVisible={isAlertVisible}
        type={AlertTypes.ERROR}
        title={t('alert.createAppointmentErrorTitle')}
        subtitle={t('alert.createAppointmentErrorSubtitle')}
        position={isDesktop ? 'top-right' : 'top-center'}
      />
      {!showTimeslots && <H3>{t('organisation.offerings')}</H3>}
      {showTimeslots ? <Timeslots {...timeslotsProps} /> : <Services {...servicesProps} />}
    </SelectPanelWrapper>
  );
};

export default SelectPanel;
