import { useTranslation } from 'react-i18next';
import { BodySmall, H6, Link, Div, Flex } from '@beauty/beauty-market-ui';
import { ColumnFlex } from '../../../../HomePage/style';

type InfoProps = {
  title: string;
  type: string;
  value: string;
  onClick?: () => void;
};

export const Info = ({ title, type, value, onClick }: InfoProps) => {
  const { t } = useTranslation();

  return (
    <Flex mb={type !== 'change' ? ['20px', '20px', '20px', '40px'] : 0}>
      {value ? (
        <Flex>
          <Flex flexDirection="column">
            <BodySmall>{title}</BodySmall>
            <H6 mb="4px">{value}</H6>
          </Flex>
          {/* {type === 'change' && (
            <Link size="s" mt="10px" onClick={onClick}>
              {t('profile.change')}
            </Link>
          )} */}
        </Flex>
      ) : (
        <ColumnFlex>
          <BodySmall mb="4px">{title}</BodySmall>
          <Link size="s" mt="10px" onClick={onClick}>
            {type === 'add' ? t('profile.add') : t('profile.choose')}
          </Link>
        </ColumnFlex>
      )}
    </Flex>
  );
};
